import React, { Component } from "react";
import { Draggable } from "react-beautiful-dnd";
import { FaArrowsAlt } from "react-icons/fa";
import { Row, Col } from "reactstrap";
import "./css/ItemInstrumentoDisponivel.css";

const RespostaEstilos = props => {
  if (props.index !== undefined) {
    return (
      <Row>
        <Col style={{ padding: 0 }}>
          <Draggable
            draggableId={props.index + 1 + props.column}
            key={props.index}
            index={props.index}
          >
            {(provided, snapshot) => (
              <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                isDragging={snapshot.isDragging}
                className="task"
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <FaArrowsAlt size="12" />
                  </div>
                  <span className="" style={{ paddingLeft: "10px" }}>
                    {props.resp}
                  </span>
                </div>
              </div>
            )}
          </Draggable>
        </Col>
      </Row>
    );
  }
  return <h1>Vazio</h1>;
};

export default RespostaEstilos;
