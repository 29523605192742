import axios from 'axios';

let { REACT_APP_ASSINC_API_URL, REACT_APP_REST_API_URL } = process.env;
console.info('REACT_APP_ASSINC_API_URL=', REACT_APP_ASSINC_API_URL);
console.info('REACT_APP_REST_API_URL=', REACT_APP_REST_API_URL);
const baseURL = REACT_APP_ASSINC_API_URL;

let api = axios.create({
  baseURL,
});

api.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

export const regularApi = axios.create({
  baseURL: REACT_APP_REST_API_URL,
  headers: {
    common: {
      Authorization: "Bearer " + sessionStorage.getItem("token")
    }
  }
});

export default api;
