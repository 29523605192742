import React, { Component } from "react";
import styled from "styled-components";
import "./css/NewCustomRadio.css";
import 'react-tippy/dist/tippy.css';
import {
  Tooltip,
} from 'react-tippy';

/**
 * O radio button padrão dos Assícronos
 *
 * @export
 * @class CustomRadio
 * @extends {Component}
 */
export default class NewCustomRadio extends Component {
  render() {
    return (
      <Tooltip
        title={this.props.label}
        position="top"
        size="small"
        trigger="mouseenter"
      >
      <div style={this.props.style}>
        <label className={`container-radio ${this.props.labelClasses}`}>
          <input type="radio" {...this.props} />
          <span className="checkmark-radio"></span>
        </label>
      </div>
      </Tooltip>
    );
  }
}

export const RadioWrapper = styled.div`
  /* height: 30px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 50px;
`;
