import React from "react";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import { NewCustomRadio } from '../../../components/general';

const QuestaoCentralizada = styled(Row)`
  align-items: center;
  justify-content: center;
`;

const RadioColumn = styled.div`
    display: flex;
    justify-content: flex-end;
  `;

const RadioColumnMd = styled(Col)`
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
    height: 2.5px;
    background-color: #ccc;
    align-items: center;
  `;

const RadioWrapper = styled.div`
    height: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  `;

const Opcao = styled(Row)`
    display: flex;
    align-items: center;
    margin: ${(props) => props.isMobile ? 0 : "10px"};
  `;

export function QuestionLocusControle(props) {
  const {
    indice,
    indicea,
    indiceb,
    checkedA,
    checkedB,
    onChangeA,
    onChangeB,
    index
  } = props;
  const isMobile = props.isMobile;

  return (
    <QuestaoCentralizada>
      {isMobile ? (
        <>
          <Col sm="12" style={{ marginBottom: 10 }}>
            <Opcao sm="12" isMobile={isMobile}>
              <RadioColumn style={{ marginBottom: 10 }}>
                <RadioWrapper>
                  <NewCustomRadio
                    value={indicea}
                    checked={checkedA}
                    onChange={onChangeA}
                    key={`key-A-${index}`}
                    name={`name-A-${index}`}
                    id={`id-A-${index}`}
                    label={'A'}
                  />
                </RadioWrapper>
              </RadioColumn>
              <Col>
                {/* <b>a) </b> */}
                <label htmlFor={`${indice}-0`}>{props.perguntaa}</label>
              </Col>
            </Opcao>
          </Col>

          <Col sm="12">
            <Opcao sm="12" isMobile={isMobile}>
              <RadioColumn style={{ marginBottom: 10 }}>
                <RadioWrapper>
                  <NewCustomRadio
                    value={indiceb}
                    checked={checkedB}
                    onChange={onChangeB}
                    key={`key-B-${index}`}
                    name={`name-B-${index}`}
                    id={`id-B-${index}`}
                    label={'B'}
                  />
                </RadioWrapper>
              </RadioColumn>
              <Col>
                {/* <b>b) </b> */}
                <label htmlFor={`${indice}-1`}>{props.perguntab}</label>
              </Col>
            </Opcao>
          </Col>
        </>
      ) : (
        <>
          <Col sm="5" style={{ paddingLeft: 5 }}>
            <Opcao isMobile={isMobile}>
              <Col style={{ paddingLeft: '0' }}>
                {/* <b>a) </b> */}
                <label style={{marginBottom: 0}} htmlFor={`${indice}-0`}>{props.perguntaa}</label>
              </Col>
            </Opcao>
          </Col>
          <RadioColumnMd sm="2">
            <RadioWrapper>
              <NewCustomRadio
                style={{ marginLeft: -5 }}
                value={indicea}
                checked={checkedA}
                onChange={onChangeA}
                key={`key-A-${index}`}
                name={`name-A-${index}`}
                id={`id-A-${index}`}
                label={'A'}
              />
            </RadioWrapper>
            <RadioWrapper>
              <NewCustomRadio
                style={{ marginRight: -5 }}
                value={indiceb}
                checked={checkedB}
                onChange={onChangeB}
                key={`key-B-${index}`}
                name={`name-B-${index}`}
                id={`id-B-${index}`}
                label={'B'}
              />
            </RadioWrapper>
          </RadioColumnMd>
          <Col sm="5">
            <Opcao isMobile={isMobile}>
              <Col>
                {/* <b>b) </b> */}
                <label style={{marginBottom: 0}} htmlFor={`${indice}-1`}>{props.perguntab}</label>
              </Col>
            </Opcao>
          </Col>
        </>
      )}
    </QuestaoCentralizada>
  );
}

QuestionLocusControle.defaultProps = {
  selectedOption: null,
  isMobile: false
};

export default QuestionLocusControle;
