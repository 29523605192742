export default function replaceTemplateWithValues(template, replacements) {

    for (const key in replacements) {
       
        template = template.replace(
            new RegExp(`{{${key}}}`, "g"),
            replacements[key]
        );
    }

    return template;
}