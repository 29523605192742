import React, { Component } from "react";
import { Row, Col } from "reactstrap";
//import "./css/SubHeader2.css";
import styled from "styled-components";
import { handleIcon } from "./InstrumentIcons";
import { InstructionsModal } from "../../../components/InstructionsModal";

const Title = styled.h1`
  color: #929c7c;
  font-size: 18px;
  margin-bottom: 0;
`;

export function SubHeader({ customStyle, idInstrumento, titulo, ...rest }) {
  let participantName = localStorage.getItem("userName");
  return (
    <>
      <Row className="sub-header" style={customStyle}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-end",
            marginTop: "35px"
          }}
        >
          <div style={{ width: "100%", maxWidth: 1024, margin: "0 auto" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {handleIcon(idInstrumento, "#929c7c")}
              <div
                style={{
                  marginLeft: 10,
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  maxWidth: 974
                }}
              >
                <Title>
                  {titulo} - {participantName}
                </Title>
                <InstructionsModal {...rest} />
              </div>
            </div>
            <hr />
          </div>
        </div>
      </Row>
    </>
  );
}
