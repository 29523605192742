export { default as upperCaseFirstLetter } from './upperCaseFirstLetter';
export { default as formatURL } from './formatURL';
export { default as normalizarPorMaximo } from './normalizarPorMaximo';
export { default as replaceTemplateWithValues } from './replace-template-with-values';
export { removeAccent } from './formatURL';

/// Converte um objeto Date no formato: DD/MM/YYYY e um horário no formato HH:mm para
/// uma string formatada no formato UTC (No fuso horário de Brasília)
export function convertToDateTimeString(date, time) {
  let day;
  let month;
  let year;
  let hour;
  try {
    day = date.substring(0, 2);
    month = date.substring(3, 5);
    year = date.substring(6);
    hour = time + ':00-03:00';
    Date.parse(`${year}-${month}-${day}T${hour}`);
  } catch (error) {
    return 'ERRO';
  }
  return `${year}-${month}-${day}T${hour}`;
}

/// Retorna a diferença, em ms, entre dois objetos Date
export default function dateDiffInMilli(a, b) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(
    a.getFullYear(),
    a.getMonth(),
    a.getDate(),
    a.getHours(),
    a.getMinutes(),
    a.getSeconds(),
    a.getMilliseconds()
  );
  const utc2 = Date.UTC(
    b.getFullYear(),
    b.getMonth(),
    b.getDate(),
    b.getHours(),
    b.getMinutes(),
    b.getSeconds(),
    b.getMilliseconds()
  );

  return utc2 - utc1;
}

export function dateIsBefore(a, b) {
  return dateDiffInMilli(a, b) > 0;
}

export function formatDate(date) {
  let data = new Date(date),
    dia = data.getDate().toString(),
    diaF = dia.length == 1 ? "0" + dia : dia,
    mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
    mesF = mes.length == 1 ? "0" + mes : mes,
    anoF = data.getFullYear();

  if (isNaN(data.getDate())) return date;

  return diaF + "/" + mesF + "/" + anoF;
}

