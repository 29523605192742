import api, { regularApi } from '../services/api';

export default {

	assincronos: {

		async getDadosInstrumento(idInstrumento) {

			return await regularApi.get(
				`api/instruments/${idInstrumento}`,
			);
		},

		async validateRespondentAccess(idGrp, idInstrumento) {

			// GET /respondente/grp-instrumentos/:idGrp/instrument/idInstrumento/validate-access
			return api.get(`/respondente/grp-instrumentos/${idGrp}/instrument/${idInstrumento}/validate-access`);
		}
	}
}