import React from 'react';
import styled from 'styled-components';

export const User2 = styled.div`
  text-align: center;

  div {
    width: 50px;
    height: 50px;
    border: solid 1px white;
    -webkit-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.32);
    -moz-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.32);
    box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.32);
    border-radius: 50px;
    background: url(${(props) => props.background});
    background-size: cover;
    background-position: center;
  }
`;

export class UserFotoSmall extends React.Component {

  render() {
    const urlToUse = this.props.tests ? `https://ambiente-testes.s3.us-east-2.amazonaws.com` : `https://files-storage.s3.amazonaws.com`;
    return (
      <User2
        background={
          this.props.foto == ""
            ? `"${urlToUse}/avatar_default.png"`
            : `"${urlToUse}/${this.props.foto}"`
        }
      >
        <div alt="logo" />
      </User2>
    );
  }
}
