export function capitalizeFirstLetter (s) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
}

export function convertToBRCurrency (n) {
  try {
    return Number(n).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
  } catch (err) {
    return '';
  }
}

export function formatCpf(cpf) {
  return cpf
  .replace(/\D/g, '')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d{1,2})/, '$1-$2')
  .replace(/(-\d{2})\d+?$/, '$1')
}

export function capitalizeFirstLetterEachWord(phrase='') {
  let splited = phrase.trim().split(' ');

  const mask = /\b(das|dos|de|da|do|d)\b/i;

  let text = '';

  splited.map(s => {
    if (!mask.test(s)) {
      const upper = s.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
      text += ` ${upper}`;
    } else {
      text += ` ${s}`;
    }
  })

  return text;
}
