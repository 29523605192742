import React, { Component, createRef } from 'react';
import { Prompt } from 'react-router';
import { Row } from 'reactstrap';
import api, { regularApi } from '../../../services/api';
import { notifySuccess, notifyInfo, notifyError } from '../../../services/notificacoes';
import { CustomRadio } from '../../components/general';
import ActivityActions from './Components/ActivityActions';
import './css/general.css';
import withSubscription from "./hoc-instrumentos-assincronos";
import Messages from '../../../static/Messages';
import { assincronoInstrumentosIDS } from '../../../services/Constantes';
import ErrorBoundary from '../../components/general/ErrorBoundary';
import ErrorMessage from '../../components/general/error-message';
import InstrumentContainer from '../../components/InstrumentContainer';
import NewSubHeader from '../ListInstruments/components/NewSubHeader';
import { NewCustomRadio } from '../../components/general';
import { RadioWrapper } from '../../components/general/NewCustomRadio';
import { scrollToUnfinishedQuestion } from './utils/scrollToUnfinishedQuestion';

// ///
// /// DIVIDIDO em 2 Grupos, e 2 feedbacks

// / GRUPO 01 - some as respostas e divida por 7
// / GRUPO 02- some as respostas e divida por 4

let respostas = [];

class EscutaAtiva extends Component {
  state = {
    dados: [],
    enunciado: '',
    unfinishedQuestion: undefined
  };

  graphicRefs = [];

  async componentDidMount() {
    await this.props.validateAccess(localStorage.getItem('assincrono'), localStorage.getItem('instrumento'));
    const response = await regularApi.get(
      'api/instruments/5c0455bcacd7ba15fc075536',
    );
    const dadosDb = response.data;
    const savedAnswers = await api.get(
      `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
        'assincrono',
      )}/${localStorage.getItem('instrumento')}/${localStorage.getItem(
        'projeto',
      )}`,
    );
    const storageName = `escutaEscolhas-${localStorage.getItem('assincrono')}`;
    this.props.getStorageName([storageName]);
    if (sessionStorage.getItem(storageName)) this.props.setHasChanged(true);
    respostas = sessionStorage.getItem(storageName) ?
    JSON.parse(sessionStorage.getItem(storageName)) : savedAnswers.data ? savedAnswers.data.escolhas : [];
    this.setState({ dados: [dadosDb], enunciado: dadosDb.data[0].enunciado || '' });
  }

  handleRespostas = (e, index) => {
    respostas[index] = e.target.value;
    sessionStorage.setItem(`escutaEscolhas-${localStorage.getItem('assincrono')}`, JSON.stringify(respostas));
    this.props.setHasChanged(true);
    this.setState({});
  };

  enviar = async e => {
    try {
      e.preventDefault();

      let sum1 = 0;
      let sum2 = 0;


      const alternativas = this.state.dados[0].data[0].alternativas;

      if (respostas.filter((r) => !!r === true).length < alternativas.length) {

        return notifyError(Messages.Assincronos.msgValidation);
      }

      for (let i = 0; i < respostas.length; i++) {
        if (i >= 0 && i < 7) {
          sum1 += Number(respostas[i]);
        }
        if (i >= 7 && i < 11) {
          sum2 += Number(respostas[i]);
        }
      }

      if (sum1 === null || isNaN(sum1)) {

        return notifyError('Falha ao enviar instrumento. Selecione todas questões e tente novamente');

      }

      if (sum2 === null || isNaN(sum2)) {

        return notifyError('Falha ao enviar instrumento. Selecione todas questões e tente novamente');

      }

      await api.put(
        `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
          'assincrono',
        )}/${localStorage.getItem('instrumento')}`,
        {
          resposta: {
            media1: sum1 / 7,
            media2: sum2 / 4,
            escolhas: respostas,
            finished: true
          },
          projetoID: localStorage.getItem('projeto'),
        },
      );
      this.props.setHasChanged(false);
      this.props.history.goBack();
    } catch (err) {
      this.props.handleError(err);
    }
  };

  saveAnswer = async () => {
    try {
      await api.put(
        `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
          'assincrono',
        )}/${localStorage.getItem('instrumento')}`,
        {
          resposta: {
            escolhas: respostas,
          },
          projetoID: localStorage.getItem('projeto'),
        },
      );
      this.props.setHasChanged(false);
      notifySuccess(
        'Atividade salva com sucesso. Caso queira, você poderá modificar a atividade até o prazo final',
      );
    } catch (err) {
      this.props.handleError(err);
    }
  };

  voltar = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <ErrorBoundary errorComponent={() => (
        <ErrorMessage message={Messages.Assincronos.msgErrorLoadDados} />
      )}>
        <Prompt
          when={this.props.handleBack()}
          message={Messages.Assincronos.msgConfirmacao}
        />


        <InstrumentContainer>
        <NewSubHeader
            titulo={"Escuta Ativa"}
            instrucao={this.state.enunciado}
            idInstrumento={assincronoInstrumentosIDS.EscutaAtiva}
          />
          {this.state.dados.map(dados => (
            <div key={dados._id}>
              {dados.data.map((dados, index) => (
                <div key={index}>
                  {dados.alternativas.map((dados, index) => (
                    this.graphicRefs.push(createRef()),
                    <>
                      <div ref={this.graphicRefs[index]} key={index} className='margin-top'>
                        <div className='sel'>
                          <p>
                            {' '}
                            <b>
                              {index + 1} - {dados}
                            </b>
                          </p>
                          <Row className="without-margin row-centered remove-margins question align-center" style={{ flexWrap: 'nowrap' }}>
                            <div><label htmlFor={`${index}-1`}>Nunca</label></div>
                            {[1, 2, 3, 4, 5].map(i => (
                              <RadioWrapper
                              style={{ height: 'unset' }}
                              >
                                <NewCustomRadio
                                  value={String(i)}
                                  checked={respostas[index] === String(i)}
                                  onChange={e => this.handleRespostas(e, index)}
                                  key={i}
                                  name={`value${index}`}
                                  id={`${index}-${i}`}
                                  label={i}
                                />
                              </RadioWrapper>
                            ))}
                            <div style={{ textAlign: 'right' }}><label htmlFor={`${index}-5`}>Sempre</label></div>
                          </Row>
                        </div>
                      </div>
                      <hr style={{marginTop: 30}} />
                    </>
                  ))}
                </div>
              ))}
            </div>
          ))}
          <ActivityActions
            back={() => this.voltar()}
            send={this.enviar}
            save={this.saveAnswer}
            nextSend={() => scrollToUnfinishedQuestion(this.graphicRefs, this.state.dados[0].data[0].alternativas, respostas)}
          />
        </InstrumentContainer>
      </ErrorBoundary>
    );
  }
}

export default withSubscription(EscutaAtiva);
