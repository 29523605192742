import React, { Component, createRef } from 'react';
import { Prompt } from 'react-router';

import { notifySuccess, notifyError } from '../../../services/notificacoes';
import './css/TipoDePersonalidade.css';
import './css/general.css';
import withSubscription from "./hoc-instrumentos-assincronos";
import QuestionTiposPersonalidade from './Components/QuestionTiposPersonalidade';
import Messages from '../../../static/Messages';
import { scrollToUnfinishedQuestion } from './utils/scrollToUnfinishedQuestion';
import NewSubHeader from '../ListInstruments/components/NewSubHeader';
import { assincronoInstrumentosIDS } from '../../../services/Constantes';
import InstrumentContainer from '../../components/InstrumentContainer';

import { replaceTemplateWithValues } from "../../../utils";
import api from '../../../services/api';
import apiCalls from '../../../config/api-calls';
import ActivityActions from './Components/ActivityActions';

let valor1 = '';
let valor2 = '';
let valor3 = '';
let valor4 = '';

class TiposDePersonalidade extends Component {

  graphicRefs = [];

  constructor(props) {
    super(props);
    this.state = {
      dados: [],
      instrumento: {},
    };
  }

  async componentDidMount() {
    await this.props.validateAccess(localStorage.getItem('assincrono'), localStorage.getItem('instrumento'));
    const response = await apiCalls.assincronos.getDadosInstrumento("5ed167f60ca0022f3c5126b0");
    const dadosDb = response.data;

    const savedAnswers = await api.get(
      `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
        'assincrono',
      )}/${localStorage.getItem('instrumento')}/${localStorage.getItem(
        'projeto',
      )}`,
    );
    const storageName = `tiposEscolhas-${localStorage.getItem('assincrono')}`;
    this.props.getStorageName([storageName]);
    if (sessionStorage.getItem(storageName)) this.props.setHasChanged(true);
    const respostas = sessionStorage.getItem(storageName) ?
    JSON.parse(sessionStorage.getItem(storageName)) : savedAnswers.data ? savedAnswers.data.escolhas : [];

    this.setState({
      dados: [dadosDb],
      instrumento: {
        nome: dadosDb.name,
        enunciado: dadosDb.enunciado && dadosDb.enunciado.replaceAll("{{participant_name}}", ` ${localStorage.getItem('userName')}`)
      },
      respostas
    });
  }

  saveAnswer = async () => {
    try {
      await api.put(
        `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
          'assincrono',
        )}/${localStorage.getItem('instrumento')}`,
        {
          resposta: {
            escolhas: this.state.respostas,
          },
          projetoID: localStorage.getItem('projeto'),
        },
      );

      this.props.setHasChanged(false);
      notifySuccess(`Atividade salva com sucesso.
      Caso queira, você poderá modificar a atividade até o prazo final`);

    } catch (err) {
      this.props.handleError(err);
    }
  };

  handleChange = (e, indice, index) => {
    if (index === 0) {
      index = 'a';
    } else {
      index = 'b';
    }
    const respostas = [...this.state.respostas];
    respostas[indice] = index;
    this.props.setHasChanged(true);
    sessionStorage.setItem(`tiposEscolhas-${localStorage.getItem('assincrono')}`, JSON.stringify(respostas));
    this.setState({respostas});
  };

  enviar = async () => {
    try {

      let sumE = 0;
      let sumES = 0;
      let sumS = 0;
      let sumST = 0;
      let sumT = 0;
      let sumTJ = 0;
      let sumJ = 0;

      let sumI = 0;
      let sumIN = 0;
      let sumN = 0;
      let sumNF = 0;
      let sumF = 0;
      let sumFP = 0;
      let sumP = 0;

      const filteredRepostas = this.state.respostas.filter(r => r);

      if (filteredRepostas.length < 30) {
        notifyError(Messages.Assincronos.msgValidation);
        return;
      }

      for (let i = 0; i < this.state.respostas.length; i += 1) {
        if (this.state.respostas[i] === 'a') {
          const j = i + 1;
          if (
            j === 1 ||
            j === 8 ||
            j === 15 ||
            j === 22 ||
            j === 29 ||
            j === 36 ||
            j === 43 ||
            j === 50 ||
            j === 57 ||
            j === 64
          ) {
            sumE += 1;
          }
          if (
            j === 2 ||
            j === 9 ||
            j === 16 ||
            j === 23 ||
            j === 30 ||
            j === 37 ||
            j === 44 ||
            j === 51 ||
            j === 58 ||
            j === 65
          ) {
            sumES += 1;
          }
          if (
            j === 3 ||
            j === 10 ||
            j === 17 ||
            j === 24 ||
            j === 31 ||
            j === 38 ||
            j === 45 ||
            j === 52 ||
            j === 59 ||
            j === 66
          ) {
            sumS += 1;
          }
          if (
            j === 4 ||
            j === 11 ||
            j === 18 ||
            j === 25 ||
            j === 32 ||
            j === 39 ||
            j === 46 ||
            j === 53 ||
            j === 60 ||
            j === 67
          ) {
            sumST += 1;
          }
          if (
            j === 5 ||
            j === 12 ||
            j === 19 ||
            j === 26 ||
            j === 33 ||
            j === 40 ||
            j === 47 ||
            j === 54 ||
            j === 61 ||
            j === 68
          ) {
            sumT += 1;
          }
          if (
            j === 6 ||
            j === 13 ||
            j === 20 ||
            j === 27 ||
            j === 34 ||
            j === 41 ||
            j === 48 ||
            j === 55 ||
            j === 62 ||
            j === 69
          ) {
            sumTJ += 1;
          }
          if (
            j === 7 ||
            j === 14 ||
            j === 21 ||
            j === 28 ||
            j === 35 ||
            j === 42 ||
            j === 49 ||
            j === 56 ||
            j === 63 ||
            j === 70
          ) {
            sumJ += 1;
          }
        }
        if (this.state.respostas[i] === 'b') {
          const j = i + 1;
          if (
            j === 1 ||
            j === 8 ||
            j === 15 ||
            j === 22 ||
            j === 29 ||
            j === 36 ||
            j === 43 ||
            j === 50 ||
            j === 57 ||
            j === 64
          ) {
            sumI += 1;
          }
          if (
            j === 2 ||
            j === 9 ||
            j === 16 ||
            j === 23 ||
            j === 30 ||
            j === 37 ||
            j === 44 ||
            j === 51 ||
            j === 58 ||
            j === 65
          ) {
            sumIN += 1;
          }
          if (
            j === 3 ||
            j === 10 ||
            j === 17 ||
            j === 24 ||
            j === 31 ||
            j === 38 ||
            j === 45 ||
            j === 52 ||
            j === 59 ||
            j === 66
          ) {
            sumN += 1;
          }
          if (
            j === 4 ||
            j === 11 ||
            j === 18 ||
            j === 25 ||
            j === 32 ||
            j === 39 ||
            j === 46 ||
            j === 53 ||
            j === 60 ||
            j === 67
          ) {
            sumNF += 1;
          }
          if (
            j === 5 ||
            j === 12 ||
            j === 19 ||
            j === 26 ||
            j === 33 ||
            j === 40 ||
            j === 47 ||
            j === 54 ||
            j === 61 ||
            j === 68
          ) {
            sumF += 1;
          }
          if (
            j === 6 ||
            j === 13 ||
            j === 20 ||
            j === 27 ||
            j === 34 ||
            j === 41 ||
            j === 48 ||
            j === 55 ||
            j === 62 ||
            j === 69
          ) {
            sumFP += 1;
          }
          if (
            j === 7 ||
            j === 14 ||
            j === 21 ||
            j === 28 ||
            j === 35 ||
            j === 42 ||
            j === 49 ||
            j === 56 ||
            j === 63 ||
            j === 70
          ) {
            sumP += 1;
          }
        }
      }

      await api.put(
        `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
          'assincrono',
        )}/${localStorage.getItem('instrumento')}`,
        {
          resposta: {
            sumE,
            sumES,
            sumS,
            sumST,
            sumT,
            sumTJ,
            sumJ,

            sumI,
            sumIN,
            sumN,
            sumNF,
            sumF,
            sumFP,
            sumP,
            escolhas: this.state.respostas,
            finished: true
          },
          projetoID: localStorage.getItem('projeto'),
        },
      );
      this.props.setHasChanged(false);
      this.props.history.goBack();

    } catch (err) {
      this.props.handleError(err);
    }
  };

  voltar = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <InstrumentContainer>
        <Prompt
          when={this.props.handleBack()}
          message={Messages.Assincronos.msgConfirmacao}
        />
        <NewSubHeader
          titulo="Tipos de Personalidade"
          instrucao={this.state.instrumento.enunciado}
          idInstrumento={assincronoInstrumentosIDS.TiposDePersonalidade}
          />
        {this.state.dados.map(dados => (
          <div key={dados._id} style={{ padding: '30px' }}>
            {dados.data.map((data, index) => (
              <div key={index}>
                {data.atividades.map((data, indice) => {
                  return (
                    this.graphicRefs.push(createRef()),
                    <div ref={this.graphicRefs[indice]} key={indice} className="margin-top">
                      <p>
                        <b>
                          {indice + 1}
                          {' - '}
                          {replaceTemplateWithValues(data.titulo, {
                            nomeParticipante: localStorage.getItem("userName")
                          })}
                        </b>
                      </p>
                      <QuestionTiposPersonalidade
                        data={data}
                        indice={indice}
                        selectedOption={this.state.respostas[indice]}
                        isMobile={this.props.data.isMobile}

                        handleChange={this.handleChange} />
                      <hr />
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        ))}
        <ActivityActions
          back={() => this.voltar()}
          send={this.enviar}
          save={this.saveAnswer}
          nextSend={() => scrollToUnfinishedQuestion(this.graphicRefs, this.state.dados[0].data[0].atividades, this.state.respostas)}
        />
      </InstrumentContainer>
    );
  }
}
export default withSubscription(TiposDePersonalidade);
