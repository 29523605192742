import React, { Component } from 'react';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { Button } from '../../../../views/components/Buttons';
import styled from 'styled-components';
import './css/OpenInstrucoes.css';
import { Tooltip } from 'react-tippy';

const TituloModal = styled.h2`
  font-weight: 700;
  font-size: 20px;
`;
/**
 * Componente para renderizar o botão "Instruções (?)"
 *
 * @export
 * @class OpenInstrucoes
 * @extends {Component}
 */
export default class OpenInstrucoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: !this.props.startClosed,
    };
  }

  static defaultProps = {
    showTitle: true,
  };

  toggleModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  };

  render() {
    const { modalIsOpen } = this.state;
    return (
      <div>
        <div
          onClick={this.toggleModal}
          style={{
            cursor: 'pointer',
            color: '#002449',
          }}
        >

          <BsFillQuestionCircleFill size={20}></BsFillQuestionCircleFill>

        </div>
        <InstructionsModal isOpen={modalIsOpen}
          toggle={this.toggleModal}
          instrucao={this.props.instrucao}
          showTitle={this.props.showTitle}/>
      </div>
    );
  }
}

export const InstructionsModal = ({ isOpen, toggle, showTitle, instrucao }) => (
  <Modal isOpen={isOpen} toggle={toggle} size='lg' style={{fontSize: 12}}>
    <ModalHeader toggle={toggle}>
      Instruções
      </ModalHeader>
      <ModalBody style={{ fontFamily: "Montserrat" }}>
        <p dangerouslySetInnerHTML={{ __html: instrucao }}></p>
      </ModalBody>
      <ModalFooter>
        <Button color='danger' onClick={toggle}>
          Fechar
        </Button>
      </ModalFooter>
  </Modal>
)
