import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";

import ItemInstrumentoDashboard from "./ItemInstrumentoDashboard";

import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import { FaEye, FaPlay, FaUserFriends } from "react-icons/fa";
import { ItemInstrumento, ItemActions, Action } from "./styles";
import { CircularButton } from "../../../Assets/Assets";
import { Tooltip } from "react-tippy";

export default class ItemInstrumentoFinalizado extends Component {
  static propTypes = {
    indice: PropTypes.number,
    instrumento: PropTypes.object.isRequired,
    userCanSeeResults: PropTypes.bool.isRequired,
    showPlayButton: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    userCanSeeResults: false,
    showPlayButton: false,
  };

  constructor(props) {
    super(props);
  }

  handleOpenIndicationModal = () => {
    const {
      openRespondentModal,
      instrumento: { _id, idGrupo, projectId },
    } = this.props;
    openRespondentModal(idGrupo, _id, projectId);
  };

  render() {
    const { instrumento, notRespondente } = this.props;
    return (
      <ItemInstrumento key={this.props.indice}>
        <ItemInstrumentoDashboard instrumento={instrumento} />

        <ItemActions sm="3">
          {instrumento.userCanSelectWhoWillAnswer && notRespondente && (
            <Action>
              <>
                <CircularButton
                  onClick={this.handleOpenIndicationModal}
                  // id={`repondente-${instrumento._id}`}
                >
                  <Tooltip
                    title="Cadastrar Respondentes"
                    size="small"
                    position="top"
                    arrow
                  >
                    <FaUserFriends size={20} />
                  </Tooltip>
                </CircularButton>
              </>
            </Action>
          )}
          {this.props.showPlayButton ? (
            <Action>
              <CircularButton
                // id={`finalizado--play-${instrumento._id}`}
                onClick={this.props.play}
              >
                <Tooltip
                  title="Acessar atividade"
                  size="small"
                  position="top"
                  arrow
                >
                  <FaPlay />
                </Tooltip>
              </CircularButton>
            </Action>
          ) : (
            ""
          )}
          {!this.props.showPlayButton ? (
            <>
              {this.props.userCanSeeResults && notRespondente && (
                <Action>
                  <CircularButton
                    onClick={() =>
                      this.props.showResults(this.props.instrumento)
                    }
                    // id={`finalizado--results-${instrumento._id}`}
                  >
                    <Tooltip
                      title="Ver resultados"
                      size="small"
                      position="top"
                      arrow
                    >
                      <FaEye size={20} />
                    </Tooltip>
                  </CircularButton>
                </Action>
              )}
            </>
          ) : (
            ""
          )}
        </ItemActions>
      </ItemInstrumento>
    );
  }
}
