// eslint-disable-next-line
import React, { Component, createRef } from "react";
import { Prompt } from 'react-router';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Row, Col } from "reactstrap";
import api, { regularApi } from "../../../services/api";
import TaskList from "../../components/general/TaskList";
import { notifyError, notifySuccess } from "../../../services/notificacoes";
import ErrorBoundary from "../../components/general/ErrorBoundary";
import RespostaEstilos from "./Components/RespostaEstilos";
import "./css/general.css";
import withSubscription from "./hoc-instrumentos-assincronos";
import InstrumentContainer from "../../components/InstrumentContainer";
import Messages from '../../../static/Messages';
import NewSubHeader from "../ListInstruments/components/NewSubHeader";
import { ASYNCHRONOUS_INSTRUMENTS_IDS } from "../../../constants/instruments-ids";
import ActivityActions from "./Components/ActivityActions";
import { scrollToUnfinishedQuestion } from './utils/scrollToUnfinishedQuestion';

class EstilosDeComunicacao extends Component {
  state = {
    dados: [
      {
        data: [],
      },
    ],
    questoesDnD: [],
    escolhasDnD: [],
    questionsQtty: 0,
    enunciado: ''
  };

  graphicRefs = [];

  notify = () =>
    toast.error("Insira apenas números 1,3,4 ou 6", {
      position: toast.POSITION.TOP_RIGHT,
    });

  componentDidMount = async () => {
    await this.props.validateAccess(
      localStorage.getItem("assincrono"),
      localStorage.getItem("instrumento")
    );
    const response = await regularApi.get(
      "api/instruments/649ddd2359ded476e85f1e67"
    );
    const dadosDb = response.data;
    const { atividades } = dadosDb.data[0];
    this.setState({ questionsQtty: atividades.length });
    let questoesDnD = [];
    const atividadesCopy = JSON.parse(JSON.stringify(atividades));
    const savedAnswers = await api.get(
      `/respondente/${localStorage.getItem("user")}/${localStorage.getItem(
        "assincrono"
      )}/${localStorage.getItem("instrumento")}/${localStorage.getItem(
        "projeto"
      )}`
    );

    let escolhasDnD = new Array(questoesDnD.length);

    const storageName0 = `estilosEscolhasResp-${localStorage.getItem('assincrono')}`;
    const storageName1 = `estilosQuestionsResp-${localStorage.getItem('assincrono')}`;

    this.props.getStorageName([storageName0, storageName1]);

    if (sessionStorage.getItem(storageName0) || sessionStorage.getItem(storageName1)) this.props.setHasChanged(true);

    if (sessionStorage.getItem(storageName0)) {
      escolhasDnD = JSON.parse(sessionStorage.getItem(storageName0));
    } else if (savedAnswers.data.escolhas) {
      savedAnswers.data.escolhas.map((answer, index) => {
        if (answer) escolhasDnD[answer.indexAtividade] = answer;
      });
    }

    if (sessionStorage.getItem(storageName1)) {
      questoesDnD = JSON.parse(sessionStorage.getItem(storageName1));
    } else {
      atividadesCopy.forEach((ativ, indexAtividade) => {
        questoesDnD.push({
          indexAtividade,
          respostas: ativ.resposta,
        });
      });
      sessionStorage.setItem(storageName1, JSON.stringify(questoesDnD));
    }

    questoesDnD.map((questao) => {
      if (escolhasDnD[questao.indexAtividade]) {
        escolhasDnD[questao.indexAtividade].respostas.map((resposta) => {
          let indexResp = questoesDnD[questao.indexAtividade].respostas.indexOf(
            resposta
          );
          if (indexResp >= 0)
            questoesDnD[questao.indexAtividade].respostas.splice(indexResp, 1);
        });
      }
    });

    this.setState({ enunciado: dadosDb.data[0].enunciado && dadosDb.data[0].enunciado.replaceAll("{nome_participante}", localStorage.getItem('userName')), dados: [dadosDb], questoesDnD, escolhasDnD });
  };

  getValorByPosicao(indexItem) {
    switch (indexItem) {
      case 0:
        return 6;
      case 1:
        return 4;
      case 2:
        return 3;
      case 3:
        return 1;
    }
  }

  reposicionarRespostasAtividade = (result, indexAtividade) => {
    let { escolhasDnD } = this.state;
    let { destination, source, draggableId } = result;
    draggableId = draggableId - 1;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    let indexQuestao = escolhasDnD.findIndex(
      (q) => q && q.indexAtividade === indexAtividade
    );
    let escolhaAtividade = escolhasDnD[indexQuestao];
    const respostas = escolhaAtividade.respostas;
    const respSelecionada = respostas[source.index];
    respostas.splice(source.index, 1);
    respostas.splice(destination.index, 0, respSelecionada);
    this.props.setHasChanged(true);
    sessionStorage.setItem(`estilosEscolhasResp-${localStorage.getItem('assincrono')}`, JSON.stringify(escolhasDnD));
    this.setState({ escolhasDnD });
  };

  onDragEnd = (result, indexAtividade) => {
    Array.prototype.insert = function(index, item) {
      this.splice(index, 0, item);
    };

    const { source, destination } = result;

    try {
      let escolhas = [...this.state.escolhasDnD];
      let questoes = [...this.state.questoesDnD];

      if (!escolhas[indexAtividade]) {
        escolhas[indexAtividade] = {
          indexAtividade,
          respostas: [],
        };
      }

      if (
        destination.droppableId.split("-")[1] !=
          source.droppableId.split("-")[1] &&
        destination.droppableId.split("-")[1] == "ordered"
      ) {
        escolhas[indexAtividade].respostas.insert(
          destination.index,
          this.state.questoesDnD[indexAtividade].respostas[source.index]
        );
        questoes[indexAtividade].respostas.splice(source.index, 1);
        questoes.filter((q) => q);

        this.setState({ escolhasDnD: [...escolhas] });
        this.setState({ questoesDnD: [...questoes] });

        // const posicaoResposta = result.destination.index;
        this.props.setHasChanged(true);
        sessionStorage.setItem(
          `estilosEscolhasResp-${localStorage.getItem("assincrono")}`,
          JSON.stringify(escolhas)
        );
      }

      if (
        destination.droppableId.split("-")[1] ==
          source.droppableId.split("-")[1] &&
        destination.droppableId.split("-")[1] == "ordered"
      ) {
        this.reposicionarRespostasAtividade(result, indexAtividade);
      }
    } catch (error) {
      console.error(error);
    }
  };

  validateEscolhas = escolhas => {
    const { questionsQtty } = this.state;
    let valid = true;
    if (escolhas.length < questionsQtty) valid = false;
    for (const escolha of escolhas) {
      if (escolha.respostas.length < 4) valid = false;
    }

    return valid;
  }

  enviar = async (e) => {
    try {
      e.preventDefault();
      let { escolhasDnD, dados } = this.state;
      const questoesOriginal = dados[0].data[0].atividades;


      if (!this.validateEscolhas(escolhasDnD)) {
        notifyError(Messages.Assincronos.msgValidation);
        return
      };

      let soma1 = 0;
      let soma1b = 0;
      let soma1c = 0;
      let soma1d = 0;
      let soma2 = 0;
      let soma2b = 0;
      let soma2c = 0;
      let soma2d = 0;
      let soma3 = 0;
      let soma3b = 0;
      let soma3c = 0;
      let soma3d = 0;
      let soma4 = 0;
      let soma4b = 0;
      let soma4c = 0;
      let soma4d = 0;
      let soma5 = 0;
      let soma5b = 0;
      let soma5c = 0;
      let soma5d = 0;
      let soma6 = 0;
      let soma6b = 0;
      let soma6c = 0;
      let soma6d = 0;
      // / RESULTADO

      // i: questão
      for (let i = 0; i < escolhasDnD.length; i++) {
        const resp0 = questoesOriginal[i].resposta[0];
        const resp1 = questoesOriginal[i].resposta[1];
        const resp2 = questoesOriginal[i].resposta[2];
        const resp3 = questoesOriginal[i].resposta[3];

        const posResp0 = escolhasDnD[i].respostas.findIndex(
          (resp) => resp === resp0
        );
        const valorResp0 = this.getValorByPosicao(posResp0);
        const posResp1 = escolhasDnD[i].respostas.findIndex(
          (resp) => resp === resp1
        );
        const valorResp1 = this.getValorByPosicao(posResp1);
        const posResp2 = escolhasDnD[i].respostas.findIndex(
          (resp) => resp === resp2
        );
        const valorResp2 = this.getValorByPosicao(posResp2);
        const posResp3 = escolhasDnD[i].respostas.findIndex(
          (resp) => resp === resp3
        );
        const valorResp3 = this.getValorByPosicao(posResp3);

        if (i == 0 || i == 6 || i == 12) {
          soma1 += Number(valorResp0);
          soma1b += Number(valorResp1);
          soma1c += Number(valorResp2);
          soma1d += Number(valorResp3);
        }
        if (i == 1 || i == 7 || i == 13) {
          soma2 += Number(valorResp0);
          soma2b += Number(valorResp1);
          soma2c += Number(valorResp2);
          soma2d += Number(valorResp3);
        }
        if (i == 2 || i == 8 || i == 14) {
          soma3 += Number(valorResp0);
          soma3b += Number(valorResp1);
          soma3c += Number(valorResp2);
          soma3d += Number(valorResp3);
        }
        if (i == 3 || i == 9 || i == 15) {
          soma4 += Number(valorResp0);
          soma4b += Number(valorResp1);
          soma4c += Number(valorResp2);
          soma4d += Number(valorResp3);
        }
        if (i == 4 || i == 10 || i == 16) {
          soma5 += Number(valorResp0);
          soma5b += Number(valorResp1);
          soma5c += Number(valorResp2);
          soma5d += Number(valorResp3);
        }
        if (i == 5 || i == 11 || i == 17) {
          soma6 += Number(valorResp0);
          soma6b += Number(valorResp1);
          soma6c += Number(valorResp2);
          soma6d += Number(valorResp3);
        }
      }

      await api.put(
        `/respondente/${localStorage.getItem("user")}/${localStorage.getItem(
          "assincrono"
        )}/${localStorage.getItem("instrumento")}`,
        {
          resposta: {
            pr: soma1 + soma2c + soma3d,
            s: soma1b + soma2 + soma3c,
            pn: soma1c + soma2b + soma3b,
            i: soma1d + soma2d + soma3,

            prS: soma4c + soma5c + soma6b,
            sS: soma4d + soma5 + soma6d,
            pnS: soma4 + soma5b + soma6,
            iS: soma4b + soma5d + soma6c,
            escolhas: escolhasDnD,
            finished: true,
          },
          projetoID: localStorage.getItem("projeto"),
        }
      );

      const res = await api.put(`/respondente/${localStorage.getItem("user")}/${localStorage.getItem("assincrono")}/${localStorage.getItem("instrumento")}/group-answerers-result?projectId=${localStorage.getItem("projeto")}`)

      if (res.data && res.data.grafico) {
        const { grafico } = res.data

        const promises = Object.keys(grafico).map(async key => {
          if (grafico[key].changed) {
            const result = { ...grafico[key] }

            await regularApi.post(`/v3/results/user/${localStorage.getItem("user")}/assincrono/${localStorage.getItem(
              "assincrono"
            )}/instrument/${localStorage.getItem("instrumento")}/save-text-result`, {
              result,
              answerer: grafico[key].relationId,
              projectId: localStorage.getItem("projeto")
            });
          }
        })

        await Promise.all(promises)
      }

      this.props.setHasChanged(false);
      this.props.history.goBack();
    } catch (err) {
      this.props.handleError(err);
    }
  };

  saveAnswer = async () => {
    const { escolhasDnD } = this.state;

    try {
      await api.put(
        `/respondente/${localStorage.getItem("user")}/${localStorage.getItem(
          "assincrono"
        )}/${localStorage.getItem("instrumento")}`,
        {
          resposta: {
            escolhas: escolhasDnD,
          },
          projetoID: localStorage.getItem("projeto"),
        }
      );
      this.props.setHasChanged(false);
      notifySuccess(
        'Atividade salva com sucesso. Caso queira, você poderá modificar a atividade até o prazo final'
      );
    } catch (err) {
      this.props.handleError(err);
    }
  };

  voltar = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div>
        <Prompt
          when={this.props.handleBack()}
          message={Messages.Assincronos.msgConfirmacao}
        />
        <InstrumentContainer>
          <NewSubHeader
            titulo={`Estilos de comunicação - ${localStorage.getItem("userName")}`}
            instrucao={this.state.enunciado}
            idInstrumento={
              ASYNCHRONOUS_INSTRUMENTS_IDS.EstiloDeComunicacao
            }
          />
          <div>
            {this.state.dados.map((dados) => (
              <div key={dados._id}>
                {dados.data.map((data, index) => (
                  <>
                    <div key={index}>
                      {data.atividades.map((atividade, indexAtividade) => (
                        this.graphicRefs.push(createRef()),
                        <div ref={this.graphicRefs[indexAtividade]} key={indexAtividade}>
                          <p>
                            <b>
                              {indexAtividade + 1}. {atividade.pergunta.replaceAll("{nome_participante}", localStorage.getItem("userName"))}
                            </b>
                          </p>
                          <Row>
                            <ErrorBoundary>
                              <DragDropContext
                                onDragEnd={(result, e) =>
                                  this.onDragEnd(result, indexAtividade)
                                }
                              >
                                <Col
                                  sm="6"
                                  xs="12"
                                  style={{
                                    marginBottom: 10,
                                  }}
                                >
                                  <Droppable
                                    droppableId={`${indexAtividade}-ordered`}
                                    key={`${indexAtividade}-ordered`}
                                  >
                                    {(provided, snapshot) => (
                                      <TaskList
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        isDraggingOver={snapshot.isDraggingOver}
                                        isAnswer={true}
                                        style={{
                                          border: "1px dashed #c9c9c9",
                                          paddingTop: 10,
                                          paddingLeft: 10,
                                          paddingRight: 10,
                                          height: "calc(100% + 2px)",
                                          margin: "0",
                                        }}
                                      >
                                        {(() => {
                                          const escolhasCopyForDnD = this.state.escolhasDnD.find(
                                            (q) =>
                                              q &&
                                              q.indexAtividade ===
                                                indexAtividade
                                          );

                                          return escolhasCopyForDnD &&
                                            escolhasCopyForDnD.respostas
                                              .length ? (
                                            escolhasCopyForDnD.respostas.map(
                                              (conteudo, indexResp) => (
                                                <Col sm="12" md="12">
                                                  <RespostaEstilos
                                                    index={indexResp}
                                                    key={indexResp}
                                                    resp={conteudo}
                                                    indexResp={indexResp}
                                                    column="ordered"
                                                    style={{
                                                      padding: 0,
                                                    }}
                                                  />
                                                </Col>
                                              )
                                            )
                                          ) : (
                                            <span style={{ color: "#777" }}>
                                              Ordene as frases aqui
                                            </span>
                                          );
                                        })()}
                                      </TaskList>
                                    )}
                                  </Droppable>
                                </Col>
                                <Col
                                  sm="6"
                                  xs="12"
                                  style={{ padding: "0 25px" }}
                                >
                                  <Droppable
                                    droppableId={`${indexAtividade}-available`}
                                    key={`${indexAtividade}-available`}
                                  >
                                    {(provided, snapshot) => (
                                      <TaskList
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        isDraggingOver={snapshot.isDraggingOver}
                                      >
                                        {(() => {
                                          const questaoCopyForDnD = this.state.questoesDnD.find(
                                            (q) =>
                                              q.indexAtividade ===
                                              indexAtividade
                                          );

                                          return (
                                            questaoCopyForDnD.respostas.filter(
                                              (r) => r
                                            ).length > 0 &&
                                            questaoCopyForDnD.respostas.map(
                                              (conteudo, indexResp) => (
                                                <>
                                                  {conteudo && (
                                                    <Col sm="12" md="12">
                                                      <RespostaEstilos
                                                        index={indexResp}
                                                        key={indexResp}
                                                        resp={conteudo}
                                                        indexResp={indexResp}
                                                        column="available"
                                                      />
                                                    </Col>
                                                  )}
                                                </>
                                              )
                                            )
                                          );
                                        })()}
                                        <Col sm="6">{provided.placeholder}</Col>
                                      </TaskList>
                                    )}
                                  </Droppable>
                                </Col>
                              </DragDropContext>
                            </ErrorBoundary>
                          </Row>
                          <hr />
                        </div>
                      ))}
                    </div>
                  </>
                ))}
              </div>
            ))}
            <ActivityActions
              back={() => this.voltar()}
              save={this.saveAnswer}
              send={this.enviar}
              nextSend={() => scrollToUnfinishedQuestion(this.graphicRefs, this.state.dados[0].data[0].atividades, this.state.escolhasDnD, 0, true)}
            />
          </div>
        </InstrumentContainer>
      </div>
    );
  }
}

export default withSubscription(EstilosDeComunicacao);
