/**
 * Formata o nome de um instrumento para ser
 * usado como url
 *
 * @export
 * @param {string} instrumentName
 * @returns {string} o nome do instrumento formatado
 */
export default function formatInstrumentURL(instrumentName) {
  /**
   * As palavras que compoem o nome do instrumento
   * @type {string[]}
   */
  const words = instrumentName.split(' ');
  /**
   * As palavras que compoem o nome do instrumento,
   * sem acentos
   * @type {string[]}
   */
  const formattedWords = words.map(removeAccent);
  return formattedWords.join('-').toLowerCase();
}

/**
 * Remove acentos de uma palavra ou frase
 *
 * @export
 * @param {string} word A palavra ou frase para remover acentos
 * @returns {string} A palavra fornecida sem acentos e em letras maiúsculas
 */
export function removeAccent(word) {
  /**
   * A relação de letras e letras com acentos
   * que deve ser usada para mapear os acentos
   * @type {Object<string, Array<string>>}
   */
  const accents = {
    A: ['Á', 'À', 'Ã', 'Â'],
    E: ['É', 'Ê', 'È', 'Ẽ'],
    O: ['Ó', 'Ô', 'Õ', 'Ò'],
    I: ['Í', 'Ì', 'Ĩ', 'Î'],
    U: ['Ú', 'Ù', 'Û', 'Ũ'],
    C: ['Ç'],
  };
  word = word.toUpperCase();
  Object.keys(accents).forEach(letra => {
    const variantes = accents[letra];
    variantes.forEach(variante => {
      word = word.split(variante).join(letra);
    });
  });
  return word;
}
