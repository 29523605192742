import React, { Component } from "react";
import PropTypes from "prop-types";

import { ItemInstrumento, ItemActions, Action } from "./styles";
import { DetalhesDoInstrumento } from "./styles";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { CircularButton } from "../../../Assets/Assets";
import { FaEye } from "react-icons/fa";
import dynamicIcon from "../../../../assets/img/dynamic_black.svg";
import { capitalizeFirstLetter } from "../../../../utils/masks";
import { Tooltip } from "react-tippy";

export default class ItemInstrumentoDinamica extends Component {
  static propTypes = {
    indice: PropTypes.number,
    instrumento: PropTypes.object.isRequired,
    userCanAnswer: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    userCanAnswer: false,
  };

  constructor(props) {
    super(props);
  }

  handleOpenIndicationModal = () => {
    const {
      openRespondentModal,
      instrumento: { _id, idGrupo, projectId },
    } = this.props;
    openRespondentModal(idGrupo, _id, projectId);
  };

  render() {
    return (
      <ItemInstrumento>
        <div>
          <img src={dynamicIcon} width="40" />
        </div>
        <DetalhesDoInstrumento sm="8">
          <Row>
            <Col>
              <strong>{capitalizeFirstLetter(this.props.nome)}</strong>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>Data: {this.props.data}</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>Projeto: {this.props.projectName}</span>
            </Col>
          </Row>
        </DetalhesDoInstrumento>
        {this.props.instrumentsPermissions &&
          this.props.instrumentsPermissions.length > 0 &&
          this.props.instrumentsPermissions.filter(
            (instrument) => instrument.showResults
          ).length > 0 && (
            <ItemActions sm="3">
              <Action>
                <CircularButton
                  onClick={() =>
                    this.props.showResultsDynamic(
                      this.props.id,
                      this.props.projectId
                    )
                  }
                  id={`finalizado--results-${this.props.id}`}
                >
                  <Tooltip
                    title="Ver resultados"
                    size="small"
                    position="top"
                    arrow
                  >
                    <FaEye size={20} />
                  </Tooltip>
                </CircularButton>
              </Action>
            </ItemActions>
          )}
      </ItemInstrumento>
    );
  }
}
