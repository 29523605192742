const Messages = {
    Assincronos: {
      msgSendAnswerSuccess: "Resposta enviada!",
      msgSaveAnswerSuccess: `Atividade salva com sucesso.
        Caso queira, você poderá modificar a atividade até o prazo final`,

      msgErrorSendAnswer: "Não foi possível enviar resposta. Por favor, tente novamente",
      msgErrorSaveAnswer: "Não foi possível salvar resposta. Por favor, tente novamente",

      msgErrorLoadResult:
        "Não foi possível carregar resultados. Por favor, recarregue a página e tente novamente",
      msgErrorLoadDados:
        "Não foi possível carregar instrumento. Por favor, recarregue a página",

      msgErrorFetchAssincData:
        "Não foi possível carregar dados do grupo de instrumentos. Por favor, recarregue a página",

      /**
       * exibida quando o participante não respondeu todas as questões
      */
      msgValidation: "Existem questões não respondidas. Preencha as questões e tente novamente.",
      msgValidation1: "Responda todas as questões",

      msgConfirmacao: "É possivel que suas alterações não tenham sido salvas.",

      /**
       * mensagem do modal de confirmação
       */
      msgConfirmacao2: "Você tem certeza que deseja enviar o questionário ? Após o envio não será permitido alteração/visualização das respostas.",
      msgNoPermission: "Você não tem permissão para acessar este instrumento"
    },

    Sincronos: {

      Dinamicas: {

        msgCriteriosInsulficientes: "AVISO: A quantidade de critérios criados é insulficiente. Todas as atividades/etapas da rodada que dependem do resultado do case serão canceladas. Deseja continuar?"
      }
    },

    Geral: {

      msgNotImplemented: "Ainda não implementado",
      msgOperationError: "Não foi possível executar a operação selecionada. Por favor, tente novamente",
      msgDataFetchError: "Não foi possível trazer informações. Por favor, recarregue a página",
      msgTryAgain: "Ocorreu um erro. Tente novamente"
    },

    Error: {

      msgCsvError: "Não foi possível processar arquivo CSV informado. Por favor, verifique os dados do arquivo e tente novamente"
    }
  };

  export default Messages;
