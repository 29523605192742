import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import api from '../../services/api';
import { Redirect } from 'react-router-dom';
import './scss/Header.scss';


function Header() {
  const [logoff, setLogoff] = useState(false);
  const [loginScreen, setLoginScreen] = useState(false);
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [respondente, setRespondente] = useState({});
  const [historyLoaded, setHistoryLoaded] = useState(false);

  const history = useHistory();

  function toggle() {
    setDropDownOpen(!dropdownOpen);
  }

  useEffect(()=>{
    setHistoryLoaded(false);
    let pathArray = history.location.pathname.split("/");

    for (const p of pathArray) {
      if (p.match(/^[0-9a-fA-F]{24}$/)) {
        pathArray = pathArray.filter((pOpt) => pOpt !== p);
      }
    }

    pathArray = pathArray.filter(p => p);

    if (!pathArray.length) setLoginScreen(true);
    else if (pathArray.length && loginScreen) setLoginScreen(false);

    setHistoryLoaded(true);
  },[history.location.pathname]);

  useEffect(() => {
    async function loadRespondente() {
      if (!historyLoaded) return;

      if (loginScreen) return;
      const res = await api.get(`/respondente/get-respondente/${sessionStorage.getItem('respondenteId')}`);
      setRespondente(res.data);
    }

    loadRespondente();
  },[historyLoaded, loginScreen])

  if (loginScreen) return null;

  if (logoff) {
    return <Redirect to='/' />;
  }

  return(
    <nav className='navbar navbar-dark fixed-top flex-md-nowrap header-participante' style={{ backgroundColor: '#002a4c'}}>
        {/* <ModalAlterarSenha
          cpf={this.state.cpf}
          senhaAntiga={this.state.senhaAntiga}

          isOpen={ this.state.modalAlterarSenha }
          toggle={() => this.toggleModal("modalAlterarSenha")}
          handleClose={() => this.handleModalClose("modalAlterarSenha")}
          handleChange={this.handleChange}
        />
        <ModalEditarAcesso
          cpf={this.state.cpf}
          history={this.props.history}
          email={this.state.email}
          nome={this.state.nome}
          celular={this.state.celular}
          handleChange={this.handleChange}

          isOpen={this.state.modalEditarAcesso}
          toggle={() => this.toggleModal("modalEditarAcesso")}
          handleClose={() => this.handleModalClose("modalEditarAcesso")}
        /> */}
        <Row className='without-margin'>
          <Col sm='2' className='without-padding nome-header-wrapper'>
            <ButtonDropdown
              isOpen={dropdownOpen}
              toggle={toggle} >
              <DropdownToggle
                caret
                style={{...styles.nomeParticipante, fontSize: 12, background: 'transparent'}}>
                {respondente.nome}
              </DropdownToggle>
              <DropdownMenu left>
                {/* <DropdownItem style={{ cursor: 'pointer', fontSize: 12 }} tag="div" onClick={this.editarConfig}>
                    Edição perfil
                </DropdownItem>
                <DropdownItem style={{ cursor: 'pointer', fontSize: 12 }} tag="div" onClick={this.alterarSenha}>
                    Alterar Senha
                </DropdownItem> */}
                <DropdownItem style={{ cursor: 'pointer', fontSize: 12 }} tag="div" onClick={() => {
                    const id = sessionStorage.getItem('respondenteId');
                    sessionStorage.clear()
                    window.location.href = `/${id}`
                  }}>
                    Sair
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
        </Row>
      </nav>
  )
}

const styles = {
  icon_14: {
    width: '14px',
    height: '14px'
  },
  pointerCursor: {
    cursor: 'pointer'
  },
  nomeParticipante: {
    textDecoration: 'underline solid grey'
  }
}

export default Header;
