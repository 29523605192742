import React, { Component, createRef } from 'react';
import { Prompt } from 'react-router';
import { Modal, Row, ModalBody, Col, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from '../../components/Buttons'
import { toast } from 'react-toastify';
import api, { regularApi } from '../../../services/api';
import { notifyError, notifySuccess, notifyInfo } from '../../../services/notificacoes';
import 'react-toastify/dist/ReactToastify.css';
import { CustomCheckbox, CustomRadio } from '../../components/general';
import './css/AncoraDaCarreira.css';
import './css/general.css';
import withSubscription from "./hoc-instrumentos-assincronos";
import ActivityActions from './Components/ActivityActions';
import NewSubHeader from '../ListInstruments/components/NewSubHeader';
import { NewCustomRadio } from '../../components/general';
import { RadioWrapper } from '../../components/general/NewCustomRadio';
import Messages from '../../../static/Messages';
import InstrumentContainer from '../../components/InstrumentContainer';
import { assincronoInstrumentosIDS } from '../../../services/Constantes';
import { scrollToUnfinishedQuestion } from './utils/scrollToUnfinishedQuestion';

// SOMA GRUPOS E TIRA MEDIA
// O feedback é relacionado o que tem valor mais alto e o que tem até 10% menos
class AncorasDaCarreira extends Component {
  graphicRefs = [];

  constructor(props) {
    super(props);
    this.state = {
      dados: [],
      consolidado: [],
      mapa: [],
      status: '',
      tf: [],
      gg: [],
      ai: [],
      se: [],
      ce: [],
      sd: [],
      dp: [],
      ev: [],
      modal: false,
      content: [],
      /** Os números das três frases escolhidas pelo usuário
       * @type {number[]}
       */
      chosen: [],
      /** `true` se o modal de questões não respondidas deve ser mostrado
       * @type {boolean}
       */
      notAnsweredModal: false
    };
  }

  async componentDidMount() {
    await this.props.validateAccess(localStorage.getItem('assincrono'), localStorage.getItem('instrumento'));
    const response = await regularApi.get(
      'api/instruments/5c176942342a0f7306b8a093'
    );
    const dadosDb = response.data;
    const savedAnswers = await api.get(
      `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
        'assincrono'
      )}/${localStorage.getItem('instrumento')}/${localStorage.getItem(
        'projeto'
      )}`
    );
    const storageName = `ancorasEscolhas-${localStorage.getItem('assincrono')}`;
    this.props.getStorageName([storageName]);
    if (sessionStorage.getItem(storageName)) this.props.setHasChanged(true);
    this.setState({
      dados: [dadosDb],
      status: 'default',
      consolidado: sessionStorage.getItem(storageName) ?
      JSON.parse(sessionStorage.getItem(storageName)) : savedAnswers.data ? savedAnswers.data.escolhas : []
    });
  }

  toggle = () => {
    if (this.state.modal === true) {
      this.setState({ modal: false });
    } else {
      this.setState({ modal: true });
    }
  };

  notify = () =>
    toast.error('Insira apenas números entre 1 e 6', {
      position: toast.POSITION.TOP_RIGHT
    });

  handleChange = (e, index, enun) => {
    //  console.log(index)
    const re = /^[1-6\b]+$/;
    if (re.test(e.target.value)) {
      const { consolidado } = this.state;
      consolidado[index] = {
        resp: e.target.value,
        enun,
        index: index + 1
      };
      this.props.setHasChanged(true);
      sessionStorage.setItem(`ancorasEscolhas-${localStorage.getItem('assincrono')}`, JSON.stringify(consolidado));
      this.setState({ consolidado });
    } else {
      e.target.value = '';
      this.notify();
    }
    //  console.log(this.state.consolidado)
  };

  saveAnswer = async () => {
    try {
      await api.put(
        `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
          'assincrono'
        )}/${localStorage.getItem('instrumento')}`,
        {
          resposta: {
            escolhas: this.state.consolidado
          },
          projetoID: localStorage.getItem('projeto')
        }
      );
      this.props.setHasChanged(false);
      notifySuccess(
        'Atividade salva com sucesso. Caso queira, você poderá modificar a atividade até o prazo final'
      );
    } catch (err) {
      this.props.handleError(err);
    }
  };

  calculaEnviaResultado = async () => {
    try {
      let somaTF = 0;
      let somaGG = 0;
      let somaAI = 0;
      let somaSE = 0;
      let somaCE = 0;
      let somaSD = 0;
      let somaDP = 0;
      let somaEV = 0;

      for (let i = 0; i <= 40; i++) {
        if (i === 1 || i === 9 || i === 17 || i === 25 || i === 33) {
          somaTF += Number(this.state.consolidado[i - 1].resp);
        }
        if (i === 2 || i === 10 || i === 18 || i === 26 || i === 34) {
          somaGG += Number(this.state.consolidado[i - 1].resp);
        }
        if (i === 3 || i === 11 || i === 19 || i === 27 || i === 35) {
          somaAI += Number(this.state.consolidado[i - 1].resp);
        }
        if (i === 4 || i === 12 || i === 20 || i === 28 || i === 36) {
          somaSE += Number(this.state.consolidado[i - 1].resp);
        }
        if (i === 5 || i === 13 || i === 21 || i === 29 || i === 37) {
          somaCE += Number(this.state.consolidado[i - 1].resp);
        }

        if (i === 6 || i === 14 || i === 22 || i === 30 || i === 38) {
          somaSD += Number(this.state.consolidado[i - 1].resp);
        }
        if (i === 7 || i === 15 || i === 23 || i === 31 || i === 39) {
          somaDP += Number(this.state.consolidado[i - 1].resp);
        }
        if (i === 8 || i === 16 || i === 24 || i === 32 || i === 40) {
          somaEV += Number(this.state.consolidado[i - 1].resp);
        }
      }

      const resultados = [
        somaTF,
        somaGG,
        somaAI,
        somaSE,
        somaCE,
        somaSD,
        somaDP,
        somaEV
      ];
      this.state.chosen.forEach(index => {
        const selectedIndex = index % 8;
        resultados[selectedIndex] += 4;
      });
      [
        somaTF,
        somaGG,
        somaAI,
        somaSE,
        somaCE,
        somaSD,
        somaDP,
        somaEV
      ] = resultados;
      await api.put(
        `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
          'assincrono'
        )}/${localStorage.getItem('instrumento')}`,
        {
          resposta: {
            somaTF,
            somaGG,
            somaAI,
            somaSE,
            somaCE,
            somaSD,
            somaDP,
            somaEV,
            escolhas: this.state.consolidado,
            finished: true
          },
          projetoID: localStorage.getItem('projeto')
        }
      );
      this.props.setHasChanged(false);
      this.props.history.goBack();
      return { somaTF, somaGG, somaAI, somaSE, somaCE, somaSD, somaDP, somaEV };
    } catch (err) {
      this.props.handleError(err);
    }
  };

  toggleNotAnswered = () => {
    this.setState(
      {
        notAnsweredModal: !this.state.notAnsweredModal
      }
    );
  };

  pegaRespostas = e => {

    e.preventDefault();
    if (
      this.state.consolidado.filter(e => e).length === 40
    ) {
      /** A quantidade de frases que o participante
       * atribuiu a compatibilidade de 6
       * @type {number}
       */
      const sixCounter = this.state.consolidado.reduce(
        (acc, a) => (a.resp === '6' ? acc + 1 : acc),
        0
      );
      /** Os índices das questões que estão com nota 6
       * @type {number}
       */
      const sixIndices = this.state.consolidado
        .filter(resposta => resposta.resp === '6')
        .map(resposta => resposta.index - 1);
      if (sixCounter === 3) {
        this.setState(
          {
            chosen: sixIndices
          },
          () => this.calculaEnviaResultado()
        );
      } else if (sixCounter > 3) {
        this.setState({
          content: this.state.consolidado.filter((_, i) =>
            sixIndices.includes(i)
          )
        });
        this.toggle();
      } else {
        notifyError('Você precisa de, pelo menos, três questões com nota 6');
      }
    } else {
      notifyError(Messages.Assincronos.msgValidation);
      setTimeout(() => {
        scrollToUnfinishedQuestion(this.graphicRefs, this.state.dados[0].data[0].atividades[1].alternativas, this.state.consolidado);
      }, 650);
    }
  };

  voltar = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div>
        <Prompt
          when={this.props.handleBack()}
          message={Messages.Assincronos.msgConfirmacao}
        />
        {/* <Header /> */}
        <InstrumentContainer>

          <div>
            {this.state.dados.map(data =>
              data.data.map((dados, index) => (
                <div key={index}>
                  {/* <p>{dados.atividades[0].enunciado}</p> */}
                  <NewSubHeader
                    titulo="Âncora de Carreira"
                    instrucao={dados.atividades[0].enunciado}
                    idInstrumento={assincronoInstrumentosIDS.AncorasDaCarreira}
                  />
                  {dados.atividades[1].alternativas.map((dados, index) => (
                    this.graphicRefs.push(createRef()),
                    <>
                    <div ref={this.graphicRefs[index]} key={index} className="margin-top">
                      <div className="sel">
                      <p>
                        <b>
                          {index + 1}. {dados}
                        </b>
                      </p>
                      <Row className="without-margin row-centered remove-margins question align-center" style={{ flexWrap: 'nowrap'  }}>
                      <div><label htmlFor={`${index}-${1}`}>Nunca é verdadeiro para mim</label></div>
                        {[1, 2, 3, 4, 5, 6].map(value => (
                          // <div className="radio-wrapper-Ancora">
                            <RadioWrapper>
                              <NewCustomRadio
                                key={value}
                                name={`value${index}`}
                                onChange={e => this.handleChange(e, index, dados)}
                                checked={
                                  this.state.consolidado[index] &&
                                  this.state.consolidado[index]["resp"] ===
                                    String(value)
                                }
                                value={value}
                                id={`${index}-${value}`}
                                label={value}
                              />
                            </RadioWrapper>
                          // </div>
                        ))}
                        <div style={{textAlign: 'right'}}><label htmlFor={`${index}-${6}`}>Sempre é verdadeiro para mim</label></div>
                      </Row>
                      </div>
                    </div>
                    <hr style={{marginTop: 30}}  />
                    </>
                  ))}
                </div>
              ))
            )}
            <ActivityActions
              save={this.saveAnswer}
              back={() => this.voltar()}
              send={this.pegaRespostas}
            >
            </ActivityActions>
          </div>

          <Modal
            isOpen={this.state.notAnsweredModal}
            toggle={this.toggleNotAnswered}
            className={`question-not-answered ${this.props.className}`}
            size="md"
          >
            <ModalHeader toggle={this.toggleNotAnswered}>Importante</ModalHeader>
            <ModalBody>
              Todas as questões devem ser respondidas
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={() => {
                this.toggleNotAnswered();
                setTimeout(() => {
                  scrollToUnfinishedQuestion(this.graphicRefs, this.state.dados[0].data[0].atividades[1].alternativas, this.state.consolidado);
                }, 650);
              }}>
                Ok, voltar
              </Button>
              <Button
                color="primary"
                onClick={e => {
                  this.saveAnswer();
                  this.toggleNotAnswered();
                }}
              >
                Salvar e responder depois
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className}
            size="xl"
          >
            <ModalHeader toggle={this.toggle}>
              Âncoras de Carreira - Escolher Verdades
            </ModalHeader>
            <ModalBody style={{ fontSize: 12 }}>
              <div style={{ marginBottom: 20 }}>
                <strong>Importante:</strong> Você deve escolher exatamente 3 verdades.
              </div>
              <div>
                {this.state.content.map((resp, index) => (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 5
                }}>
                  <input
                    type="checkbox"
                    checked={this.state.chosen.includes(resp.index - 1)}
                    onClick={e => {
                            if (e.target.checked) {
                              if (this.state.chosen.length === 3) {
                                notifyInfo('Você deve escolher exatamente 3 verdades!');
                                return;
                              }

                              this.setState({
                                chosen: this.state.chosen.concat([resp.index - 1]),
                              });
                            } else {
                              this.setState({
                                chosen: this.state.chosen.filter(
                                  element => element !== resp.index - 1,
                                ),
                              });
                            }
                          }}
                    style={{
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                    id={`true-${index}`}
                  />
                  <label htmlFor={`true-${index}`} className="float-left" key={index}>
                    {resp.enun}
                  </label>
                </div>
              ))}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={this.toggle}>
                Sair
              </Button>
              <Button
                color="success"
                onClick={() => {
                  if (this.state.chosen.length === 3) {
                    this.calculaEnviaResultado();
                  } else {
                    notifyInfo("Você deve escolher exatamente 3 verdades");
                  }
                }}
              >
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
        </InstrumentContainer>
      </div>
    );
  }
}

export default withSubscription(AncorasDaCarreira);
