import React, { Component, createRef } from 'react';
import { Prompt } from 'react-router';
import { Row } from 'reactstrap';
import api, { regularApi } from '../../../services/api';
import { notifySuccess, notifyInfo, notifyError } from '../../../services/notificacoes';
import ActivityActions from './Components/ActivityActions';
import Messages from '../../../static/Messages';
import './css/general.css';
import withSubscription from "./hoc-instrumentos-assincronos";
import InstrumentContainer from '../../components/InstrumentContainer';
import NewSubHeader from '../ListInstruments/components/NewSubHeader';
import { NewCustomRadio } from '../../components/general';
import { RadioWrapper } from '../../components/general/NewCustomRadio';
import { assincronoInstrumentosIDS } from '../../../services/Constantes';
import { scrollToUnfinishedQuestion } from './utils/scrollToUnfinishedQuestion';

// /// SOMA todos os grupos e solta um resultado..

// // Os Grupos são :

// Orientação para Identidade Pessoal - 10 Questoes
// Orientação para Identidade Relacional - 10 Questoes
// Orientação para Identidade Social - 7 Questoes
// Orientação para Identidade Coletiva - 8 Questoes
// Itens especiais [não computados na escala] - 10 Questoes

// / LEMBRAR DE TIRAR A MEDIA -

let respostas = [];

class AspectosIdentidades extends Component {
  graphicRefs = [];

  state = {
    dados: [],
    instrumento: {
      enunciado: ''
    }
  };

  async componentDidMount() {
    await this.props.validateAccess(localStorage.getItem('assincrono'), localStorage.getItem('instrumento'));
    const response = await regularApi.get(
      'api/instruments/5c045959acd7ba15fc075537',
    );
    const dadosDb = response.data;
    const savedAnswers = await api.get(
      `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
        'assincrono',
      )}/${localStorage.getItem('instrumento')}/${localStorage.getItem(
        'projeto',
      )}`,
    );
    const storageName = `aspectosEscolhas-${localStorage.getItem('assincrono')}`;
    this.props.getStorageName([storageName]);
    if (sessionStorage.getItem(storageName)) this.props.setHasChanged(true);
    respostas = sessionStorage.getItem(storageName) ?
    JSON.parse(sessionStorage.getItem(storageName)) : savedAnswers.data ? savedAnswers.data.escolhas : [];
    this.setState({ dados: [dadosDb], instrumento: { enunciado: dadosDb.data && dadosDb.data[0].enunciado } });
  }

  handleRespostas = (e, index) => {
    respostas[index] = e.target.value;
    sessionStorage.setItem(`aspectosEscolhas-${localStorage.getItem('assincrono')}`, JSON.stringify(respostas));
    this.props.setHasChanged(true);
    this.setState({});
  };

  enviar = async e => {
    try {
      let sum1 = 0;
      let sum2 = 0;
      let sum3 = 0;
      let sum4 = 0;
      let sum5 = 0;
      e.preventDefault();
      const ip = [2, 5, 8, 11, 14, 18, 21, 25, 27, 32];
      const ir = [22, 26, 28, 31, 34, 35, 37, 39, 41, 43];
      const is = [3, 6, 9, 12, 15, 17, 20];
      const ic = [4, 7, 10, 13, 24, 29, 38, 42];
      const sp = [1, 16, 19, 23, 30, 33, 36, 40, 44, 45];
      respostas.forEach((valor, i) => {
        if (ip.includes(i + 1)) {
          sum1 += Number(valor);
        }
        if (ir.includes(i + 1)) {
          sum2 += Number(valor);
        }
        if (is.includes(i + 1)) {
          sum3 += Number(valor);
        }
        if (ic.includes(i + 1)) {
          sum4 += Number(valor);
        }
        if (sp.includes(i + 1)) {
          sum5 += Number(valor);
        }
      });

      if (this.state.dados[0] && this.state.dados[0].data && this.state.dados[0].data[0]) {
        if (respostas.filter(r => r).length < this.state.dados[0].data[0].alternativas.length) {
          notifyError(Messages.Assincronos.msgValidation);
          return;
        }
      }


    if (sum1 === null || isNaN(sum1)) {

      return notifyError('Falha ao enviar instrumento. Selecione todas questões e tente novamente');

    }

    if (sum2 === null || isNaN(sum2)) {

      return notifyError('Falha ao enviar instrumento. Selecione todas questões e tente novamente');

    }

    if (sum3 === null || isNaN(sum3)) {

      return notifyError('Falha ao enviar instrumento. Selecione todas questões e tente novamente');

    }

    if (sum4 === null || isNaN(sum4)) {

      return notifyError('Falha ao enviar instrumento. Selecione todas questões e tente novamente');

    }

    if (sum5 === null || isNaN(sum5)) {

      return notifyError('Falha ao enviar instrumento. Selecione todas questões e tente novamente');

    }

      await api.put(
        `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
          'assincrono',
        )}/${localStorage.getItem('instrumento')}`,
        {
          resposta: {
            ip: sum1 / 10,
            ir: sum2 / 10,
            is: sum3 / 7,
            ic: sum4 / 8,
            sp: sum5 / 10,
            escolhas: respostas,
            finished: true
          },
          projetoID: localStorage.getItem('projeto'),
        },
      );
      this.props.setHasChanged(false);
      this.props.history.goBack();
    } catch (err) {
      this.props.handleError(err);
    }
  };

  saveAnswer = async () => {
    try {
      await api.put(
        `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
          'assincrono',
        )}/${localStorage.getItem('instrumento')}`,
        {
          resposta: {
            escolhas: respostas,
          },
          projetoID: localStorage.getItem('projeto'),
        },
      );
      this.props.setHasChanged(false);
      notifySuccess(
        'Atividade salva com sucesso. Caso queira, você poderá modificar a atividade até o prazo final',
      );
    } catch (err) {
      this.props.handleError(err);
    }
  };

  voltar = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <>
        <Prompt
          when={this.props.handleBack()}
          message={Messages.Assincronos.msgConfirmacao}
        />
        {/* <Header /> */}
        <div>
          {/*  */}
          <InstrumentContainer>

          <NewSubHeader
            titulo={"Aspectos de Identidade"}
            instrucao={this.state.instrumento.enunciado}
            idInstrumento={assincronoInstrumentosIDS.AspectosDeIdentidade}
          />
          {this.state.dados.map(dados => (
            <div key={dados._id}>
              {dados.data.map((dados, index) => (
                <div key={index}>
                  {dados.alternativas.map((dados, index) => (
                    this.graphicRefs.push(createRef()),
                    <>
                      <div ref={this.graphicRefs[index]} key={index} className="margin-top">
                      <div className="sel">
                        <p>
                          {" "}
                          <b>
                            {index + 1} - {dados}
                          </b>
                        </p>
                        <Row className="without-margin row-centered remove-margins question align-center" style={{ flexWrap: 'nowrap' }}>
                          <div><label htmlFor={`${index}-${1}`}>Não é importante para meu senso de quem sou eu</label></div>
                            {[1, 2, 3, 4, 5].map(i => (
                              <RadioWrapper>
                                <NewCustomRadio
                                  value={String(i)}
                                  checked={respostas[index] === String(i)}
                                  onChange={e => this.handleRespostas(e, index)}
                                  id={`${index}-${i}`}
                                  label={i}
                                />
                              </RadioWrapper>
                            ))}
                          <div style={{textAlign: 'right'}}><label htmlFor={`${index}-${5}`}>Extremamente importante para meu senso de quem sou eu</label></div>
                        </Row>
                      </div>
                      </div>
                      <hr style={{marginTop: 30}} />
                    </>
                  ))}
                </div>
              ))}
            </div>
          ))}
          <ActivityActions
            back={() => this.voltar()}
            save={this.saveAnswer}
            send={this.enviar}
            nextSend={() => scrollToUnfinishedQuestion(this.graphicRefs, this.state.dados[0].data[0].alternativas, respostas)}
          >

          </ActivityActions>
          </InstrumentContainer>
        </div>
      </>
    );
  }
}

export default withSubscription(AspectosIdentidades);
