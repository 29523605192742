import React, { Component } from "react";

import { ItemInstrumento, ItemActions, Action } from "../styles";
import { DetalhesDoInstrumento } from "../styles";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { CircularButton } from "../../../../Assets/Assets";
import { FaEye } from "react-icons/fa";
import { capitalizeFirstLetter } from "../../../../../utils/masks";
import { Tooltip } from "react-tippy";

const ProvaIcon = () => (
  <div>
    <i class="fa fa-file-text" aria-hidden="true" style={{
      fontSize: '30px',
      width: '40px',
      textAlign: 'center'
    }}></i>
  </div>
)

export default class ItemInstrumentoProva extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ItemInstrumento>
        <ProvaIcon />
        <DetalhesDoInstrumento sm="8">
          <Row>
            <Col>
              <strong>{capitalizeFirstLetter(this.props.nome)}</strong>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>Data: {this.props.data}</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>Projeto: {this.props.projectName}</span>
            </Col>
          </Row>
        </DetalhesDoInstrumento>
        {this.props.allowShowResults && (
            <ItemActions sm="3">
              <Action>
                <CircularButton
                  onClick={() =>
                    this.props.showResultsProof(
                      this.props.id,
                      this.props.projectId
                    )
                  }
                  id={`finalizado--results-${this.props.id}`}
                >
                  <Tooltip
                    title="Ver resultados"
                    size="small"
                    position="top"
                    arrow
                  >
                    <FaEye size={20} />
                  </Tooltip>
                </CircularButton>
              </Action>
            </ItemActions>
          )}
      </ItemInstrumento>
    );
  }
}
