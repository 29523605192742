import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Auth from './auth';

export default function withAuth(ComponentToProtect) {
  return class extends Component {
    componentWillMount() {
      this.setState({
        confirm: false,
        loaded: false,
      });
    }

    componentDidMount() {
      Auth.loggedIn().then(loggedIn => {
        console.log(`Logged in: ${loggedIn}`);
        if (!loggedIn) {
          this.setState({
            loaded: true,
          });
        } else {
          try {
            this.setState({
              confirm: true,
              loaded: true,
            });
          } catch (err) {
            /* Oh snap! Looks like there's an error so we'll print it out and log the user out for security reasons. */
            console.log(err);
            Auth.logout();
            this.setState({
              loaded: true,
            });
          }
        }
      });
    }

    render() {
      const { loaded, confirm } = this.state;
      if (loaded) {
        if (confirm) {
          console.log('confirmed!');
          return (
            /* component that is currently being wrapper(App.js) */
            <ComponentToProtect {...this.props} />
          );
        }
        console.log('not confirmed!');
        return <Redirect to='/' />;
      }
      return null;
    }
  };
}
