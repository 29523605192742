import React, { Component } from "react";
import PropTypes from "prop-types";
import { FaEye, FaPlay, FaUserFriends } from "react-icons/fa";
import { Tooltip } from "react-tippy";

import ItemInstrumentoDashboard from "../ItemInstrumentoDashboard";
import { ItemInstrumento, ItemActions, Action } from "../styles";
import { CircularButton } from "../../../../Assets/Assets";

export default class FinishedTextInstrument extends Component {
  static propTypes = {
    indice: PropTypes.number,
    instrumento: PropTypes.object.isRequired,
    userCanSeeResults: PropTypes.bool.isRequired,
    dateExpired: PropTypes.bool,
    showResults: PropTypes.func,
    play: PropTypes.func,
    openRespondentModal: PropTypes.func,
  };

  static defaultProps = {
    userCanSeeResults: false,
    dateExpired: false
  };

  constructor(props) {
    super(props);
  }

  handleOpenIndicationModal = () => {
    const {
      openRespondentModal,
      instrumento: { _id, idGrupo, projectId },
    } = this.props;
    openRespondentModal(idGrupo, _id, projectId);
  };

  render() {
    const { instrumento, notRespondente } = this.props;
    return (
      <ItemInstrumento key={this.props.indice}>
        <ItemInstrumentoDashboard instrumento={instrumento} />

        <ItemActions sm="3">
          {instrumento.userCanSelectWhoWillAnswer && notRespondente && (
            <Action>
              <>
                <CircularButton
                  onClick={this.handleOpenIndicationModal}
                >
                  <Tooltip
                    title="Cadastrar Respondentes"
                    size="small"
                    position="top"
                    arrow
                  >
                    <FaUserFriends size={20} />
                  </Tooltip>
                </CircularButton>
              </>
            </Action>
          )}

          {this.props.dateExpired ? (
            <>
              {this.props.userCanSeeResults && (
                <Action>
                  <CircularButton onClick={this.props.showResults}>
                    <Tooltip
                      title="Ver resultados"
                      size="small"
                      position="top"
                      arrow
                    >
                      <FaEye size={20} />
                    </Tooltip>
                  </CircularButton>
                </Action>
              )}
            </>
          ) : (
            <Action>
              <CircularButton
                onClick={this.props.play}
              >
                <Tooltip
                  title="Acessar atividade"
                  size="small"
                  position="top"
                  arrow
                >
                  <FaPlay />
                </Tooltip>
              </CircularButton>
            </Action>
          )}
        </ItemActions>
      </ItemInstrumento>
    );
  }
}
