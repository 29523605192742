import React, { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './css/Question.css';

export const Question = function({ number, enunciate, ansOnChange, editorInitialState }) {
  const [ editorState, setEditorState ] = useState(editorInitialState)

  /**
   * componentDidMount do question de
   */
  useEffect(() => {
    ansOnChange(convertToRaw(editorInitialState.getCurrentContent()))
    return () => {}
  }, [])

  return (
    <Col sm="12" style={{  }}>
      <Row>
        <Col sm="12">
          { number && <p>{number}.     {enunciate}</p>}
          { !number && <p>{enunciate}</p>}
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <Editor
            spellCheck={true}
            stripPastedStyles={true}
            defaultEditorState={editorState}
            onChange={newEditorState => {
              setEditorState(newEditorState);
              ansOnChange(newEditorState) }}
            wrapperClassName='wrapper-historia-de-vida'
            editorClassName='editor-historia-de-vida'
            toolbar={{
              options: ["inline"],
              inline: {
                inDropdown: false,
                options: ["bold", "italic", "underline"],
              },
            }}/>
        </Col>
      </Row>
    </Col>
  )
}

Question.propTypes = {
  number: PropTypes.string,
  enunciate: PropTypes.string.isRequired,
  editorInitialState: PropTypes.object,
  ansOnChange: PropTypes.func.isRequired
}

