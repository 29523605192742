import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ButtonCancel } from "../../views/Assets/Assets";

function InstructionsModal(props) {
  return(
    <Modal
      isOpen={props.isOpen}
      toggle={() => props.toggle()}
      size='xl'
    >
      <ModalHeader toggle={() => props.toggle()}>
        Política de Privacidade
      </ModalHeader>
      <ModalBody>
        <p style={{ textAlign: 'center' }}><strong>POL&Iacute;TICA DE PRIVACIDADE DA</strong> <strong>FORMARE TECH</strong></p><br />
        <p>&Uacute;ltima Atualiza&ccedil;&atilde;o: 07 de mar&ccedil;o de 2022</p><br />
        <p>Bem-vindo(a) ao IAPP! N&oacute;s somos a <strong>FORMARE ASSOCIADOS CURSOS E TREINAMENTOS LTDA</strong>, sociedade inscrita no CNPJ/ME sob o n&ordm; 17.909.377/0001-60, com sede na Av Manoel dos Reis Araujo, 1081, CEP 04664-000, Cidade e Estado de S&atilde;o Paulo, doravante simplesmente &ldquo;<u><strong>Formare Tech</strong></u>&rdquo; ou &ldquo;<u><strong>N&oacute;s</strong></u>&rdquo;, e somos <strong>operadores</strong> do seus dados pessoais, conforme definido pela legisla&ccedil;&atilde;o cab&iacute;vel.</p>
        <p>Caso tenha d&uacute;vidas ou precise tratar de qualquer assunto relacionado a esta Pol&iacute;tica, entre em contato conosco atrav&eacute;s do e-mail <u><em>privacidade@formaretech.com.br</em></u><em>. </em></p>
        <p>Paralelamente, recomendamos que tamb&eacute;m entre em contato com a empresa que diretamente contratou nossa Plataforma, tal como seu empregador ou uma consultoria em recursos humanos contratada por ele, os quais, na posi&ccedil;&atilde;o de <strong>controladores</strong> dos seus dados pessoais, poder&atilde;o lhe fornecer maiores detalhes quanto ao processamento de suas informa&ccedil;&otilde;es.</p>
        <p><strong>Como somos operadores dos seus dados pessoais, n&oacute;s somente os processamos a partir das instru&ccedil;&otilde;es espec&iacute;ficas destes controladores, e somente para as finalidades descritas nesta Pol&iacute;tica &ndash; ou seja, para o funcionamento de nossa Plataforma. </strong></p>
        <p>Voc&ecirc;, titular de dados pessoais, ser&aacute; referido como &ldquo;<u><strong>Voc&ecirc;</strong></u>&rdquo; em nossa Pol&iacute;tica.</p>
        <p>A presente Pol&iacute;tica est&aacute; dividida da seguinte forma para facilitar a sua compreens&atilde;o:</p>
        <ol>
        <li value="1">
        <p>INFORMA&Ccedil;&Otilde;ES GERAIS</p>
        </li>
        <li>
        <p>SEU USO DA PLATAFORMA</p>
        </li>
        <li>
        <p>INFORMA&Ccedil;&Otilde;ES COLETADAS</p>
        </li>
        <li>
        <p>USO E COMPARTILHAMENTO DAS SUAS INFORMA&Ccedil;&Otilde;ES</p>
        </li>
        <li>
        <p>OUTROS USOS AUTORIZADOS</p>
        </li>
        <li>
        <p>DIREITOS DOS TITULARES</p>
        </li>
        <li>
        <p>SEGURAN&Ccedil;A DA INFORMA&Ccedil;&Atilde;O</p>
        </li>
        <li>
        <p>ATUALIZA&Ccedil;&Otilde;ES DA POL&Iacute;TICA DE PRIVACIDADE</p>
        </li>
        <li>
        <p>LEI APLIC&Aacute;VEL</p>
        </li>
        </ol>
        <p><strong>1. INFORMA&Ccedil;&Otilde;ES GERAIS</strong></p>
        <p>Os seus dados pessoais, ou seja, informa&ccedil;&otilde;es que te identificam ou podem te identificar, conforme definidos pela legisla&ccedil;&atilde;o cab&iacute;vel, em especial a Lei Geral de Prote&ccedil;&atilde;o de Dados (LGPD), poder&atilde;o ser coletados e processados pela nossa plataforma digital. Nossa plataforma &eacute; voltada, principalmente, a consultores de recursos humanos e departamentos empresariais de gest&atilde;o de pessoas, e se utiliza da metodologia de gest&atilde;o &ldquo;Instrumentos de <em>Assessment</em> de Prontid&atilde;o e Potencial&rdquo; ou &ldquo;IAPP&rdquo; e de desenvolvimento profissional por meio de cursos no Ambiente Online de Desenvolvimento Profissional ou &ldquo;AODP&rdquo;, sendo que tanto a plataforma quanto a metodologia que a embasa foram desenvolvidas e criadas diretamente por n&oacute;s (em conjunto, a &ldquo;<u><strong>Plataforma</strong></u>&rdquo;).</p>
        <p>Essa Pol&iacute;tica de Privacidade (a &ldquo;<u><strong>Pol&iacute;tica</strong></u>&rdquo;) tem como <strong>objetivo lhe informar de maneira clara e transparente sobre</strong> o processamento dos seus dados pessoais, coletados quando Voc&ecirc; interage com a nossa Plataforma como Consultor ou Participante nas atividades da nossa Plataforma, conforme descrito em maiores detalhes abaixo.</p>
        <p><strong>Seu acesso ou uso da Plataforma indicar&aacute; que Voc&ecirc; est&aacute; ciente com a forma como utilizaremos os seus dados pessoais. Voc&ecirc; declara e garante que todas as informa&ccedil;&otilde;es pessoais suas que nos fornecer s&atilde;o verdadeiras, atuais e completas.</strong></p>
        <br /><p><strong>2. SEU USO DA PLATAFORMA</strong></p>
        <p>Voc&ecirc; poder&aacute; interagir com a Plataforma na posi&ccedil;&atilde;o de Consultor ou de Participante.</p>
        <ul>
        <li>
        <p><u><strong>Consultor.</strong></u> Caso Voc&ecirc; seja um consultor, Voc&ecirc; poder&aacute; gerar atividades em que os Participantes possam participar, nas quais eles fornecer&atilde;o os dados pessoais descritos nessa Pol&iacute;tica, assim como outras informa&ccedil;&otilde;es n&atilde;o pessoais (como informa&ccedil;&otilde;es do seu empregador) ou que eles desejem compartilhar. Voc&ecirc; tamb&eacute;m poder&aacute; escolher quais atividades realizar e quais Participantes convidar para participar nelas, assim como gerir as informa&ccedil;&otilde;es fornecidas pelos Participantes, gerar relat&oacute;rios para seu empregador, fornecer <em>feedback </em>para os Participantes, entre outros. (Doravante, pessoas nessa posi&ccedil;&atilde;o ser&atilde;o denominados os &ldquo;<u><strong>Consultores</strong></u>&rdquo;.)</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Sua responsabilidade como Consultor.</strong></u> Voc&ecirc;, na posi&ccedil;&atilde;o de Consultor, reconhece sua responsabilidade pelos dados pessoais a que tiver acesso dos Participantes, comprometendo-se a utiliz&aacute;-los t&atilde;o somente para fins de gest&atilde;o de pessoas no &acirc;mbito do seu empregador, em compasso com as regras &eacute;ticas profissionais, a legisla&ccedil;&atilde;o cab&iacute;vel, essa Pol&iacute;tica e as normativas internas de seu empregador. Assim, Voc&ecirc; se compromete a n&atilde;o processar quaisquer dados pessoais acessados por meio da Plataforma para quaisquer finalidades distintas das previstas em tais normativas, tal como para finalidades pessoais suas ou de terceiros, ou para finalidades discriminat&oacute;rias. <strong>Voc&ecirc; se responsabiliza integralmente perante a Formare Tech, inclusive mediante regresso, pelo uso dos dados pessoais que Voc&ecirc; puder acessar por meio da Plataforma.</strong></p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Participante.</strong></u> Caso Voc&ecirc; seja um Participante, Voc&ecirc; poder&aacute; interagir com atividades criadas para Voc&ecirc; pelos Consultores e nelas fornecer os dados pessoais previstos nesta Pol&iacute;tica, assim como acessar as informa&ccedil;&otilde;es relativas &agrave;s atividades em que Voc&ecirc; pr&oacute;prio participou. (Doravante, pessoas nessa posi&ccedil;&atilde;o ser&atilde;o denominados os &ldquo;<u><strong>Participantes</strong></u>&rdquo;.)</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Respondente.</strong></u> Voc&ecirc; ser&aacute; um &ldquo;<u><strong>Respondente</strong></u>&rdquo; caso acesse um link para um &uacute;nico ambiente restrito na Plataforma, voltado para sua participa&ccedil;&atilde;o em uma atividade limitada, tal como um question&aacute;rio sobre outro Participante. Tais atividades somente ser&atilde;o acess&iacute;veis por meio de link gerado por um Consultor ou outros Participantes. Para os fins desta Pol&iacute;tica, Respondentes tamb&eacute;m s&atilde;o considerados Participantes, e todas as situa&ccedil;&otilde;es que se referem abaixo aos Participantes tamb&eacute;m se aplicam aos Respondentes no que forem cab&iacute;veis, exceto se expressamente descrito de outra forma.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Dados de terceiros.</strong></u> Ao utilizar a Plataforma e participar de uma atividade, Voc&ecirc; poder&aacute; nos fornecer informa&ccedil;&otilde;es de terceiros, tal como colegas seus. Nessas situa&ccedil;&otilde;es, caso Voc&ecirc; tenha conhecimento de qualquer raz&atilde;o pela qual seus colegas ou terceiros mencionados possam, mesmo que potencialmente, n&atilde;o querer de maneira leg&iacute;tima que suas informa&ccedil;&otilde;es sejam compartilhadas por meio da Plataforma, Voc&ecirc; se compromete a n&atilde;o compartilhar tais informa&ccedil;&otilde;es, justificando-se para tal perante o controlador de dados e/ou seu empregador. <strong>Voc&ecirc; &eacute; exclusiva e integralmente respons&aacute;vel pelo conte&uacute;do que adicionar &agrave; Plataforma durante sua participa&ccedil;&atilde;o em nossas atividades, em especial conte&uacute;dos relativos a colegas seus ou quaisquer outros terceiros, eximindo a Formare Tech, inclusive mediante regresso, de qualquer responsabilidade nesse sentido.</strong></p>
        </li>
        </ul>
        <p><strong>3. INFORMA&Ccedil;&Otilde;ES COLETADAS</strong></p>
        <p>N&oacute;s coletamos os seguintes tipos de informa&ccedil;&otilde;es sobre Voc&ecirc;:</p>
        <p>&nbsp;</p>
        <ul>
        <li>
        <p><u><strong>Dados de cadastro do Consultor</strong></u>. Quando Voc&ecirc;, Consultor, obteve sua certifica&ccedil;&atilde;o com a Formare Tech, Voc&ecirc; nos forneceu informa&ccedil;&otilde;es pessoais suas como seu nome, e-mail e CPF. A Formare Tech utilizar&aacute; seu e-mail e CPF para gerar um cadastro pr&eacute;vio para Voc&ecirc; na Plataforma, o qual Voc&ecirc; poder&aacute; acessar e personalizar por meio da pr&oacute;pria Plataforma, a partir de link compartilhado com Voc&ecirc; pela Formare Tech. Durante seu cadastro, Voc&ecirc; dever&aacute; nos fornecer as informa&ccedil;&otilde;es ali solicitadas, tal como seu nome, telefone, e foto pessoal, assim como informa&ccedil;&otilde;es n&atilde;o pessoais, como a empresa em que Voc&ecirc; trabalha, logo dessa empresa, entre outros.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Dados de cadastro do Participante</strong></u>. Os Consultores, como pessoas que t&ecirc;m acesso e permiss&atilde;o de uso leg&iacute;timos a seus dados pessoais em vista de seus cargos diretivos ou de gest&atilde;o de pessoas no controlador de dados, poder&atilde;o criar um cadastro pr&eacute;vio para Voc&ecirc; na Plataforma, utilizando-se para isso seu nome, e-mail, CPF e cargo. Tal cadastro poder&aacute; ser acessado e personalizado por Voc&ecirc; por meio da pr&oacute;pria Plataforma, a partir de link compartilhado pelo Consultor. Durante seu cadastro, Voc&ecirc; poder&aacute; nos fornecer novos dados pessoais seus especificamente solicitados neste ambiente, como seu sal&aacute;rio, telefone e data de nascimento. Caso Voc&ecirc; seja um Respondente, Voc&ecirc; n&atilde;o ter&aacute; um cadastro na Plataforma, fornecendo-nos somente, ao participar de uma atividade restrita conforme descrito acima, seu nome, e-mail e a sua rela&ccedil;&atilde;o com o Participante em quest&atilde;o.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Dados de sua participa&ccedil;&atilde;o</strong></u><strong>.</strong> Quando Voc&ecirc; participar de alguma atividade de nossa Plataforma, Voc&ecirc; poder&aacute; escolher nos fornecer diversos dados pessoais seus, por exemplo ao responder perguntas sobre seu emprego ou suas compet&ecirc;ncias, ao completar campos abertos de texto etc. Todos os dados pessoais que Voc&ecirc; escolher nos fornecer ser&atilde;o tratados nos termos desta Pol&iacute;tica.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Outros dados</strong></u>. Na Plataforma, poderemos pedir de Voc&ecirc; outros dados, tal como seu nome completo, Tempo de Empresa, CPF, E-mail, Cargo, Sal&aacute;rio mensal, Telefone/Celular, data de nascimento, idade, g&ecirc;nero, escolaridade, &aacute;rea de atua&ccedil;&atilde;o, forma&ccedil;&atilde;o, pa&iacute;s de atua&ccedil;&atilde;o e estado de atua&ccedil;&atilde;o, para as mesmas finalidades descritas nesta Pol&iacute;tica.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Registros de acesso</strong></u>. A Formare Tech coleta automaticamente registros de acesso a aplica&ccedil;&atilde;o, que incluem o endere&ccedil;o IP, com data e hora, utilizado para acessar a Plataforma. Esses dados s&atilde;o de coleta obrigat&oacute;ria, de acordo com a Lei 12.965/2014, mas somente ser&atilde;o fornecidos para outros terceiros n&atilde;o previstos nesta Pol&iacute;tica com a sua autoriza&ccedil;&atilde;o expressa ou por meio de demanda judicial.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Dados de uso</strong></u>. N&oacute;s coletamos informa&ccedil;&otilde;es sobre suas intera&ccedil;&otilde;es na Formare Tech, como sua navega&ccedil;&atilde;o, as p&aacute;ginas ou outro conte&uacute;do que Voc&ecirc; acessa ou cria, suas buscas, participa&ccedil;&otilde;es em pesquisas ou f&oacute;runs e outras a&ccedil;&otilde;es.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Comunica&ccedil;&otilde;es com a Formare Tech</strong></u>. Quando Voc&ecirc; se comunica com a Formare Tech, coletamos informa&ccedil;&otilde;es sobre sua comunica&ccedil;&atilde;o, incluindo metadados como data, IP e hora das comunica&ccedil;&otilde;es e todo o seu conte&uacute;do, assim como qualquer informa&ccedil;&atilde;o que Voc&ecirc; escolha fornecer.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Cookies e tecnologias semelhantes</strong></u>. N&oacute;s utilizamos cookies, que s&atilde;o arquivos de texto gerados e armazenados no seu navegador ou aparelho por sites, aplicativos e an&uacute;ncios online. Os cookies poder&atilde;o ser utilizados para autenticar sua sess&atilde;o, lembrar suas prefer&ecirc;ncias e configura&ccedil;&otilde;es e entender, de forma agregada e anonimizada, os comportamentos e interesses dos usu&aacute;rios de nossa Plataforma.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Dados de Pagamento</strong></u><strong>.</strong> Quando Voc&ecirc; compra cr&eacute;ditos para utilizar a Plataforma, Voc&ecirc; nos fornecer&aacute; algumas informa&ccedil;&otilde;es banc&aacute;rias (como seu n&uacute;mero de cart&atilde;o de cr&eacute;dito e outras informa&ccedil;&otilde;es explicitamente solicitadas no momento do pagamento), que ser&atilde;o compartilhados com os meios de pagamento apontados no momento da transa&ccedil;&atilde;o para a finalidade &uacute;nica de processar seu pagamento e permitir o uso da Plataforma.</p>
        </li>
        </ul>
        <p><strong>4. USO E COMPARTILHAMENTO DAS SUAS INFORMA&Ccedil;&Otilde;ES</strong></p>
        <ul>
        <li>
        <p><u><strong>Informa&ccedil;&otilde;es confidenciais.</strong></u> Todos os seus dados pessoais s&atilde;o tratados como confidenciais pela Formare Tech, e somente os usaremos para as finalidades aqui descritas. Exceto se disposto de outra forma especificamente nessa Pol&iacute;tica, seus dados pessoais ser&atilde;o utilizados unicamente para a operacionaliza&ccedil;&atilde;o da Plataforma, ou seja, para permitir que os Consultores realizem as atividades de gest&atilde;o de pessoas oferecidas pela Plataforma e gerem relat&oacute;rios e an&aacute;lises a partir de tal participa&ccedil;&atilde;o, e que Voc&ecirc; possa nelas participar. No entanto, poderemos anonimizar os dados submetidos &agrave; Plataforma e utiliz&aacute;-los, de forma agregada, para outras finalidades razo&aacute;veis, nunca permitindo sua identifica&ccedil;&atilde;o, mesmo que posterior, de forma a descaracterizar tais informa&ccedil;&otilde;es como &ldquo;pessoais&rdquo;.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Servidores</strong></u>. Todos os dados que Voc&ecirc; nos fornecer s&atilde;o armazenados em servidores externos &agrave; sua empresa e &agrave; Formare Tech, na plataforma Amazon Web Services, fisicamente localizada nos Estados Unidos. Recomendamos que entre em contato com a <u>Pol&iacute;tica de Privacidade deles</u> caso deseje se informar sobre os usos que fazem de seus dados pessoais.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Nenhum compartilhamento.</strong></u> Exceto se disposto de outra maneira nessa Politica, nenhum dado pessoal seu ser&aacute; compartilhado pela Formare Tech com quaisquer terceiros, como parceiros comerciais ou outras empresas.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Acesso a dados pelos Consultores.</strong></u> Os Consultores que convidaram Voc&ecirc; para determinada atividade ter&atilde;o acesso a todos os seus dados pessoais descritos nessa Pol&iacute;tica, contanto que relacionados &agrave; atividade para a qual ele te convidou. Seus dados pessoais, assim como an&aacute;lises das suas respostas &agrave;s atividades em que Voc&ecirc; participou, ser&atilde;o compartilhados pelos Consultores com os controladores de seus dados pessoais, como seu empregador ou a consultoria de recursos humanos que contratou a Formare Tech. Consultores n&atilde;o podem dar acesso aos dados pessoais dos Participantes para nenhum profissional externo, exceto para outros Consultores assistentes apontados por eles.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Acesso ao seu nome pelos Respondentes.</strong></u> Respondentes poder&atilde;o ter acesso ao nome completo de Participantes caso sejam convidados para responder atividades relacionadas a tais Participantes.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Requisi&ccedil;&atilde;o judicial</strong></u><strong>. </strong>A Formare Tech se reserva no direito de fornecer dados e informa&ccedil;&otilde;es sobre caso seja requisitada judicialmente para tanto, exclusivamente na medida em que tal compartilhamento for obrigat&oacute;rio por lei.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Dados de uso</strong></u>. Todos os dados que Voc&ecirc; gerar durante seu uso da Plataforma, tal como frequ&ecirc;ncia de acesso, atividades realizadas, relat&oacute;rios gerados ou acessados e seus conte&uacute;dos, e informa&ccedil;&otilde;es similares referentes ao uso da Plataforma, poder&atilde;o ser coletados pela Formare Tech.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Dados anonimizados</strong></u>. Poderemos anonimizar seus dados, ou seja, excluir toda e qualquer possibilidade de eles o identificarem, tal como por meio da utiliza&ccedil;&atilde;o de dados volum&eacute;tricos ou estat&iacute;sticos, para outras finalidades leg&iacute;timas.</p>
        </li>
        </ul>
        <p><strong>5. OUTROS USOS AUTORIZADOS</strong></p>
        <p>Tamb&eacute;m poderemos utilizar seus dados para:</p>
        <ul>
        <li>
        <p>Permitir que Voc&ecirc; acesse e utilize todas as funcionalidades da Formare Tech, inclusive utilizando-se de seu n&uacute;mero de CPF para autentica&ccedil;&atilde;o da sua participa&ccedil;&atilde;o em uma de nossas atividades, por exemplo por meio da associa&ccedil;&atilde;o de seu CPF com um c&oacute;digo &uacute;nico gerado pelos Consultores;</p>
        </li>
        </ul>
        <ul>
        <li>
        <p>Enviar a Voc&ecirc; mensagens a respeito de suporte ou servi&ccedil;o, como alertas, notifica&ccedil;&otilde;es e atualiza&ccedil;&otilde;es;</p>
        </li>
        </ul>
        <ul>
        <li>
        <p>Analisar o tr&aacute;fego dos usu&aacute;rios em nossas aplica&ccedil;&otilde;es;</p>
        </li>
        </ul>
        <ul>
        <li>
        <p>Detec&ccedil;&atilde;o e preven&ccedil;&atilde;o de fraudes, <em>spam</em> e incidentes de seguran&ccedil;a;</p>
        </li>
        </ul>
        <ul>
        <li>
        <p>Cumprir obriga&ccedil;&otilde;es legais; e</p>
        </li>
        </ul>
        <ul>
        <li>
        <p>Monitorar a Plataforma, principalmente para assegurar que as regras descritas nessa Pol&iacute;tica est&atilde;o sendo observadas, ou ainda se n&atilde;o h&aacute; viola&ccedil;&atilde;o ou abuso das leis aplic&aacute;veis.</p>
        </li>
        </ul>
        <p><strong>6. DIREITOS DOS TITULARES</strong></p>
        <p>Voc&ecirc; tem, por lei, diversos direitos relativos &agrave; privacidade e &agrave; prote&ccedil;&atilde;o dos seus dados pessoais. Tais direitos encontram-se resumidos abaixo. <strong>Caso queira exercer qualquer um destes direitos, por favor envie um e-mail para </strong><u><strong>privacidade@formaretech.com.br</strong></u><strong>, assim como para o controlador de dados, e cumpriremos com sua solicita&ccedil;&atilde;o o mais r&aacute;pido poss&iacute;vel, com a colabora&ccedil;&atilde;o do controlador de dados. Caso Voc&ecirc; entre em contato conosco para exercer algum desses direitos, teremos de acessar seus dados pessoais correspondentes para poder cumprir com suas solicita&ccedil;&otilde;es.</strong></p>
        <ul>
        <li>
        <p><u><strong>Direito de acesso</strong></u>. Este direito permite que Voc&ecirc; possa requisitar e receber uma c&oacute;pia dos dados pessoais que possu&iacute;mos sobre Voc&ecirc;.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Direito de retifica&ccedil;&atilde;o</strong></u>. Este direito permite que Voc&ecirc;, a qualquer momento, possa solicitar a corre&ccedil;&atilde;o e/ou retifica&ccedil;&atilde;o dos seus dados pessoais, caso identifique que alguns deles est&atilde;o incorretos. Contudo, para ser efetivada essa corre&ccedil;&atilde;o, teremos que checar a veracidade dos dados que Voc&ecirc; nos fornecer.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Direito de exclus&atilde;o</strong></u>. Este direito permite que Voc&ecirc; possa nos solicitar a exclus&atilde;o dos seus dados pessoais. Todos os dados coletados ser&atilde;o exclu&iacute;dos de nossos servidores quando Voc&ecirc; assim requisitar, salvo se houver qualquer outra raz&atilde;o para a sua manuten&ccedil;&atilde;o, como eventual obriga&ccedil;&atilde;o legal de reten&ccedil;&atilde;o de dados.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Direito de oposi&ccedil;&atilde;o ao processamento</strong></u>. Voc&ecirc; tamb&eacute;m tem o direito de contestar onde e em que contexto estamos tratando seus dados pessoais para diferentes finalidades. Em determinadas situa&ccedil;&otilde;es, podemos demonstrar que temos motivos leg&iacute;timos para tratar seus dados, os quais se sobrep&otilde;em aos seus direitos, caso, por exemplo, o controlador de dados tenha base legal leg&iacute;tima para reten&ccedil;&atilde;o dos seus dados e compartilhamento deles conosco.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Direito</strong></u> <u><strong>de solicitar anonimiza&ccedil;&atilde;o, bloqueio ou elimina&ccedil;&atilde;o</strong></u><strong>.</strong> Este direito permite que Voc&ecirc; nos pe&ccedil;a para suspender o processamento de seus dados pessoais nos seguintes cen&aacute;rios: (a) se Voc&ecirc; quiser que n&oacute;s estabele&ccedil;amos a precis&atilde;o dos dados; (b) quando Voc&ecirc; precisar que sejam mantidos os dados mesmo se n&atilde;o precisarmos mais deles, conforme necess&aacute;rio, para estabelecer, exercer ou defender reivindica&ccedil;&otilde;es legais; ou (c) se Voc&ecirc; se op&ocirc;s ao uso de seus dados, mas nesta hip&oacute;tese precisamos verificar se temos motivos leg&iacute;timos para us&aacute;-los.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Direito &agrave; portabilidade</strong></u>. Forneceremos a Voc&ecirc;, ou a terceiros que Voc&ecirc; escolheu, seus dados pessoais em formato estruturado e interoper&aacute;vel.</p>
        </li>
        </ul>
        <ul>
        <li>
        <p><u><strong>Direito &agrave; revis&atilde;o de decis&otilde;es automatizadas</strong></u>. Voc&ecirc; tamb&eacute;m tem o direito de solicitar a revis&atilde;o de decis&otilde;es tomadas unicamente com base em tratamento automatizado de seus dados pessoais que afetem seus interesses, inclu&iacute;das as decis&otilde;es destinadas &agrave; defini&ccedil;&atilde;o de perfis pessoais, profissionais, de consumo e de cr&eacute;dito e/ou os aspectos de sua personalidade.</p>
        </li>
        </ul>
        <p>Talvez seja necess&aacute;rio solicitar informa&ccedil;&otilde;es espec&iacute;ficas suas para nos ajudar a confirmar sua identidade e garantir seus direitos acima. Esta &eacute; uma medida de seguran&ccedil;a para garantir que os dados pessoais n&atilde;o sejam divulgados a qualquer pessoa que n&atilde;o tenha direito de receb&ecirc;-los.</p>
        <br /><p><strong>7. SEGURAN&Ccedil;A DA INFORMA&Ccedil;&Atilde;O</strong></p>
        <p>Todos os seus dados s&atilde;o confidenciais e somente as pessoas com as devidas autoriza&ccedil;&otilde;es e necessidade ter&atilde;o acesso a eles, e somente mediante autoriza&ccedil;&atilde;o expressa e por tempo limitado para tal acesso. Qualquer uso dos seus dados pessoais estar&aacute; de acordo com a presente Pol&iacute;tica. A Formare Tech empreender&aacute; todos os esfor&ccedil;os razo&aacute;veis de mercado para garantir a seguran&ccedil;a dos nossos sistemas e dos seus dados. O acesso aos servidores onde est&atilde;o seus dados somente pode ser feito por meio de canais de comunica&ccedil;&atilde;o previamente autorizados. Todas as suas informa&ccedil;&otilde;es s&atilde;o criptografadas nos servidores em que se encontram, assim como seu tr&aacute;fego entre os servidores e nossos computadores.</p>
        <p>Especificamente, o tr&aacute;fego de suas senhas, token de acesso e o seu acesso s&atilde;o protegidos via SSL com certificado emitido pela Amazon Web Services, suas senhas s&atilde;o criptografadas em md5, associadas a um token &uacute;nico associado a Voc&ecirc;, e a Plataforma &eacute; protegida com todos os recursos de seguran&ccedil;a fornecidos pela Amazon Web Services, como firewall e certificados de seguran&ccedil;a.</p>
        <p>Manteremos seus dados de acesso a nosso site (ou seja, IP, data e hora de seu acesso) em nossos servidores por 6 (seis) meses.</p>
        <p>Sem preju&iacute;zo desses prazos, manteremos seus dados e informa&ccedil;&otilde;es somente at&eacute; quando estas forem necess&aacute;rias ou relevantes para as finalidades descritas nesta Pol&iacute;tica, ou em caso de per&iacute;odos pr&eacute;-determinados por lei.</p>
        <p>A Formare Tech considera a sua privacidade algo extremamente importante e far&aacute; tudo que estiver ao alcance para proteg&ecirc;-la. Todavia, n&atilde;o temos como garantir que todos os dados e informa&ccedil;&otilde;es sobre Voc&ecirc; em nossa plataforma estar&atilde;o livres de acessos n&atilde;o autorizados, principalmente caso haja compartilhamento indevido das credenciais necess&aacute;rias para acessar a Plataforma. <strong>Portanto, Voc&ecirc; &eacute; o &uacute;nico respons&aacute;vel por manter sua senha de acesso e links recebidos para acesso a atividades da Plataforma em local seguro, sendo vedado o compartilhamento destas informa&ccedil;&otilde;es com terceiros. Voc&ecirc; se compromete a notificar a Formare Tech e o controlador de dados imediatamente, atrav&eacute;s de meio seguro, a respeito de qualquer uso n&atilde;o autorizado de sua conta, bem como o acesso n&atilde;o autorizado por terceiros a esta.</strong></p>
        <br /><p><strong>8. ATUALIZA&Ccedil;&Otilde;ES DA POL&Iacute;TICA DE PRIVACIDADE</strong></p>
        <p>A Formare Tech se reserva no direito de alterar essa Pol&iacute;tica quantas vezes forem necess&aacute;rias, contanto que em compasso com as normativas legais e as instru&ccedil;&otilde;es dos controladores de dados. &Eacute; por isso que &eacute; muito importante acessar nossa Pol&iacute;tica periodicamente. Para facilitar, indicamos no in&iacute;cio do documento a data da &uacute;ltima atualiza&ccedil;&atilde;o.</p>
        <br /><p><strong>9. LEI APLIC&Aacute;VEL</strong></p>
        <p>Este documento &eacute; regido e deve ser interpretado de acordo com as leis da Rep&uacute;blica Federativa do Brasil. Fica eleito o Foro da Comarca de S&atilde;o Paulo, S&atilde;o Paulo, como o competente para dirimir quaisquer quest&otilde;es porventura oriundas do presente documento, com expressa ren&uacute;ncia a qualquer outro, por mais privilegiado que seja.</p>

      </ModalBody>
      <ModalFooter>
        <ButtonCancel onClick={ () => {
        props.toggle()
      } }>Fechar</ButtonCancel>
      </ModalFooter>
    </Modal>
  );
}

export default InstructionsModal;
