import api from '../../services/api';
import { regularApi } from '../../services/api';

export default async function validateEmail(email, id, history) {
  const token = await api.post('/respondente/login', { email: email ? email.toLowerCase() : '', id });
  sessionStorage.setItem('token', token.data.token);
  sessionStorage.setItem('respondenteId', id);
  api.defaults.headers.common["Authorization"] =
            "Bearer " + token.data.token;
  regularApi.defaults.headers.common["Authorization"] =
            "Bearer " + token.data.token;

  history.push('/items');
}
