import React, { useEffect, useState } from "react";
import {
  AppFooter,
} from "@coreui/react";
import styled from "styled-components";
import { useHistory } from 'react-router-dom';

const Ft = styled.footer`
  width: 100%;
  background: #f0f3f5;
`;

export default function Footer(props) {
  const history = useHistory();
  const date = new Date();
  const year = date.getFullYear();

  const [loginScreen, setLoginScreen] = useState(false);


  useEffect(()=>{
    let pathArray = history.location.pathname.split("/");

    for (const p of pathArray) {
      if (p.match(/^[0-9a-fA-F]{24}$/)) {
        pathArray = pathArray.filter((pOpt) => pOpt !== p);
      }
    }

    pathArray = pathArray.filter(p => p);

    if (!pathArray.length) setLoginScreen(true);
    else if (pathArray.length && loginScreen) setLoginScreen(false);
  },[history.location.pathname]);

  if (loginScreen) return null;

  return (
    <AppFooter>
      <Ft style={{ display: loginScreen ? 'none' : 'block' }} className="text-center white-footer">
        <footer>
          <small>
            <b>© {year} Formare Tech.</b>
            <br />
            Todos os Direitos Reservados
          </small>
        </footer>
      </Ft>
    </AppFooter>
  );
}
