import React, { Component } from "react";
import "./css/CustomRadio.css";
/**
 * O radio button padrão do IAPP
 *
 * @export
 * @class CustomRadio
 * @extends {Component}
 */
export default class CustomRadio extends Component {
  render() {
    return (
      <div className="custom-radio">
        <label className={`container-radio ${this.props.labelClasses}`}>
          {this.props.label}
          <input type="radio" {...this.props} />
          <span className="checkmark-radio"></span>
        </label>
      </div>
    );
  }
}
