import styled from "styled-components";

const TaskList = styled.div`
  transition: background-color 0.2s ease;
  flex-grow: 1;
  padding-bottom: ${(props) =>
    props.isDraggingOver && props.isAnswer ? "100px" : "0"};

  .placed {
    width: 100%;
  }
`;

export default TaskList;
