import React, { Component, createRef } from 'react';
import { Row } from 'reactstrap';
import { Prompt } from 'react-router';
import api, { regularApi } from '../../../services/api';
import { notifySuccess, notifyInfo, notifyError } from '../../../services/notificacoes';
import { CustomRadio } from '../../components/general';
import ActivityActions from './Components/ActivityActions';
import './css/general.css';
import withSubscription from "./hoc-instrumentos-assincronos";
import Messages from '../../../static/Messages';
import InstrumentContainer from '../../components/InstrumentContainer';
import NewSubHeader from '../ListInstruments/components/NewSubHeader';
import { NewCustomRadio } from '../../components/general';
import { RadioWrapper } from '../../components/general/NewCustomRadio';
import { assincronoInstrumentosIDS } from '../../../services/Constantes';
import { scrollToUnfinishedQuestion } from './utils/scrollToUnfinishedQuestion';

// // Separado em 6 GRUPOS
// // AUTONOMIA - SOMA de Respostas/Num Respostas
// /// REALIZACAO
// /// COMPETENCIA
// /// SAUDE - INVERTIDOS
// // SEGURANÇA - INVERTIDOS
// /// AJUSTAMENTO

// // RESULTADOS são médias separados por grupos

let respostas = [];

class AutoconceitoNoTrabalho extends Component {
  graphicRefs = [];

  state = {
    dados: [],
    instrumento: {
      enunciado: ''
    }
  };

  async componentDidMount() {
    await this.props.validateAccess(localStorage.getItem('assincrono'), localStorage.getItem('instrumento'));
    const response = await regularApi.get(
      'api/instruments/5c04514dacd7ba15fc075534',
    );
    const dadosDb = response.data;
    const savedAnswers = await api.get(
      `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
        'assincrono',
      )}/${localStorage.getItem('instrumento')}/${localStorage.getItem(
        'projeto',
      )}`,
    );
    const storageName = `autoconceitoEscolhas-${localStorage.getItem('assincrono')}`;
      this.props.getStorageName([storageName]);

    if (sessionStorage.getItem(storageName)) this.props.setHasChanged(true);
    respostas = sessionStorage.getItem(storageName) ?
    JSON.parse(sessionStorage.getItem(storageName)) : savedAnswers.data ? savedAnswers.data.escolhas : [];
    this.setState({ dados: [dadosDb], instrumento: { enunciado: dadosDb.data && dadosDb.data[0].enunciado } });
  }

  handleRespostas = (e, indice) => {
    // ///// INVERTIDOS SAUDE E SEGURANCA

    const i = indice + 1;

    respostas[i] = e.target.value;
    this.props.setHasChanged(true);
    sessionStorage.setItem(`autoconceitoEscolhas-${localStorage.getItem('assincrono')}`, JSON.stringify(respostas));
    this.setState({});
  };

  enviar = async e => {
    try {
      e.preventDefault();
      let sum1 = 0;
      let sum2 = 0;
      let sum3 = 0;
      let sum4 = 0;
      let sum5 = 0;
      let sum6 = 0;
    const feed1 =
      '	Autonomia<br/>Diz respeito à sua percepção sobre sua capacidade de resolver problemas complexos, sem necessidade de ajuda, que surgem durante a execução de suas tarefas, bem como sua capacidade de tomada de decisõe';
    const feed2 =
      ' Realização<br/>Diz respeito à sua percepção em relação à consciência de si mesmo no que diz respeito à suas aspirações e ideais realizados através do trabalho que executa, bem como da percepção de seu papel e de seu status na organização na qual atua';
    const feed3 =
      'Competência<br/>Diz respeito à sua percepção valorativa sobre si mesmo em relação à sua atuação no trabalho, bem como os sentimentos de dominação e possessividade que derivam desta sua percepção';
    const feed4 =
      'Saúde<br/>Dizem respeito à sua percepção sobre como o trabalho ou os fatos que o envolvem podem afetar sua saúde';
    const feed5 =
      'Segurança<br/>Diz respeito à sua percepção em relação às suas dúvidas e hesitações diante de situações novas.';
    const feed6 =
      'Ajustamento<br/>Diz respeito à maneira que você se percebe enquanto adaptado às regras e normas da organização na qual trabalha, bem como às regras e normas de conduta social e, a como se sente adaptado ao trabalho que realiza';
    const reversed = [1, 3, 17, 21, 24, 37, 38, 39, 5, 6, 23, 30];

    respostas.map((valor, i) => {
      if (reversed.includes(i)) {
        valor = 6 - Number(valor);
      }
      if (
        i == 14 ||
        i == 43 ||
        i == 40 ||
        i == 35 ||
        i == 36 ||
        i == 31 ||
        i == 9 ||
        i == 27
      ) {
        sum1 += Number(valor);
        // console.log("sum1", sum1/2, feed1)
      }
      if (i == 4 || i == 13 || i == 16 || i == 18 || i == 19 || i == 28) {
        sum2 += Number(valor);
        // console.log("sum2", sum2/2, feed2)
      }
      if (
        i == 2 ||
        i == 7 ||
        i == 20 ||
        i == 25 ||
        i == 29 ||
        i == 32 ||
        i == 33 ||
        i == 34
      ) {
        sum3 += Number(valor);
        //   console.log("sum3", sum3/2, feed3)
      }
      if (
        i == 1 ||
        i == 3 ||
        i == 17 ||
        i == 21 ||
        i == 24 ||
        i == 37 ||
        i == 38 ||
        i == 39
      ) {
        sum4 += Number(valor);
        // if(valor==1){valor = 5; sum4+= Number(valor); console.log("valor saude", valor)}
        // if(valor==2){valor = 4; sum4+= Number(valor); console.log("valor saude", valor)}
        // if(valor==3){valor = 3; sum4+= Number(valor); console.log("valor saude", valor)}
        // if(valor==4){valor = 2; sum4+= Number(valor); console.log("valor saude", valor)}
        // if(valor==5){valor = 1; sum4+= Number(valor)}; console.log("valor saude", valor)

        // console.log("sum4", sum4/2, feed4)
      }
      if (i == 5 || i == 6 || i == 23 || i == 30) {
        sum5 += Number(valor);
        // if(valor == 1){valor = 5;sum5+= Number(valor)};
        // if(valor == 2){valor = 4;sum5+= Number(valor)};
        // if(valor == 3){valor = 3;sum5+= Number(valor)};
        // if(valor == 4){valor = 2;sum5+= Number(valor)};
        // if(valor == 5){valor = 1;sum5+= Number(valor)};

        // console.log("sum5", sum5/2, feed5)
      }

      if (
        i == 8 ||
        i == 10 ||
        i == 11 ||
        i == 12 ||
        i == 15 ||
        i == 22 ||
        i == 26 ||
        i == 41 ||
        i == 42
      ) {
        sum6 += Number(valor);
        // console.log("sum6", sum6/2, feed6)
      }
    });


    if (this.state.dados[0] && this.state.dados[0].data && this.state.dados[0].data[0]) {
      if (respostas.filter(r => r).length < this.state.dados[0].data[0].alternativas.length) {
        notifyError(Messages.Assincronos.msgValidation);
        return;
      }
    }


    if (sum1 === null || isNaN(sum1)) {

      return notifyError('Falha ao enviar instrumento. Selecione todas questões e tente novamente');

    }

    if (sum2 === null || isNaN(sum2)) {

      return notifyError('Falha ao enviar instrumento. Selecione todas questões e tente novamente');

    }

    if (sum3 === null || isNaN(sum3)) {

      return notifyError('Falha ao enviar instrumento. Selecione todas questões e tente novamente');

    }

    if (sum4 === null || isNaN(sum4)) {

      return notifyError('Falha ao enviar instrumento. Selecione todas questões e tente novamente');

    }

    if (sum5 === null || isNaN(sum5)) {

      return notifyError('Falha ao enviar instrumento. Selecione todas questões e tente novamente');

    }

    if (sum6 === null || isNaN(sum6)) {

      return notifyError('Falha ao enviar instrumento. Selecione todas questões e tente novamente');

    }


    await api.put(
      `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
        'assincrono',
      )}/${localStorage.getItem('instrumento')}`,
      {
        resposta: {
          autonomia: sum1 / 8,
          realizacao: sum2 / 6,
          competencia: sum3 / 8,
          saude: sum4 / 8,
          seguranca: sum5 / 4,
          ajustamentos: sum6 / 9,
          escolhas: respostas,
          finished: true
        },
        projetoID: localStorage.getItem('projeto'),
      },
    ).then(() => {
      this.props.setHasChanged(false);
      this.props.history.goBack();
    })

    } catch (err) {
      this.props.handleError(err);
    }
  };

  saveAnswer = async () => {
    try {
      await api.put(
        `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
          'assincrono',
        )}/${localStorage.getItem('instrumento')}`,
        {
          resposta: {
            escolhas: respostas,
          },
          projetoID: localStorage.getItem('projeto'),
        },
      );
      this.props.setHasChanged(false);
      notifySuccess(
        'Atividade salva com sucesso. Caso queira, você poderá modificar a atividade até o prazo final',
      );
    } catch (err) {
      this.props.handleError(err);
    }
  };

  voltar = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <>
        <Prompt
          when={this.props.handleBack()}
          message={Messages.Assincronos.msgConfirmacao}
        />
        {/* <Header /> */}
        {/*  */}
        <div>
          <InstrumentContainer>
            <NewSubHeader
              titulo={"Auto conceito no Trabalho"}
              instrucao={this.state.instrumento.enunciado}
              idInstrumento={assincronoInstrumentosIDS.AutoconceitoNoTrabalho}
            />
            {this.state.dados.map(dados => (
              <div key={dados._id}>
                {dados.data.map((dados, index) => (
                  <div key={index}>
                    {dados.alternativas.map((dados, index) => (
                      this.graphicRefs.push(createRef()),
                      <>
                        <div ref={this.graphicRefs[index]} key={index} className="margin-top">
                        <div className="sel">
                          <p>
                            {" "}
                            <b>
                              {index + 1} - {dados}
                            </b>
                          </p>
                          <Row className="without-margin row-centered remove-margins question align-center" style={{ flexWrap: 'nowrap' }}>
                            <div><label htmlFor={`${index}-${1}`}>Discordo plenamente</label></div>
                            {[1, 2, 3, 4, 5].map(i => (
                              <RadioWrapper>
                                <NewCustomRadio
                                  value={String(i)}
                                  checked={respostas[index + 1] === String(i)}
                                  onChange={e => this.handleRespostas(e, index)}
                                  id={`${index}-${i}`}
                                  label={i}
                                />
                              </RadioWrapper>
                            ))}
                            <div style={{textAlign: 'right'}}><label htmlFor={`${index}-${5}`}>Concordo plenamente</label></div>
                          </Row>
                        </div>
                        </div>
                        <hr style={{marginTop: 30}} />
                      </>
                    ))}
                  </div>
                ))}
              </div>
            ))}
            <ActivityActions
              back={() => this.voltar()}
              save={this.saveAnswer}
              send={this.enviar}
              nextSend={() => scrollToUnfinishedQuestion(this.graphicRefs, this.state.dados[0].data[0].alternativas, respostas, 1)}
            >
            </ActivityActions>
          </InstrumentContainer>
        </div>

      </>
    );
  }
}

export default withSubscription(AutoconceitoNoTrabalho);
