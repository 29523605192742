import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #fff;
  min-height: ${props => props.vh || '100vh'};
  font-size: 12px;
  margin-top: 130px;
`;

const ContainerWidth = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding-bottom: 10px;
`;

const ContainerMargin = styled.div`
  margin: 0 10px;
`;

const InstrumentContainer = ({ children, vh }) => (
  <Container vh={vh}>
    <ContainerWidth>
      <ContainerMargin>
        {children}
      </ContainerMargin>
    </ContainerWidth>
  </Container>
);

export default InstrumentContainer;
