import React, { Component } from "react";
import { UncontrolledTooltip } from "reactstrap";
import PropTypes from "prop-types";

import ItemInstrumentoDashboard from "./ItemInstrumentoDashboard";

import { ItemInstrumento, ItemActions, Action } from "./styles";
import { FaPlay, FaUserFriends } from "react-icons/fa";
import { CircularButton } from "../../../Assets/Assets";
import { Tooltip } from "react-tippy";

export default class ItemInstrumentoPendente extends Component {
  static propTypes = {
    indice: PropTypes.number,
    instrumento: PropTypes.object.isRequired,
    userCanAnswer: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    userCanAnswer: false,
  };

  constructor(props) {
    super(props);
  }

  handleOpenIndicationModal = () => {
    const {
      openRespondentModal,
      instrumento: { _id, idGrupo, projectId },
    } = this.props;
    openRespondentModal(idGrupo, _id, projectId);
  };

  render() {
    const { instrumento, notRespondente } = this.props;
    return (
      <ItemInstrumento key={this.props.indice}>
        <ItemInstrumentoDashboard instrumento={instrumento} />

        <ItemActions sm="3">
          <Action>
            {instrumento.userCanSelectWhoWillAnswer && notRespondente && (
              <>
                <CircularButton
                  onClick={this.handleOpenIndicationModal}
                  // id={`repondente-${instrumento._id}`}
                >
                  <Tooltip
                    title="Cadastrar respondentes"
                    size="small"
                    position="top"
                    arrow
                  >
                    <FaUserFriends size={20} />
                  </Tooltip>
                </CircularButton>
              </>
            )}
          </Action>

          <Action>
            <CircularButton
              onClick={() => this.props.play(this.props.instrumento)}
              // id={`play-${instrumento._id}`}
            >
              <Tooltip
                title="Iniciar atividade"
                size="small"
                position="top"
                arrow
              >
                <FaPlay />
              </Tooltip>
            </CircularButton>
          </Action>
        </ItemActions>
      </ItemInstrumento>
    );
  }
}
