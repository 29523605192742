import React, { Component, createRef } from "react";
import { Prompt } from 'react-router';
import { Row } from "reactstrap";
import api, { regularApi } from "../../../services/api";
import { notifySuccess, notifyInfo, notifyError } from "../../../services/notificacoes";
import { CustomRadio } from "../../components/general";
import { normalizarPorMaximo } from "../../../utils";
import "./css/general.css";
import withSubscription from "./hoc-instrumentos-assincronos";
import ActivityActions from "./Components/ActivityActions";
import Messages from '../../../static/Messages';
import InstrumentContainer from '../../components/InstrumentContainer';
import NewSubHeader from '../ListInstruments/components/NewSubHeader';
import { NewCustomRadio } from '../../components/general';
import { RadioWrapper } from '../../components/general/NewCustomRadio';
import { assincronoInstrumentosIDS } from '../../../services/Constantes';
import { scrollToUnfinishedQuestion } from './utils/scrollToUnfinishedQuestion';

let respostas = [];

class ArteDaArticulacao extends Component {
  graphicRefs = [];

  state = {
    dados: [],
    soma: 0,
    instrumento: {
      enunciado: ''
    }
  };

  async componentDidMount() {
    await this.props.validateAccess(
      localStorage.getItem("assincrono"),
      localStorage.getItem("instrumento")
    );
    const response = await regularApi.get(
      "api/instruments/63b59197734f8926dd2050db"
    );
    const dadosDb = response.data;
    const savedAnswers = await api.get(
      `/respondente/${localStorage.getItem("user")}/${localStorage.getItem(
        "assincrono"
      )}/${localStorage.getItem("instrumento")}/${localStorage.getItem(
        "projeto"
      )}`
    );
    const storageName = `arteEscolhas-${localStorage.getItem('assincrono')}`;
    this.props.getStorageName([storageName]);
    if (sessionStorage.getItem(storageName)) this.props.setHasChanged(true);
    respostas = sessionStorage.getItem(storageName) ?
    JSON.parse(sessionStorage.getItem(storageName)) : savedAnswers.data ? savedAnswers.data.escolhas : [];
    this.setState({ dados: [dadosDb], instrumento: { enunciado: dadosDb.data && dadosDb.data[0].enunciado && dadosDb.data[0].enunciado.replaceAll("{nome_participante}", ` ${localStorage.getItem('userName')}`) } });
  }

  salvaResposta = (e, pergunta) => {
    respostas[pergunta - 1] = e.target.value;
    sessionStorage.setItem(`arteEscolhas-${localStorage.getItem('assincrono')}`, JSON.stringify(respostas));
    this.props.setHasChanged(true);
    this.setState({ respostas });
  };

  enviar = () => {
    let sum = 0;
    const alternativas = this.state.dados[0].data[0].alternativas;
    if (respostas.filter((r) => !!r === true).length < alternativas.length) {

      return notifyError(Messages.Assincronos.msgValidation);
    }
    respostas.forEach(valor => (sum += Number(valor)));
    this.setState({ soma: sum }, () => {
      this.props.setHasChanged(false);
      this.feedback();
    });
  };

  montaOpcoes = (valor, pergunta) => {
    if (valor === "normal") {
      return (
        <Row className="without-margin row-centered remove-margins question">
          <p>Discordo plenamente</p>
          {[9, 8, 7, 6, 5, 4, 3, 2, 1].reverse().map((i) => (
            <div className="radio-wrapper">
              <p>{i}</p>
              <CustomRadio
                value={String(i)}
                checked={respostas[pergunta - 1] === String(i)}
                onChange={(e) => this.salvaResposta(e, pergunta)}
              />
            </div>
          ))}
          <p>Concordo plenamente</p>
        </Row>
      );
    }
    return (
      <Row className="without-margin row-centered remove-margins question">
        <p>Discordo plenamente</p>
        {[9, 8, 7, 6, 5, 4, 3, 2, 1].map((i) => (
          <div className="radio-wrapper">
            <p>{10 - i}</p>
            <CustomRadio
              value={String(i)}
              checked={respostas[pergunta - 1] === String(i)}
              onChange={(e) => this.salvaResposta(e, pergunta)}
            />
          </div>
        ))}
        <p>Concordo plenamente</p>
      </Row>
    );
  };

  saveAnswer = async () => {
    try {
      await api.put(
        `/respondente/${localStorage.getItem("user")}/${localStorage.getItem(
          "assincrono"
        )}/${localStorage.getItem("instrumento")}`,
        {
          resposta: {
            escolhas: respostas,
          },
          projetoID: localStorage.getItem("projeto"),
        }
      );
      notifySuccess(
        "Atividade salva com sucesso. Caso queira, você poderá modificar a atividade até o prazo final"
      );
    } catch (err) {
      this.props.handleError(err);
    }
  };

  async feedback() {
    try {
      const { soma } = this.state;
      let sum = 0;
      await api.put(
        `/respondente/${localStorage.getItem("user")}/${localStorage.getItem(
          "assincrono"
        )}/${localStorage.getItem("instrumento")}`,
        {
          resposta: {
            somaNormalizada: normalizarPorMaximo(198, soma),
            somaBruta: soma,
            escolhas: respostas,
            finished: true,
          },
          projetoID: localStorage.getItem("projeto"),
        }
      );
      this.props.history.goBack();
    } catch (err) {
      this.props.handleError(err);
    }
  }

  voltar = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <>
        {/* <ModalConfirmacao
          msg={Messages.Assincronos.msgConfirmacao2}
          isMobile={isMobile}
          isOpen={modalConfirmacao}
          toggle={() => {
            this.props.setModalConfirmacao(!modalConfirmacao)
          }}
          handleClose={() => { }}
          handleYes={() => this.enviar()}
          handleNo={() => this.props.setModalConfirmacao(false)}
        /> */}
        <Prompt
          when={this.props.handleBack()}
          message={Messages.Assincronos.msgConfirmacao}
        />

        {/* <Header history={this.props.history} /> */}

        <InstrumentContainer>
          <NewSubHeader
            titulo={"Arte da Articulação"}
            instrucao={this.state.instrumento.enunciado}
            idInstrumento={assincronoInstrumentosIDS.ArteDaArticulacao}
          />
          {this.state.dados.map((dados, index) => (
            <div key={index}>
              {dados.data.map((dados, index) => (
                <div key={index}>
                  {dados.alternativas.map((dados, index) => {
                    let indexLabel = 1;
                    let niveis = dados[0].tipo === "normal" ?
                      [1, 2, 3, 4, 5, 6, 7, 8, 9]
                      :
                      [1, 2, 3, 4, 5, 6, 7, 8, 9].reverse()
                    return (
                      this.graphicRefs.push(createRef()),
                      <>
                        <div ref={this.graphicRefs[index]} key={index} className="margin-top">
                          <div className="sel">
                            <p>
                              <b>
                                {index + 1}. {dados[0].questao.replaceAll('{nome_participante}', localStorage.getItem('userName'))}
                              </b>
                            </p>
                            <Row className="without-margin row-centered remove-margins question align-center" style={{ flexWrap: 'nowrap' }}>
                              <div><label htmlFor={`${index}-${niveis[0]}`}>Discordo plenamente</label></div>
                              {niveis.map(value => (
                                <RadioWrapper
                                  style={{ height: 'unset' }}
                                  >
                                  <NewCustomRadio
                                    key={value}
                                    name={`value${index}`}
                                    onChange={e => this.salvaResposta(e, index + 1)}
                                    checked={respostas && respostas[index] && respostas[index] == value}
                                    value={value}
                                    id={`${index}-${value}`}
                                    label={indexLabel++}
                                  />
                                </RadioWrapper>
                              ))}
                              <div style={{ textAlign: 'right' }}><label htmlFor={`${index}-${niveis[8]}`}>Concordo plenamente</label></div>
                            </Row>
                          </div>
                        </div>
                        <hr style={{marginTop: 30}} />
                      </>
                    )
                  }
                  )}
                </div>
              ))}
            </div>
          ))}
          <ActivityActions
            back={this.props.voltar}
            send={this.enviar}
            nextSend={() => scrollToUnfinishedQuestion(this.graphicRefs, this.state.dados[0].data[0].alternativas, respostas)}
          >

          </ActivityActions>
        </InstrumentContainer>
      </>
    );
  }
}

export default withSubscription(ArteDaArticulacao);
