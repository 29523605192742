import React, { Component } from "react";
import { UncontrolledTooltip } from "reactstrap";
import PropTypes from "prop-types";

import ItemInstrumentoDashboard from "./ItemInstrumentoDashboard";

import { ItemInstrumento, ItemActions, Action } from "./styles";
import { FaPlay, FaUserFriends } from "react-icons/fa";
import { CircularButton } from "../../../Assets/Assets";
import { DetalhesDoInstrumento } from "./styles";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";

import assessmentIcon from "../../../../assets/img/assessment_black.svg";
import { capitalizeFirstLetter } from "../../../../utils/masks";

export default class ItemInstrumentoProximo extends Component {
  static propTypes = {
    indice: PropTypes.number,
    instrumento: PropTypes.object.isRequired,
    userCanAnswer: PropTypes.bool.isRequired
  };

  static defaultProps = {
    userCanAnswer: false
  };

  constructor(props) {
    super(props);
  }

  handleOpenIndicationModal = () => {
    const {
      openRespondentModal,
      instrumento: { _id, idGrupo, projectId }
    } = this.props;
    openRespondentModal(idGrupo, _id, projectId);
  };

  render() {
    const { instrumento } = this.props;
    return (
      <ItemInstrumento
      >
        <div>
          <img src={assessmentIcon} width="40" />
        </div>
        <DetalhesDoInstrumento sm="9">
          <Row>
            <Col>
              <strong>
                {capitalizeFirstLetter(instrumento.name)}
              </strong>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>
                Data: {instrumento.dataInicio} - {instrumento.horarioInicio}
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>
                Projeto: {instrumento.projectName}
              </span>
            </Col>
          </Row>
        </DetalhesDoInstrumento>
      </ItemInstrumento>
    );
  }
}
