import React, { Component } from "react";
import { Prompt } from "react-router";
import api, { regularApi } from "../../../services/api";
import { Button, Container, Row, Col } from "reactstrap";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import withSubscription from "./hoc-instrumentos-assincronos";

import "./css/ColetaDeFeedback.css";

import { EditorState, ContentState, convertFromHTML } from "draft-js";

import { Question, OpenInstrucoes, Header } from "iapp-design-system";
import { notifySuccess } from "../../../services/notificacoes";
import InstrumentContainer from "../../components/InstrumentContainer";
import { SubHeader } from "./Components/Subheader";
import { ASYNCHRONOUS_INSTRUMENTS_IDS } from "../../../constants/instruments-ids";
import ActivityActions from "./Components/ActivityActions";

const Loading = () => <p>Loading...</p>;

class ProjetosAtuais extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasChanged: false,
      dados: [],
      loading: true,
      textos: [],
      instructionsModalVisible: false,
    };
  }

  async componentDidMount() {
    await this.props.validateAccess(
      localStorage.getItem("assincrono"),
      localStorage.getItem("instrumento")
    );
    const response = await regularApi.get(
      `api/instruments/${ASYNCHRONOUS_INSTRUMENTS_IDS.ProjetosAtuais}`
    );
    const { name, tipo, _id, data } = response.data;
    let enunciado;
    if (data.length > 0) {
      enunciado = data[0].enunciado;
    }
    const dadosDb = response.data;
    const savedAnswers = await api.get(
      `/respondente/${localStorage.getItem("user")}/${localStorage.getItem(
        "assincrono"
      )}/${localStorage.getItem("instrumento")}/${localStorage.getItem(
        "projeto"
      )}`
    );

    let textos0 = "";
    if (
      savedAnswers.data &&
      savedAnswers.data[0] &&
      !savedAnswers.data.textos
    ) {
      textos0 = savedAnswers.data[0];
    } else if (
      savedAnswers.data &&
      savedAnswers.data.textos &&
      savedAnswers.data.textos[0]
    ) {
      textos0 = savedAnswers.data.textos[0];
    }

    let textos1 = "";
    if (
      savedAnswers.data &&
      savedAnswers.data[1] &&
      !savedAnswers.data.textos
    ) {
      textos1 = savedAnswers.data[1];
    } else if (
      savedAnswers.data &&
      savedAnswers.data.textos &&
      savedAnswers.data.textos[1]
    ) {
      textos1 = savedAnswers.data.textos[1];
    }
    this.setState({
      textos: [textos0, textos1].map(this.convertTextToEditorState),
      dados: [dadosDb],
      instrumento: {
        nome: name,
        enunciado: enunciado,
      },
      loading: false,
      hasChanged: false,
    });
  }

  enviar = async () => {
    try {
      const drafts = this.state.textos;
      const textos = drafts.map((draft) => draftToHtml(draft));
      await api
        .put(
          `/respondente/${localStorage.getItem("user")}/${localStorage.getItem(
            "assincrono"
          )}/${localStorage.getItem("instrumento")}`,
          {
            resposta: {
              textos,
              finished: true,
            },
            projetoID: localStorage.getItem("projeto"),
          }
        )
        .then((e) => {
          notifySuccess("Suas respostas foram enviadas");
          this.props.history.goBack();
        });
    } catch (err) {
      this.props.handleError(err);
    }
  };

  saveAnswer = async () => {
    try {
      const drafts = this.state.textos;
      const textos = drafts.map((draft) => draftToHtml(draft));
      await api
        .put(
          `/respondente/${localStorage.getItem("user")}/${localStorage.getItem(
            "assincrono"
          )}/${localStorage.getItem("instrumento")}`,
          {
            resposta: textos,
            projetoID: localStorage.getItem("projeto"),
          }
        )
        .then((e) => {
          notifySuccess(
            "Suas respostas foram salvas, você pode voltar no questionário a qualquer momento"
          );
        });
    } catch (err) {
      this.props.handleError(err);
    }
  };

  voltar = () => {
    this.props.history.goBack();
  };

  handleBack = () => {
    const { hasChanged } = this.state;
    return hasChanged;
  };
  convertTextToEditorState = (text) => {
    const blocksFromHtml = convertFromHTML(text || "<p></p>");
    return EditorState.createWithContent(
      ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
      )
    );
  };
  handleDraftChange = (index) => (newEditorState) => {
    const updatedText = newEditorState;
    let newTexts = [...this.state.textos];

    newTexts = newTexts.map((text, idx) => {
      if (idx === index) {
        return updatedText;
      } else {
        return text;
      }
    });

    this.setState({ textos: newTexts });
  };

  render() {
    const { loading, hasChanged, textos, instrumento } = this.state;
    let editorsInitalState = [...textos];
    let participantName = localStorage.getItem("userName");
    let enunciado = instrumento && instrumento.enunciado;
    window.onbeforeunload = (e) => {
      if (hasChanged) return true;
    };

    return (
      <InstrumentContainer>
        <Prompt
          when={this.handleBack()}
          message="É possivel que suas alterações não tenham sido salvas."
        />
        {loading ? (
          <Loading />
        ) : (
          <>
            <SubHeader
              titulo="Projetos atuais"
              instrucao={enunciado}
              idInstrumento={ASYNCHRONOUS_INSTRUMENTS_IDS.ProjetosAtuais}
            />
            <div style={{ marginTop: 30 }}>
              <Question
                editorInitialState={editorsInitalState[0]}
                ansOnChange={this.handleDraftChange(0)}
              />
            </div>
          </>
        )}
        <ActivityActions
          back={() => this.voltar()}
          send={this.enviar.bind(this)}
        />
      </InstrumentContainer>
    );
  }
}

export default withSubscription(ProjetosAtuais);
