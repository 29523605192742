import Col from 'reactstrap/lib/Col';
import styled from 'styled-components';

const ItemInstrumento = styled.div`
  display: flex;
  align-items: center;

  background-color: #fff;
  border: 1px solid #c9c9c9;
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 8px;
`;

const DetalhesDoInstrumento = styled(Col)`
  font-size: 12px;
`;

const ItemActions = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const Action = styled.div`
  margin-right: 0 5px;
`;

export {
  ItemInstrumento,
  ItemActions,
  Action,
  DetalhesDoInstrumento
};