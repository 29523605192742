import React, { Component } from "react";
import { Prompt } from "react-router";
import api, { regularApi } from "../../../services/api";
import { Button, Container, Row, Col } from "reactstrap";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import withSubscription from "./hoc-instrumentos-assincronos";

import "./css/ColetaDeFeedback.css";

import { EditorState, ContentState, convertFromHTML } from "draft-js";

import LogoSvg from "../../../assets/img/logo.svg";
import { OpenInstrucoes, Header } from "iapp-design-system";
import { Question } from './Components/Question.jsx';
import { notifySuccess } from "../../../services/notificacoes";
import InstrumentContainer from "../../components/InstrumentContainer";
import { SubHeader } from "./Components/Subheader";
import { ASYNCHRONOUS_INSTRUMENTS_IDS } from "../../../constants/instruments-ids";
import ActivityActions from "./Components/ActivityActions";

const questionEnunciates = [
  (participantName) =>
    `Nessa etapa você deve indicar quais os "3 a 5" pontos fortes do(a) ${participantName}, que reconhece como mais relevantes. Apresente exemplos.`,
  (participantName) =>
    `Nessa etapa  você deve indicar as oportunidades de desenvolvimento pessoal mais relevantes para o(a) ${participantName}. Apresente exemplos.`,
];

const Loading = () => <p>Loading...</p>;

class ColetaDeFeedback extends Component {
  state = {
    hasChanged: false,
    dados: [],
    loading: true,
    textos: [],
    instructionsModalVisible: false,
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    await this.props.validateAccess(
      localStorage.getItem("assincrono"),
      localStorage.getItem("instrumento")
    );
    const response = await regularApi.get(
      "api/instruments/612396ce3afeca1aaf7be920"
    );
    const { name, tipo, _id, data } = response.data;
    let enunciado;
    if (data.length > 0) {
      enunciado = data[0].enunciado;
    }

    if (enunciado) {
      const participantName = localStorage.getItem("userName");

      enunciado = enunciado.replace(/“nome do participante”/g, participantName);
    }

    const dadosDb = response.data;
    const savedAnswers = await api.get(
      `/respondente/${localStorage.getItem("user")}/${localStorage.getItem(
        "assincrono"
      )}/${localStorage.getItem("instrumento")}/${localStorage.getItem(
        "projeto"
      )}`
    );

    let textos0 = "";
    if (
      savedAnswers.data &&
      savedAnswers.data[0] &&
      !savedAnswers.data.textos
    ) {
      textos0 = savedAnswers.data[0];
    } else if (
      savedAnswers.data &&
      savedAnswers.data.textos &&
      savedAnswers.data.textos[0]
    ) {
      textos0 = savedAnswers.data.textos[0];
    }

    let textos1 = "";
    if (
      savedAnswers.data &&
      savedAnswers.data[1] &&
      !savedAnswers.data.textos
    ) {
      textos1 = savedAnswers.data[1];
    } else if (
      savedAnswers.data &&
      savedAnswers.data.textos &&
      savedAnswers.data.textos[1]
    ) {
      textos1 = savedAnswers.data.textos[1];
    }

    const storageName0 = `coletaEscolhas0-${localStorage.getItem('assincrono')}`;
    const storageName1 = `coletaEscolhas1-${localStorage.getItem('assincrono')}`;

    this.props.getStorageName([storageName0, storageName1]);

    if (sessionStorage.getItem(storageName0 || storageName1)) this.props.setHasChanged(true);

    if (sessionStorage.getItem(storageName0)) {
      const draft0 = JSON.parse(sessionStorage.getItem(storageName0));
      if (draft0.blocks && draftToHtml(draft0) !== '<p></p>')
        textos0 = draftToHtml(draft0);
    }

    if (sessionStorage.getItem(storageName1)) {
      const draft1 = JSON.parse(sessionStorage.getItem(storageName1));
      if (draft1.blocks && draftToHtml(draft1) !== '<p></p>')
        textos1 = draftToHtml(draft1);
    }

    this.setState({
      textos: [textos0, textos1].map(this.convertTextToEditorState),
      dados: [dadosDb],
      instrumento: {
        nome: name,
        enunciado: enunciado,
      },
      loading: false,
      hasChanged: false,
    });
  }

  enviar = async () => {
    try {
      const drafts = this.state.textos;
      const textos = drafts.map((draft) => draftToHtml(draft));
      await api
        .put(
          `/respondente/${localStorage.getItem("user")}/${localStorage.getItem(
            "assincrono"
          )}/${localStorage.getItem("instrumento")}`,
          {
            resposta: {
              textos,
              finished: true,
            },
            projetoID: localStorage.getItem("projeto"),
          }
        )
        .then((e) => {
          notifySuccess("Suas respostas foram enviadas");
          this.props.setHasChanged(false);
        });

        this.props.history.goBack();
    } catch (err) {
      this.props.handleError(err);
    }
  };

  saveAnswer = async () => {
    try {
      const drafts = this.state.textos;
      const textos = drafts.map((draft) => draftToHtml(draft));
      await api
        .put(
          `/respondente/${localStorage.getItem("user")}/${localStorage.getItem(
            "assincrono"
          )}/${localStorage.getItem("instrumento")}`,
          {
            resposta: textos,
            projetoID: localStorage.getItem("projeto"),
          }
        )
        .then((e) => {
          notifySuccess(
            "Suas respostas foram salvas, você pode voltar no questionário a qualquer momento"
          );
        });
    } catch (err) {
      this.props.handleError(err);
    }
  };

  voltar = () => {
    this.props.history.goBack();
  };

  handleBack = () => {
    const { hasChanged } = this.state;
    return hasChanged;
  };
  convertTextToEditorState = (text) => {
    const blocksFromHtml = convertFromHTML(text || "<p></p>");
    return EditorState.createWithContent(
      ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
      )
    );
  };
  handleDraftChange = (index) => (newEditorState) => {
    const updatedText = newEditorState;
    let newTexts = [...this.state.textos];

    newTexts = newTexts.map((text, idx) => {
      if (idx === index) {
        return updatedText;
      } else {
        return text;
      }
    });

    if (newTexts[0].blocks && draftToHtml(newTexts[0]) !== '<p></p>') {
      sessionStorage.setItem(`coletaEscolhas0-${localStorage.getItem('assincrono')}`, JSON.stringify(newTexts[0]));
    }
    if (newTexts[1].blocks && draftToHtml(newTexts[1]) !== '<p></p>')
      sessionStorage.setItem(`coletaEscolhas1-${localStorage.getItem('assincrono')}`, JSON.stringify(newTexts[1]));

    this.props.setHasChanged(true);

    this.setState({ textos: newTexts });
  };

  render() {
    const { loading, hasChanged, textos, instrumento } = this.state;
    let editorsInitalState = [...textos];
    let participantName = localStorage.getItem("userName");
    let enunciado = instrumento && instrumento.enunciado;
    window.onbeforeunload = (e) => {
      if (hasChanged) return true;
    };

    return (
      <InstrumentContainer vh='0vh'>
        <Prompt
          when={this.props.handleBack()}
          message="É possivel que suas alterações não tenham sido salvas."
        />
        {loading ? (
          <Loading />
        ) : (
          <>
            <SubHeader
              titulo="Coleta de Feedback"
              instrucao={enunciado}
              idInstrumento={ASYNCHRONOUS_INSTRUMENTS_IDS.ColetaDeFeedback}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              {questionEnunciates
                .map((enunciateFn) => enunciateFn(participantName))
                .map((enunciate, index) => (
                  <Row
                    key={index}
                    style={{ marginTop: index !== 0 ? 100 : 50 }}
                  >
                    {" "}
                    {/* dá pra diminuir pra 100 sem ficar feio no mobile */}
                    <Col sm="12">
                      <Question
                        number={index + 1}
                        enunciate={enunciate}
                        editorInitialState={editorsInitalState[index]}
                        ansOnChange={this.handleDraftChange(index)}
                      />
                    </Col>
                  </Row>
                ))}
            </div>
          </>
        )}

        <ActivityActions
          back={() => this.voltar()}
          send={this.enviar.bind(this)}
        />
      </InstrumentContainer>
    );
  }
}

export default withSubscription(ColetaDeFeedback);
