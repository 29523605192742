export const ASYNCHRONOUS_INSTRUMENTS_IDS = {
    EscutaAtiva :"5c0455bcacd7ba15fc075536",
    AutoconceitoNoTrabalho : "5c04514dacd7ba15fc075534",
    LocusdeControle : "5c19a9680fc62504da06d4e7",
    ColetaDeFeedback : "5c045b60acd7ba15fc075538",
    AspectosDeIdentidade : "5c045959acd7ba15fc075537",
    ArteDaArticulacao : "5c13ecbf342a0f7306b8a090",
    AncorasDaCarreira : "5c176942342a0f7306b8a093",
    EstilosDeLideranca : "5c1a84452c87cd05d02bd1d8",
    EstiloDeComunicacao : "5c1ba7d92c87cd05d02bd1db",
    Autoeficacia : "5c1bfc062c87cd05d02bd1e0",
    TiposDePersonalidade : "5c1bec082c87cd05d02bd1df",
    HistoriaDeVida: "5df3b10fdc68f12269347fbe",
    ProjetosAtuais: "5df3e33cdc68f12269347fbf"
  }