/* eslint-disable react/jsx-filename-extension */
import { GlobalDebug } from "./utils/remove-console"
import React, { useState, useEffect } from 'react';
import Bowser from "bowser";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AuthenticateScreen from './views/pages/Authenticate';
import ListInstruments from './views/pages/ListInstruments';
import InvalidBrowser from './components/InvalidBrowser';
import CookieConsent from "react-cookie-consent";
import {
  AncoraDaCarreira,
  TipoDePersonalidade,
  Autoeficacia,
  EscutaAtiva,
  AspectosDeIdentidade,
  ArteDaArticulacao,
  LocusDeControle,
  ColetaDeFeedback,
  EstiloDeComunicacao,
  AutoconceitoNoTrabalho,
  TiposDeLideranca_assincrono
} from './views/pages/Instruments';
import MyAuth from './services/withAuth';
import CookieConsentModal from "./components/CookieConsentModal";
import {
  ScssGlobalStyles
} from 'iapp-design-system';

import "./styles/global.css";
import 'react-tippy/dist/tippy.css';
import Header from './views/Assets/Header';
import ProjetosAtuais from './views/pages/Instruments/ProjetosAtuais';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import api from './services/api';

if (process.env.NODE_ENV === 'production') {
  GlobalDebug(false)
}

function App() {
  const [validBrowser, setValidBrowser] = useState(true);
  const [cookieModal, setCookieModal] = useState(false);
  const [consentVisible, setConsentVisible] = useState(false);

  const verifyPolicy = async() => {
    const url = document.URL.split('/');
    const id = url[url.length - 1];

    const res = await api.get(`/respondente/get-policy/${id}`);

    const { policy_agreement } = res.data;

    setConsentVisible(!policy_agreement )
  }

  useEffect(()=>{
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isValidBrowser = browser.satisfies({
      chrome: ">=81.0.4000",
      firefox: ">=69",
      opera: ">=65",
      edge: ">=81.0",
      safari: ">=12"
    });

    const isValid = !browser.getBrowser().name.toLowerCase().includes('internet explorer') && isValidBrowser !== false;
    setValidBrowser(isValid);

    verifyPolicy();
  },[]);

  const onAcceptPolicy = async() => {
    const url = document.URL.split('/');
    const id = url[url.length - 1];

    const res = await api.put(`/respondente/accept-policy/${id}`);

    const { success } = res.data;

    if (success) setConsentVisible(false);
  }

  return (
    <>
      <CookieConsentModal isOpen={cookieModal} toggle={() => { setCookieModal(!cookieModal) }} />
      <CookieConsent
        location="bottom"
        buttonText="Ok"
        overlay={true}
        onAccept={onAcceptPolicy}
        visible={consentVisible ? 'show' : 'hidden'}
        cookieName={'CookieConsentIappRespondente'}
        style={{ background: "#2B373B", justifyContent: 'center', zIndex: 1050 }}
        contentStyle={{ flex: 'none' }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        overlayStyle={{ zIndex: 1049 }}
      >
        Para prosseguir, precisamos que leia e aceite a nossa <a onClick={() => {setCookieModal(!cookieModal)}} href="javascript:void(0)">Política de Privacidade</a>. Ao clicar em "Ok", você consente que está de acordo.
      </CookieConsent>
      <ToastContainer />
      {!validBrowser ? (
        <InvalidBrowser />
      ) : null}
      <ScssGlobalStyles>
        <div className='App'>
          <BrowserRouter>
            <Header />
            <div className='app-body'>
            <Switch>
              <Route
                exact
                path='/items'
                name='Instrumentos'
                component={MyAuth(ListInstruments)}
              />
              <Route
                exact
                path='/ancoras-da-carreira'
                name='Ancora da carreira'
                component={MyAuth(AncoraDaCarreira)}
              />
              <Route
                exact
                path='/tipos-de-personalidade'
                name='Tipos de personalidade'
                component={MyAuth(TipoDePersonalidade)}
              />
              <Route
                exact
                path='/autoeficacia'
                name='Autoeficácia'
                component={MyAuth(Autoeficacia)}
              />
              <Route
                exact
                path='/escuta-ativa'
                name='Escuta ativa'
                component={MyAuth(EscutaAtiva)}
              />
              <Route
                exact
                path='/projetos-atuais'
                name='Projetos atuais'
                component={MyAuth(ProjetosAtuais)}
              />
              <Route
                exact
                path='/aspectos-de-identidade'
                name='Aspectos de identidade'
                component={MyAuth(AspectosDeIdentidade)}
              />
              <Route
                exact
                path='/arte-da-articulacao'
                name='Arte da articulação'
                component={MyAuth(ArteDaArticulacao)}
              />
              <Route
                exact
                path='/coleta-de-feedback'
                name='Coleta de feedback'
                component={MyAuth(ColetaDeFeedback)}
              />
              <Route
                exact
                path='/locus-de-controle'
                name='Locus de controle'
                component={MyAuth(LocusDeControle)}
              />
              <Route
                exact
                path='/estilo-de-comunicacao'
                name='Estilo de Comunição'
                component={MyAuth(EstiloDeComunicacao)}
              />
              <Route
                exact
                path='/estilos-de-lideranca---assincrono'
                name='Estilos de Liderança'
                component={MyAuth(TiposDeLideranca_assincrono)}
              />
              <Route
                exact
                path='/autoconceito-no-trabalho'
                name='Autoconceito no trabalho'
                component={MyAuth(AutoconceitoNoTrabalho)}
              />
              <Route
                path='/'
                name='Confirmar e-mail'
                component={AuthenticateScreen}
              />
            </Switch>
            </div>
            <Footer />
          </BrowserRouter>
        </div>
      </ScssGlobalStyles>
    </>
  );
}

export default App;
