import React from "react";
import apiCalls from "../../../config/api-calls";
import { notifyError } from "../../../services/notificacoes";
import { BlockWindowUnload } from '../../components/block-window-unload';

export default function withSubscription(WrappedComponent) {
  return class extends React.Component {
    state = {
      isMobile: !!(window.innerWidth < 575),
      hasChanged: false,
      currentPath: window.location.pathname,
      storageName: []
    };

    constructor(props) {
      super(props);
    }

    updateSize = () => {
      this.setState({
        isMobile: !!(window.innerWidth < 575),
      });
    };

    async validateAccess(idGrp, idInstrumento) {
      try {
        const res = await apiCalls.assincronos.validateRespondentAccess(
          idGrp,
          idInstrumento
        );

        return res;
      } catch (err) {
        this.handleError(err);
      }
    }

    componentDidMount() {
      window.addEventListener("resize", this.updateSize);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateSize);
    }

    getStorageName = (nameArray) => {
      this.setState({ storageName: nameArray });
    }

    setHasChanged = (value, cb) => this.setState({ hasChanged: value }, cb);

    voltar = () => {
      this.props.history.goBack();
    };

    handleBack = () => {
      return this.state.hasChanged;
    };

    handleError = (error) => {
      console.error(error);
      if (error.response && error.response.status === 401) {
        notifyError("Data de entrega expirada!");

        const redirect = () => this.props.history.push("/items");
        setTimeout(redirect, 3000);
      }
    };

    render() {
      return (
        <>
          <BlockWindowUnload storageName={this.state.storageName} currentPath={this.state.currentPath} when={this.state.hasChanged} />
          <WrappedComponent
            data={this.state}
            getStorageName={this.getStorageName}
            hasChangedHoc={this.hasChanged}
            handleBack={this.handleBack}
            setHasChanged={this.setHasChanged}
            voltar={this.voltar}
            handleError={this.handleError}
            {...this.props}
            validateAccess={this.validateAccess}
          />
        </>
      );
    }
  };
}
