import decode from 'jwt-decode';
import api from './api';

export default class Auth {
  static loggedIn() {
    return api
      .get('/respondente')
      .then(res => {
        console.log(res.status);
        console.log(res.data.loggedIn);
        return res.data.loggedIn;
      })
      .catch(e => {
        return false;
      });
  }

  static setToken(idToken) {
    // Saves user token to sessionStorage
    sessionStorage.setItem('token', idToken);
    console.log(sessionStorage.getItem('token'));
  }

  static getToken() {
    // Retrieves the user token from sessionStorage
    return sessionStorage.getItem('token');
  }

  static logout() {
    // Clear user token and profile data from sessionStorage
    sessionStorage.removeItem('token');
  }

  static getConfirm() {
    // Using jwt-decode npm package to decode the token
    const answer = decode(String(Auth.getToken()));
    console.log(answer);
    console.log('Recieved answer!');
    return answer;
  }

  static getEmail() {
    return sessionStorage.getItem('email');
  }

  static setEmail(email) {
    sessionStorage.setItem('email', email);
  }
}
