import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import logo from '../../../../assets/img/logo_curto.png';
import OpenInstrucoes from './OpenInstrucoes';
import './css/SubHeader.css';
import styled from 'styled-components';

const Titulo = styled.h1`
  color: #929c7c;
  font-size: 18px;
`;

class SubHeader extends Component {
  render() {
    return (
      <>
        <Row className='sub-header ' style={ this.props.customStyle }>
          <Col style={{ display: 'flex', alignItems: 'flex-end', marginTop: '35px' }}>
            <div style={{ width: '100%', margin: '0 auto' }}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <img src={logo} width={40} style={{ marginRight: '15px' }}  />
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Titulo>{this.props.titulo}</Titulo>
                  <OpenInstrucoes {...this.props} />
                </div>
                </div>
              <hr />
            </div>
          </Col>
        </Row>
        <div className='subheader-offset-dash'></div>
      </>
    );
  }
}

export default SubHeader;
