// import socket from "socket.io-client";
const baseURL = process.env.REACT_APP_ASSINC_API_URL

// Socket
// export const io = socket(baseURL, { query: 'role=consultor' });
// export const io = socket(
//   baseURL,
//   { query: "role=consultor", transports: ["websocket"], upgrade: false },
//   { "force new connection": true }
// );

export const ModuloAssincrono = {
  Nomes_Instrumentos: {
    PROJETOS_ATUAIS: "Projetos Atuais",
    HISTORIA_DE_VIDA: "história de vida",
    COLETA_DE_FEEDBACK: "coleta de feedback"
  }
};

// Gerais
export const tipoEmpresaCliente = "cliente";
export const cpfTamanho = 11;
export const cargoInicial = "participante";
export const minAleatorio = 3333;
export const maxAleatorio = 88888;
export const codigoAcessoSize = 6;

// Funções
export const funcaoParticipante = "participante";
export const funcaoConsultor = "consultor";
export const funcaoMembro = "membro";

// Instrumentos
export const instrumentoCase = "case";
export const instrumentoCaseIndividual = "case";
export const instrumentoCaseIndividual2 = "case2";
export const instrumentoCaseGrupo = "caseGrupo";
export const instrumentoBinario = "binario";
export const instrumentoStorytelling = "storytelling";
export const instrumentoStoryTellingIndividual = "storytelling";
export const instrumentoStoryTellingIndividual2 = "storytelling2";
export const instrumentoStoryTellingGrupo = "storytellingGrupo";
export const instrumentoSociometria = "sociometria";
export const instrumentoEstilos = "Estilos de liderança";
export const instrumentoProvas = "provas";
/** Os ids e os instrumentos assincronos
 * @type {Map<string, string>}
 */
export const instrumentosIDs = {
  "5c0455bcacd7ba15fc075536": "Escuta ativa",
  "5c04514dacd7ba15fc075534": "Autoconceito no trabalho",
  "5c19a9680fc62504da06d4e7": "Locus de controle",
  "5c045b60acd7ba15fc075538": "Coleta de feedback",
  "5c045959acd7ba15fc075537": "Aspectos de identidade",
  "5c13ecbf342a0f7306b8a090": "Arte da articulação",
  "5c176942342a0f7306b8a093": "Âncoras da carreira",
  "5c1a84452c87cd05d02bd1d8": "Estilos de liderança",
  "5c1ba7d92c87cd05d02bd1db": "Estilo de comunicação",
  "5c1bfc062c87cd05d02bd1e0": "Autoeficácia",
  "5c1bec082c87cd05d02bd1df": "Tipos de personalidade",
  "5df3b10fdc68f12269347fbe": "História de vida",
  "5df3e33cdc68f12269347fbf": "Projetos atuais"
};

export const assincronoInstrumentosIDS = {
  EscutaAtiva :"5c0455bcacd7ba15fc075536",
  AutoconceitoNoTrabalho : "5c04514dacd7ba15fc075534",
  LocusdeControle : "5c19a9680fc62504da06d4e7",
  ColetaDeFeedback : "5c045b60acd7ba15fc075538",
  AspectosDeIdentidade : "5c045959acd7ba15fc075537",
  ArteDaArticulacao : "5c13ecbf342a0f7306b8a090",
  AncorasDaCarreira : "5c176942342a0f7306b8a093",
  EstilosDeLideranca : "5c1a84452c87cd05d02bd1d8",
  EstiloDeComunicacao : "5c1ba7d92c87cd05d02bd1db",
  Autoeficacia : "5c1bfc062c87cd05d02bd1e0",
  TiposDePersonalidade : "5c1bec082c87cd05d02bd1df",
  HistoriaDeVida: "5df3b10fdc68f12269347fbe",
  ProjetosAtuais: "5df3e33cdc68f12269347fbf"
}

export const atividadesIndividual = [
  instrumentoCaseIndividual,
  instrumentoStoryTellingIndividual
];

export const atividadesIndividual2 = [
  instrumentoCaseIndividual2,
  instrumentoStoryTellingIndividual2
];

export const atividadeRelacionadas = {
  [instrumentoCaseIndividual]: [instrumentoCaseIndividual2, instrumentoBinario],
  [instrumentoStoryTellingIndividual]: [instrumentoStoryTellingIndividual2]
};

export const atividadesTemplate = [
  instrumentoCaseIndividual,
  instrumentoStoryTellingIndividual
];

// Tempos
export const temposSincronoInstrumentos = {
  [instrumentoCaseIndividual]: {
    individual: 15,
    grupo: 40,
    binario: 10
  },
  [instrumentoStoryTellingIndividual]: {
    individual: 15,
    grupo: 40
  },
  [instrumentoEstilos]: {
    individual: 15
  },
  [instrumentoSociometria]: {
    individual: 5
  }
};

// Provas
export const tempoProvaQuestao = 3; // minutos
export const tempoProvaProva = 50; // minutos
export const provaQuestoes = 20; // Quantidade de questões
export const provaTextoInicial = "MODELO - Texto inicial prova ";
export const provaTextoFinal = "MODELO - Texto final prova ";

export const estilosNiveis = [0, 0.41, 0.66, 0.82, 0.91, 0.97, 1];

// Modulos
export const moduloDinamicas = "dinamicas";
export const moduloAssincronos = "assincronos";
export const moduloProvas = "provas";
export const moduloAssessments = "assessments";

// Estatistica
export const confiabilidade = 1.96; //Confiabilidade
export const p = 0.5; // proporção
export const e = 0.05; // erro

// Mensagens
export const mensagemSalvo = "Salvo!";
export const mensagemFaltaCargos = "Defina os Cargos antes de Prosseguir";
export const mensagemCargoVazio = "Insira o cargo";
export const mensagemCargoRepetido = "Cargo já existente";
export const mensagemNomeVazio = "Insira o nome";
export const mensagemCPFinvalido = "Insira um CPF válido";

//// ACOMPANHAMENTO
export const mensagemStatusAndamento = "Em progresso";
export const mensagemStatusPendenteRodadas = "Pendência: Configurar rodadas";
export const mensagemStatusPendenteGrupos = "Pendência: Configurar grupos";
export const mensagemStatusPendenteDinâmica = "Pendência: Configurar dinâmica";
export const mensagemStatusPendenteParticipantes =
  "Pendência: Adicionar participantes no módulo";
export const mensagemStatusConcluido = "Concluído";
export const mensagemStatusIniciar = "Módulo não iniciado";
export const mensagemPlaySemGrupo =
  "Play nessa atividade somente após grupos serem formados";
export const mensagemPlaySemAtividadeGrupo =
  "Play nessa atividade só pode ser dado após a atividade em grupo da rodada";
export const mensagemPlayEmGrupoSemIndividual =
  "Play nessa atividade só pode ser dado após a ativididade individual relacionada";
export const mensagemPlayEmBinarioSemGrupo =
  "Play nessa atividade só pode ser dado após a ativididade em grupo relacionada";
export const mensagemPlayEmBinarioSemDuelos =
  "Play nessa atividade só pode ser dado após a distribuição dos duelos";
export const mensagemPlaySemTemplate =
  "Atividade sem template. Configure a atividade antes de dar play";
export const mensagemRodadaAtualizada = "Rodada atualizada com sucesso!";
export const mensagemTempoInvalido = "Tempo invalido (formato HH:MM:SS)";
export const mensagemTempoAtualizado = "Tempo Atualizado!";

// Participantes
export const mensagemNadaParaAlterar = "Nada para alterar.";
export const mensagemErroInclusaoParticipante =
  "Erro: não foi possível incluir o participante ao projeto!";
export const mensagemParticipanteExcluido = "Participante removido!";
export const mensagemErroExclusaoParticipante =
  "Erro: não foi possível excluir o participante!";

export const mensagemCPFInvalido = "CPF Inválido";
export const mensagemEmailInvalido = "Email Inválido";

export const mensagemNomeArquivoInvalido =
  "Erro: Arquivo(s) com nome inválido!";

// Grupos Instrumentos
export const quantidadeCriteriosGrupo = 5;

// Projeto (Grupos)
export const quantidadeUsuariosMaximo = 12;
export const quantidadeUsuariosMinimo = 4;
export const grupoMetodoManual = "Manual";
export const grupoMetodoAleatorio = "Aleatorio";
export const grupoMetodoProva = "Prova";
export const grupoMetodoSociometria = "Sociometria";
export const grupoMetodoRanking = "Ranking";

// Icones
export const iconePlay = "fa fa-play";
export const iconeStop = "fa fa-stop";
export const iconeCheck = "fa fa-check-circle";
export const iconePen = "fa fa-pencil";
export const iconeForward = "fa fa-forward";

// Imagens
export const imagemSpinner = "spinner.gif";

export class Constantes {
  // static get io() {
  //   return io;
  // }
  static get tipoEmpresaCliente() {
    return tipoEmpresaCliente;
  }
  static get cpfTamanho() {
    return cpfTamanho;
  }
  static get cargoInicial() {
    return cargoInicial;
  }
  static get minAleatorio() {
    return minAleatorio;
  }
  static get maxAleatorio() {
    return maxAleatorio;
  }
  static get codigoAcessoSize() {
    return codigoAcessoSize;
  }
  static get funcaoParticipante() {
    return funcaoParticipante;
  }
  static get funcaoConsultor() {
    return funcaoConsultor;
  }
  static get funcaoMembro() {
    return funcaoMembro;
  }
  static instrumentoCase = "case";
  static get instrumentoCaseIndividual() {
    return instrumentoCaseIndividual;
  }
  static get instrumentoCaseIndividual2() {
    return instrumentoCaseIndividual2;
  }
  static get instrumentoCaseGrupo() {
    return instrumentoCaseGrupo;
  }
  static get instrumentoBinario() {
    return instrumentoBinario;
  }
  static get instrumentoStoryTellingIndividual() {
    return instrumentoStoryTellingIndividual;
  }
  static get instrumentoStoryTellingIndividual2() {
    return instrumentoStoryTellingIndividual2;
  }
  static get instrumentoStoryTellingGrupo() {
    return instrumentoStoryTellingGrupo;
  }
  static get instrumentoSociometria() {
    return instrumentoSociometria;
  }
  static get instrumentoEstilos() {
    return instrumentoEstilos;
  }
  static get instrumentoProvas() {
    return instrumentoProvas;
  }
  static get tempoProvaQuestao() {
    return tempoProvaQuestao;
  }
  static get tempoProvaProva() {
    return tempoProvaProva;
  }
  static get provaQuestoes() {
    return provaQuestoes;
  }
  static get provaTextoInicial() {
    return provaTextoInicial;
  }
  static get provaTextoFinal() {
    return provaTextoFinal;
  }
  static get estilosNiveis() {
    return estilosNiveis;
  }
  static get atividadesIndividual() {
    return atividadesIndividual;
  }
  static get atividadesIndividual2() {
    return atividadesIndividual2;
  }
  static get atividadeRelacionadas() {
    return atividadeRelacionadas;
  }
  static get atividadesTemplate() {
    return atividadesTemplate;
  }
  static get temposSincronoInstrumentos() {
    return temposSincronoInstrumentos;
  }
  static get moduloDinamicas() {
    return moduloDinamicas;
  }
  static get moduloAssincronos() {
    return moduloAssincronos;
  }
  static get moduloProvas() {
    return moduloProvas;
  }
  static get moduloAssessments() {
    return moduloAssessments;
  }
  static get confiabilidade() {
    return confiabilidade;
  }
  static get p() {
    return p;
  }
  static get e() {
    return e;
  }
  static get mensagemSalvo() {
    return mensagemSalvo;
  }
  static get mensagemFaltaCargos() {
    return mensagemFaltaCargos;
  }
  static get mensagemCargoVazio() {
    return mensagemCargoVazio;
  }
  static get mensagemCargoRepetido() {
    return mensagemCargoRepetido;
  }
  static get mensagemNomeVazio() {
    return mensagemNomeVazio;
  }
  static get mensagemCPFinvalido() {
    return mensagemCPFinvalido;
  }
  static get mensagemStatusAndamento() {
    return mensagemStatusAndamento;
  }
  static get mensagemStatusPendenteRodadas() {
    return mensagemStatusPendenteRodadas;
  }
  static get mensagemStatusPendenteGrupos() {
    return mensagemStatusPendenteGrupos;
  }
  static get mensagemStatusPendenteDinâmica() {
    return mensagemStatusPendenteDinâmica;
  }
  static get mensagemStatusConcluido() {
    return mensagemStatusConcluido;
  }
  static get mensagemStatusIniciar() {
    return mensagemStatusIniciar;
  }
  static get mensagemPlayEmGrupoSemIndividual() {
    return mensagemPlayEmGrupoSemIndividual;
  }
  static get mensagemPlayEmBinarioSemGrupo() {
    return mensagemPlayEmBinarioSemGrupo;
  }
  static get mensagemPlayEmBinarioSemDuelos() {
    return mensagemPlayEmBinarioSemDuelos;
  }
  static get mensagemPlaySemTemplate() {
    return mensagemPlaySemTemplate;
  }
  static get mensagemRodadaAtualizada() {
    return mensagemRodadaAtualizada;
  }
  static get mensagemTempoInvalido() {
    return mensagemTempoInvalido;
  }
  static get mensagemTempoAtualizado() {
    return mensagemTempoAtualizado;
  }
  static get quantidadeCriteriosGrupo() {
    return quantidadeCriteriosGrupo;
  }
  static get quantidadeUsuariosMaximo() {
    return quantidadeUsuariosMaximo;
  }
  static get quantidadeUsuariosMinimo() {
    return quantidadeUsuariosMinimo;
  }
  static get mensagemParticipanteExcluido() {
    return mensagemParticipanteExcluido;
  }
  static get mensagemErroExclusaoParticipante() {
    return mensagemErroExclusaoParticipante;
  }
  static get mensagemCPFInvalido() {
    return mensagemCPFInvalido;
  }
  static get mensagemEmailInvalido() {
    return mensagemEmailInvalido;
  }
  static get mensagemErroInclusaoParticipante() {
    return mensagemErroInclusaoParticipante;
  }
  static get mensagemNadaParaAlterar() {
    return mensagemNadaParaAlterar;
  }
  static get mensagemNomeArquivoInvalido() {
    return mensagemNomeArquivoInvalido;
  }
  static get mensagemPlaySemAtividadeGrupo() {
    return mensagemPlaySemAtividadeGrupo;
  }
  static get mensagemPlaySemGrupo() {
    return mensagemPlaySemGrupo;
  }
  static get grupoMetodoManual() {
    return grupoMetodoManual;
  }
  static get grupoMetodoAleatorio() {
    return grupoMetodoAleatorio;
  }
  static get grupoMetodoProva() {
    return grupoMetodoProva;
  }
  static get grupoMetodoSociometria() {
    return grupoMetodoSociometria;
  }
  static get grupoMetodoRanking() {
    return grupoMetodoRanking;
  }
  static get iconePlay() {
    return iconePlay;
  }
  static get iconeStop() {
    return iconeStop;
  }
  static get iconeCheck() {
    return iconeCheck;
  }
  static get imagemSpinner() {
    return imagemSpinner;
  }
  static get baseURL() {
    return baseURL;
  }
  static get iconePen() {
    return iconePen;
  }
  static get iconeForward() {
    return iconeForward;
  }
}

export const newConstantes = {
  provaSincrona: "sincrono",
  provaAssincrona: "assincrono",

  MODULO_ASSINCRONO: {
    PROGRESSO_INSTRUMENTO_ASSINCRONO: {
      INICIADO: "Iniciado",
      FINALIZADO: "Finalizado",
      EXPIRADO: "Expirado",
      NAO_INICIADO: "Não iniciado"
    }
  }
};

export const PLOT_COLORS = [
  '#7f5fa9',
  '#ed5f60',
  '#face3a',
  '#8B8386',
  '#56c5d5',
  '#f17542',
  '#006400',
  '#B8860B',
  '#00FF7F',
  '#363636',

  '#cddd84',
  '#cdc5bb',
  '#a14443',
  '#FF1493',
  '#1c136c',
  '#CD6090',
  '#32CD32',
  '#FF6A6A',
  '#556B2F',
  '#FFFF00',
]

export const xlsxOptions = {
  readTimeout: 10000
}

export const STATUS_INSTRUMENTO = {
  NAO_INICIADO: -1,
  INICIADO: 1,
  FINALIZADO: 0
}

export const LINK_IAPP = 'https://iapp.formaretech.com.br';
export const LINK_IAPP_PARTICIPANTES = 'https://participante.formaretech.com.br';
