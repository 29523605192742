import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
// import logo from '../../../../assets/img/IAPP-yellow-logo.png';
import OpenInstrucoes from './OpenInstrucoes';
import './css/NewSubHeader.css';
import styled from 'styled-components';
import { handleIcon } from '../../../../utils/InstrumentIcons';

const Titulo = styled.h1`
  color: #929c7c;
  font-size: 18px;
  margin-bottom: 0;
`;

class NewSubHeader extends Component {
  render() {
    const {customIcon} = this.props;
    return (
      <>
        <Row className='sub-header' style={ this.props.customStyle }>
          {/* <div width='50px'> */}

          {/* </div> */}
          <Col style={{ display: 'flex', alignItems: 'flex-end', marginTop: '35px' }}>
            <div style={{ width: '100%', maxWidth: 1024, margin: '0 auto' }}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                {/* <img src={logo} width={50} style={{ marginRight: '15px' }}  /> */}
                {
                  customIcon ?
                  customIcon :
                  handleIcon(this.props.idInstrumento, '#929c7c')
                }
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxWidth: 974 }}>
                  <Titulo>{this.props.titulo}</Titulo>
                  <OpenInstrucoes {...this.props} />
                </div>
                </div>
              <hr />
            </div>
          </Col>
        </Row>
        <div className='subheader-offset'></div>
      </>
    );
  }
}

export default NewSubHeader;
