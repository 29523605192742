import React, { useState } from "react";
import { Container, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Button } from "../../../components/Buttons";

export default function ActivityActions({ send, hideSend, nextSend, save, back, hideBack, children }) {
  const [isModalSendOpen, setIsModalSendOpen] = useState(false);

  const toggleModalSend = () => {
    setIsModalSendOpen(!isModalSendOpen);
  };

  return (
    <>
      <Modal isOpen={isModalSendOpen} toggle={toggleModalSend} size="md">
        <ModalHeader toggle={toggleModalSend} style={{ fontSize: 18 }}>
          Enviar Atividade
        </ModalHeader>
        <ModalBody className="modal-padding" style={{ fontSize: 12 }}>
          <p>Deseja enviar a atividade?</p>
          <p>IMPORTANTE: APÓS O ENVIO, NÃO SERÁ POSSÍVEL FAZER MODIFICAÇÕES.</p>
        </ModalBody>
        <div className="modal-footer">
          <Button color="danger" onClick={toggleModalSend}>
            Cancelar
          </Button>
          <Button color="success" onClick={async (e) => {
            toggleModalSend();
            if (nextSend) {
              setTimeout(async () => {
                await send(e);
                nextSend();
              }, 650);
            } else {
              await send(e);
            }
          }}>
            Enviar
          </Button>
        </div>
      </Modal>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 50
        }}
      >
        {back && !hideBack && (
          <Button
            style={{ maxWidth: "50%", margin: "0 5px" }}
            color="danger"
            onClick={back}
          >
            Voltar
          </Button>
        )}

        {save && (
          <Button
            style={{ maxWidth: "50%", margin: "0 5px" }}
            color="secondary"
            onClick={save}
          >
            Salvar
          </Button>
        )}

        {send && !hideSend && (
          <Button
            style={{ maxWidth: "50%", margin: "0 5px" }}
            color="success"
            onClick={toggleModalSend}
          >
            Enviar
          </Button>
        )}

        {children}
      </Container>
    </>
  );
}
