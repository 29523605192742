import React, { Component, createRef } from 'react';
import { Prompt } from 'react-router';
import { Row, Col } from 'reactstrap';
import api, { regularApi } from '../../../services/api';
import { notifySuccess, notifyInfo, notifyError } from '../../../services/notificacoes';
import { CustomRadio } from '../../components/general';
import { normalizarPorMaximo } from '../../../utils';
import './css/general.css';
import withSubscription from "./hoc-instrumentos-assincronos";
import ActivityActions from './Components/ActivityActions';
import NewSubHeader from '../ListInstruments/components/NewSubHeader';
import Messages from '../../../static/Messages';
import { assincronoInstrumentosIDS } from '../../../services/Constantes';
import InstrumentContainer from '../../components/InstrumentContainer';
import { NewCustomRadio } from '../../components/general';
import { QuestionLocusControle } from './Components/QuestionLocusControle';

import { scrollToUnfinishedQuestion } from './utils/scrollToUnfinishedQuestion';

// / gabarito
// /
class LocusDeControle extends Component {
  state = {
    enunciado: '',
    dados: [],
    respostas: [],
    gabarito: [],
    feedbacks: [],
    questionQty: 0,
    pontos: 0
  };

  graphicRefs = [];

  async componentDidMount() {
    await this.props.validateAccess(localStorage.getItem('assincrono'), localStorage.getItem('instrumento'));
    const response = await regularApi.get(
      'api/instruments/5c19a9680fc62504da06d4e7'
    );
    const dadosDb = response.data;
    const savedAnswers = await api.get(
      `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
        'assincrono'
      )}/${localStorage.getItem('instrumento')}/${localStorage.getItem(
        'projeto'
      )}`
    );
    const storageName = `locusEscolhas-${localStorage.getItem('assincrono')}`;
    this.props.getStorageName([storageName]);
    if (sessionStorage.getItem(storageName)) this.props.setHasChanged(true);
    const respostas = sessionStorage.getItem(storageName) ?
    JSON.parse(sessionStorage.getItem(storageName)) : savedAnswers.data ? savedAnswers.data.escolhas : [];
    this.setState({ respostas, dados: [dadosDb] });
    this.setState({ feedbacks: [dadosDb.data[0].feedbacks] });

    const questionQty = dadosDb.data[0].alternativas[0].alternativa.length;

    this.setState({
      enunciado: dadosDb.data[0].enunciado,
      gabarito: [
        'fake',
        '2a',
        '3b',
        '4b',
        '5b',
        '6a',
        '7a',
        'fake',
        '9a',
        '10b',
        '11b',
        '12b',
        '13b',
        'fake',
        '15b',
        '16a',
        '17a',
        '18a',
        'fake',
        '20a',
        '21a',
        '22b',
        '23a',
        'fake',
        '25a',
        '26b',
        'fake',
        '28b',
        '29a'
      ],
      questionQty
    });
  }

  handleChange = (e, indice, resposta) => {
    const { respostas, pontos } = this.state;
    respostas[indice] = indice + 1 + resposta;
    sessionStorage.setItem(`locusEscolhas-${localStorage.getItem('assincrono')}`, JSON.stringify(respostas));
    this.props.setHasChanged(true);
    this.setState({ respostas });
  };

  enviar = async () => {
    try {
      let { respostas } = this.state;

      let pontos = 0;

      if (respostas.filter((r) => !!r === true).length < this.state.questionQty) {
        notifyError(Messages.Assincronos.msgValidation);
        //this.props.setModalConfirmacao(false);
        return;
      }

      for (let i = 0; i < this.state.gabarito.length; i++) {
        if (this.state.gabarito[i] == this.state.respostas[i]) {
          pontos += 1;
        }
      }

      await api.put(
        `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
          'assincrono'
        )}/${localStorage.getItem('instrumento')}`,
        {
          resposta: {
            locusInternoNormalizado: normalizarPorMaximo(23, pontos),
            locusInternoBruto: pontos,
            locusExternoNormalizado: normalizarPorMaximo(23, 23 - pontos),
            locusExternoBruto: 23 - pontos,
            escolhas: respostas,
            finished: true
          },
          projetoID: localStorage.getItem('projeto')
        }
      );
      this.props.setHasChanged(false);
      this.props.history.goBack();

    } catch (err) {
      this.props.handleError(err);
    }
  };

  saveAnswer = async () => {
    try {
      const { respostas } = this.state;
      await api.put(
        `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
          'assincrono'
        )}/${localStorage.getItem('instrumento')}`,
        {
          resposta: {
            escolhas: respostas
          },
          projetoID: localStorage.getItem('projeto')
        }
      );
      this.props.setHasChanged(false);
      notifySuccess(
        `Atividade salva com sucesso.
          Caso queira, você poderá modificar a atividade até o prazo final`
      );
    } catch (err) {
      this.props.handleError(err);
    }
  };

  voltar = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <InstrumentContainer>
        <Prompt
          when={this.props.handleBack()}
          message={Messages.Assincronos.msgConfirmacao}
        />
        <NewSubHeader
          titulo={"Locus de Controle"}
          instrucao={this.state.enunciado}
          idInstrumento={assincronoInstrumentosIDS.LocusdeControle}
          />
        {this.state.dados.map(dados => (
          <div key={dados._id}>
            {dados.data.map((dados, index) => (
              <div key={index}>
                {dados.alternativas.map((dados, index) => (
                  <>
                    {dados.alternativa.map((dados, index) => (
                      this.graphicRefs.push(createRef()),
                      <>
                        <div ref={this.graphicRefs[index]} key={index} className='margin-top'>

                          <b>{`Questão ${index + 1}`}</b>
                          <QuestionLocusControle
                            indice={index}
                            indicea={dados.indicea}
                            indiceb={dados.indiceb}
                            checkedA={
                              this.state.respostas[index] ===
                              index + 1 + dados.indicea
                            }
                            checkedB={
                              this.state.respostas[index] ===
                              index + 1 + dados.indiceb
                            }
                            onChangeA={e =>
                              this.handleChange(e, index, dados.indicea)}
                            onChangeB={e =>
                              this.handleChange(e, index, dados.indiceb)}
                            index={index}
                            perguntaa={dados.perguntaa}
                            perguntab={dados.perguntab}
                            isMobile={this.props.data.isMobile}
                          />
                        </div>
                        <hr style={{ marginTop: 30 }} />
                      </>
                    ))}
                  </>
                ))}
              </div>
            ))}
            <ActivityActions
              back={() => this.voltar()}
              save={() => {
                this.props.setHasChanged(
                  false,
                  async () => await this.saveAnswer());
              }}
              send={this.enviar}
              nextSend={() => scrollToUnfinishedQuestion(this.graphicRefs, this.state.dados[0].data[0].alternativas[0].alternativa, this.state.respostas)}
            />
          </div>
        ))}
      </InstrumentContainer>
    );
  }
}

export default withSubscription(LocusDeControle);
