import React from 'react';

export class BlockWindowUnload extends React.Component {

    constructor(props) {
        super(props);
    }

    callback = (e) => {
        const msg = "É possível que as alterações feitas não sejam salvas.";

        if (this.props.when && window.location.pathname !== this.props.currentPath) {
            e.returnValue = msg;
            return msg;
        }

        return false;
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.callback);
    }

    componentWillUnmount() {
        if (this.props.storageName && this.props.storageName.length) {
          this.props.storageName.map(name => {
            sessionStorage.removeItem(name);
          });
        }

        window.removeEventListener("beforeunload", this.callback);
    }

    render() {
        return null;
    }
}
