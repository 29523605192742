import React, { Component, createRef } from 'react';
import { Prompt } from 'react-router';
import { Modal, Row, ModalBody, Col, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from '../../components/Buttons'
import api, { regularApi } from '../../../services/api';
import { notifySuccess, notifyInfo, notifyError } from '../../../services/notificacoes';
import { NewCustomRadio } from '../../components/general';
import { RadioWrapper } from '../../components/general/NewCustomRadio';
import ActivityActions from './Components/ActivityActions';
import './css/general.css';
import withSubscription from "./hoc-instrumentos-assincronos";
import Messages from '../../../static/Messages';
import { scrollToUnfinishedQuestion } from './utils/scrollToUnfinishedQuestion';
import InstrumentContainer from '../../components/InstrumentContainer';
import { assincronoInstrumentosIDS } from '../../../services/Constantes';
import NewSubHeader from '../ListInstruments/components/NewSubHeader';
// // AUTOEFICACIA irá gerar um gráfico comm 3 resultados
// //Dimensão "Iniciação e Persistência"- itens, 1, 5, 6, 7, 14, 15;
// Dimensão "Eficácia Perante a Adversidade"- itens, 2, 4, 8, 9, 12;
// /Dimensão " Eficácia Social"- itens, 3, 10, 11, 13
// 4.00
// Iniciação e Persistência
// Apreciação que o individuo faz acerca da sua vontade para iniciar e para completar uma ação
// 2.60
// Eficácia perante a adversidade
// Vontade para persistir numa atividade perante situações que são adversas
// 3.75
// Eficácia social
// Expectativas perante situações sociais que exigem criação de vínculos com indivíduos desconhecidos
// Entenda seu resultado

// Para análise e interpretação dos resultados cabe observar inicialmente alguns pontos:
// - Observe que a autoeficácia ou eficácia pessoal são sinônimos neste instrumento;
// - A teoria quanto à Visão Contextualista e à Sócio-Cognitiva compreendem que “estamos sujeitos” às circunstâncias e ao contexto ao mesmo tempo que “somos sujeitos” ao influenciar as ações e os resultados.
// - Ou seja, não controlamos os eventos, mas nossas ações os influenciam!

// Escala de Autoeficácia
// 1 e 2 – sinto-me à vontade apenas com atividades em que controlo as variáveis e estou habituado a realizar. Seja por sua natureza ou nível de complexidade. Tenho certeza de que está sob meu inteiro controle. Sinto-me à vontade de atuar com meus pares e colegas.
// 3 e 4 – aceito alguma possibilidade de descontrole, de variáveis mais desafiadoras e não inteiramente sob meu controle. Sinto-me à vontade de trabalhar com meu superior e pares.
// 5 e 6 – me realizo com desafios cada vez maiores e com variáveis conhecidas ou desconhecidas. Confio na minha capacidade de me ajustar ou buscar recursos para viabilizar o resultado, independente do que possa ocorrer. Sinto-me à vontade para discutir e atuar com um ou dois níveis hierárquicos superiores.
// 7 – confio plenamente nas minhas capacidades de trabalhar com o desconhecido, quaisquer que sejam as circunstâncias e os envolvidos. Sinto-me à vontade para atuar com pessoas em dois ou três níveis superiores.
// //Itens Invertidos 3, 4, 5, 7, 8, 9, 10, 12, 14 e 15.

let respostas = [];
class Autoeficacia extends Component {
  state = {
    dados: [],
    instrumento: {
      enunciado: ''
    }
  };

  graphicRefs = [];

  async componentDidMount() {
    await this.props.validateAccess(localStorage.getItem('assincrono'), localStorage.getItem('instrumento'));
    const response = await regularApi.get(
      'api/instruments/5c1bfc062c87cd05d02bd1e0',
    );
    const dadosDb = response.data;
    const savedAnswers = await api.get(
      `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
        'assincrono',
      )}/${localStorage.getItem('instrumento')}/${localStorage.getItem(
        'projeto',
      )}`,
    );
    const storageName = `autoeficaciaEscolhas-${localStorage.getItem('assincrono')}`;
    this.props.getStorageName([storageName]);
    if (sessionStorage.getItem(storageName)) this.props.setHasChanged(true);
    respostas = sessionStorage.getItem(storageName) ?
    JSON.parse(sessionStorage.getItem(storageName)) : savedAnswers.data ? savedAnswers.data.escolhas : [];
    this.setState({ dados: [dadosDb], instrumento: { enunciado: dadosDb.data && dadosDb.data[0].enunciado } });
  }

  saveAnswer = () => {
    try {
    api.put(
      `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
        'assincrono',
      )}/${localStorage.getItem('instrumento')}`,
      {
        resposta: {
          escolhas: respostas,
        },
        projetoID: localStorage.getItem('projeto'),
      },
    );
    this.props.setHasChanged(false);
    notifySuccess(
      'Atividade salva com sucesso. Caso queira, você poderá modificar a atividade até o prazo final',
    );
    } catch (err) {
      this.props.handleError(err);
    }
  };

  salvaResposta = (e, index) => {
    respostas[index - 1] = e.target.value;
    sessionStorage.setItem(`autoeficaciaEscolhas-${localStorage.getItem('assincrono')}`, JSON.stringify(respostas));
    this.props.setHasChanged(true);
    this.setState({});
  };

  enviar = e => {
    try {
    let ini = 0;
    let ep = 0;
    let es = 0;

    for (let i = 0; i < respostas.length; i++) {
      if (i == 0 || i == 4 || i == 5 || i == 6 || i == 13 || i == 14) {
        ini += Number(respostas[i]);
      }
      if (i == 1 || i == 3 || i == 7 || i == 8 || i == 11) {
        ep += Number(respostas[i]);
      }
      if (i == 2 || i == 9 || i == 10 || i == 12) {
        es += Number(respostas[i]);
      }
    }

    if (this.state.dados[0] && this.state.dados[0].data && this.state.dados[0].data[0]) {
      if (respostas.filter(r => r).length < this.state.dados[0].data[0].alternativas.length) {
        notifyError(Messages.Assincronos.msgValidation);
        return;
      }
    }


    if (ini === null || isNaN(ini)) {

      return notifyError('Falha ao enviar instrumento. Selecione todas questões e tente novamente');

    }

    if (ep === null || isNaN(ep)) {

      return notifyError('Falha ao enviar instrumento. Selecione todas questões e tente novamente');

    }

    if (es === null || isNaN(es)) {

      return notifyError('Falha ao enviar instrumento. Selecione todas questões e tente novamente');

    }

    api.put(
      `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
        'assincrono',
      )}/${localStorage.getItem('instrumento')}`,
      {
        resposta: {
          ini: ini / 6,
          ep: ep / 5,
          es: es / 4,
          escolhas: respostas,
          finished: true
        },
        projetoID: localStorage.getItem('projeto'),
      },
    );
    this.props.setHasChanged(false);
    this.props.history.goBack();
    } catch (err) {
      this.props.handleError(err);
    }
  };

  montaOpcoes = (valor, pergunta) => {
    return (
      <Row className="without-margin row-centered remove-margins question align-center" style={{ flexWrap: 'nowrap' }}>
        <div><label htmlFor={`${pergunta}-${1}`}>Discordo plenamente</label></div>
        {[1, 2, 3, 4, 5, 6, 7].map(i => (
          <RadioWrapper>
            <NewCustomRadio
              value={valor === "normal" ? String(i) : String(8 - i)}
              onClick={e => this.salvaResposta(e, pergunta)}
              checked={
                respostas[pergunta - 1] ===
                (valor === "normal" ? String(i) : String(8 - i))
              }
              id={`${pergunta}-${i}`}
              label={i}
            />
          </RadioWrapper>
        ))}
        <div style={{ textAlign: 'right' }}><label htmlFor={`${pergunta}-${7}`}>Concordo plenamente</label></div>
      </Row>
    );
  };

  voltar = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <>
        <Prompt
          when={this.props.handleBack()}
          message={Messages.Assincronos.msgConfirmacao}
        />
        {/* <Header /> */}
        <div>
          {/*  */}
          <InstrumentContainer>
            <NewSubHeader
              titulo={"Auto Eficácia"}
              instrucao={this.state.instrumento.enunciado}
              idInstrumento={assincronoInstrumentosIDS.Autoeficacia}
            />
            {this.state.dados.map(dados => (
              <div key={dados._id}>
                {dados.data.map((auto, index) => (
                  <div key={index}>
                    {auto.alternativas.map((auto, index) => (
                      this.graphicRefs.push(createRef()),
                      <>
                        <div ref={this.graphicRefs[index]} className="sel" key={index}>
                        <br />
                        <b>
                          <p>
                            {`${index + 1} - `}
                            {auto.resp}
                          </p>
                        </b>
                        {this.montaOpcoes(auto.tipo, index + 1)}
                        </div>
                        <hr style={{marginTop: 30}} />
                      </>
                    ))}
                  </div>
                ))}
              </div>
            ))}
            <ActivityActions
              back={() => this.voltar()}
              save={this.saveAnswer}
              send={this.enviar}
              nextSend={() => scrollToUnfinishedQuestion(this.graphicRefs, this.state.dados[0].data[0].alternativas, respostas)}
            ></ActivityActions>
          </InstrumentContainer>
        </div>
      </>
    );
  }
}

export default withSubscription(Autoeficacia);
