import React, { Component } from "react";
import PropTypes from "prop-types";

import ItemInstrumentoDashboard from "./ItemInstrumentoDashboard";
import { ItemInstrumento, ItemActions } from "./styles";
import { BiError } from "react-icons/bi";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import { Tooltip } from "react-tippy";

export default class ItemInstrumentoExpirado extends Component {
  static propTypes = {
    indice: PropTypes.number,
    instrumento: PropTypes.object.isRequired
  };

  render() {
    const { instrumento } = this.props;

    return (
      <ItemInstrumento
        key={this.props.indice}
      >
        <ItemInstrumentoDashboard instrumento={instrumento} />
        <ItemActions>
          <Tooltip title="Atividade Expirada" size="small" position="top" arrow>
            <BiError size={20} color="#9F4848" id={`expirado-${instrumento._id}`} />
          </Tooltip>
        </ItemActions>
      </ItemInstrumento>
    );
  }
}
