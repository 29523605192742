import React from "react";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import styled from "styled-components";

import NewCustomRadio from "../../../components/general/NewCustomRadio";

const QuestaoCentralizada = styled(Row)`
  align-items: center;
  justify-content: center;
`;

const RadioColumn = styled.div`
    display: flex;
    justify-content: flex-end;
  `;

const RadioColumnMd = styled(Col)`
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
    height: 2.5px;
    background-color: #ccc;
    align-items: center;
  `;

const RadioWrapper = styled.div`
    height: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  `;

const Opcao = styled(Row)`
    display: flex;
    align-items: center;
    margin: ${(props) => props.isMobile ? 0 : "10px"};
  `;

export function QuestionTiposPersonalidade(props) {
  const { data, indice } = props;
  const isMobile = props.isMobile;

  return (
    <QuestaoCentralizada>
      {isMobile ? (
        <>
          <Col sm="12" style={{ marginBottom: 10 }}>
            <Opcao sm="12" isMobile={isMobile}>
              <RadioColumn style={{ marginBottom: 10 }}>
                <RadioWrapper>
                  <NewCustomRadio
                    value={0}
                    checked={props.selectedOption === "a"}
                    onChange={e => props.handleChange(e, indice, 0)}
                    id={`${indice}-0`}
                  />
                </RadioWrapper>
              </RadioColumn>
              <Col>
                {/* <b>a) </b> */}
                <label htmlFor={`${indice}-0`}>{data.respostas[0]}</label>
              </Col>
            </Opcao>
          </Col>

          <Col sm="12">
            <Opcao sm="12" isMobile={isMobile}>
              <RadioColumn style={{ marginBottom: 10 }}>
                <RadioWrapper>
                  <NewCustomRadio
                    value={1}
                    checked={props.selectedOption === "b"}
                    onChange={e => props.handleChange(e, indice, 1)}
                    id={`${indice}-1`}
                  />
                </RadioWrapper>
              </RadioColumn>
              <Col>
                {/* <b>b) </b> */}
                <label htmlFor={`${indice}-1`}>{data.respostas[1]}</label>
              </Col>
            </Opcao>
          </Col>
        </>
      ) : (
        <>
          <Col sm="5" style={{ paddingLeft: 5 }}>
            <Opcao isMobile={isMobile}>
              <Col style={{ paddingLeft: '0' }}>
                {/* <b>a) </b> */}
                <label style={{marginBottom: 0}} htmlFor={`${indice}-0`}>{data.respostas[0]}</label>
              </Col>
            </Opcao>
          </Col>
          <RadioColumnMd sm="2">
            <RadioWrapper>
              <NewCustomRadio
                style={{ marginLeft: -5 }}
                value={0}
                checked={props.selectedOption === "a"}
                onChange={e => props.handleChange(e, indice, 0)}
                id={`${indice}-0`}
              />
            </RadioWrapper>
            <RadioWrapper>
              <NewCustomRadio
                style={{ marginRight: -5 }}
                value={1}
                checked={props.selectedOption === "b"}
                onChange={e => props.handleChange(e, indice, 1)}
                id={`${indice}-1`}
              />
            </RadioWrapper>
          </RadioColumnMd>
          <Col sm="5">
            <Opcao isMobile={isMobile}>
              <Col>
                {/* <b>b) </b> */}
                <label style={{marginBottom: 0}} htmlFor={`${indice}-1`}>{data.respostas[1]}</label>
              </Col>
            </Opcao>
          </Col>
        </>
      )}
    </QuestaoCentralizada>
  );
}

QuestionTiposPersonalidade.propTypes = {
  data: PropTypes.object.isRequired,
  indice: PropTypes.number.isRequired,
  selectedOption: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,

  handleChange: PropTypes.func.isRequired
};

QuestionTiposPersonalidade.defaultProps = {
  selectedOption: null,
  isMobile: false
};

export default QuestionTiposPersonalidade;
