import React, { Component, createRef } from "react";
import { Row, Col, Container } from "reactstrap";
import styled from 'styled-components';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Prompt } from "react-router";
import Messages from "../../../static/Messages";
import { NewCustomRadio } from '../../components/general';
import NewSubHeader from '../ListInstruments/components/NewSubHeader';
import { assincronoInstrumentosIDS } from '../../../services/Constantes';
import InstrumentContainer from '../../components/InstrumentContainer';
import { notifySuccess, notifyInfo, notifyError } from "../../../services/notificacoes";
import ActivityActions from "./Components/ActivityActions";
import api, { regularApi } from '../../../services/api';
import withSubscription from "./hoc-instrumentos-assincronos";
import TaskList from "../../components/general/TaskList";
import Column from "./Components/Drop";
import { Button } from "../../components/Buttons";

import { scrollToUnfinishedQuestion } from './utils/scrollToUnfinishedQuestion';

import './css/EstilosLideranca.css';

const Qst = styled.div`
  background: ${(props) => (props.active ? '#fac100' : '#00babe')};
  color: white;
  padding: 10px 12px;
  margin-right: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  float: left;
  font-size: 14px;

  .next {
    background: lightgray;
  }
`;

const StyledRow = styled(Row) `
  @media (max-width: 575px) {
    flex-direction: column-reverse;
  }
`

const Box = styled.div`
  border: 1px solid lightgray;
`;

const Boxed = styled.div`
  border: 1px solid lightgray;

  @media (max-width: 575px) {
    width: 90% !important;
    margin: 0 auto !important;
}
`;

const P = styled.p`
  font-size: 13px;
`;

const StyledCol = styled(Col) `
  text-align: right !important;

  @media (max-width: 575px) {
    text-align: left !important;
  }
`

class TiposDeLiderancaAssincrono extends Component {
  appRef = null;

  state = {
    initialData: {
      tasks: {
        e: { id: "e", content: "" }
      },
      columns: {
        participantes: {
          id: "participantes",
          title: "Participantes",
          estilo: "Participantes",
          taskIds: ["e"]
        }
      },
      // Facilitate reordering of the columns
      columnOrder: ["participantes"]
    },
    responses: [],
    dados: [
      { alternativas: [] }
    ],
    participants: [],
    enunciado: '',
    respostas: [],
    hasChanged: false,
    perguntaAtual: 0,
    loading: true,
    barra: false,
  };

  graphicRefs = [];

  monta = async (participantes, pergunta) => {
    let initialData = { ...this.state.initialData };

    const json = {};
    const current = json;
    const columnJson = {};
    const col = columnJson;
    const ordem = [];

    await participantes.map((part, indice) => {
      var valordeId = part.id;

      current[valordeId] = {
        id: valordeId,
        content: part.nome,
        picture: part.foto
      };
      col["participantes"] = {
        id: "participantes",
        title: "Participantes",
        estilo: "Participantes",
        taskIds: []
      };
      ordem.push(part.id);
    });
    await pergunta.resposta.map((valor, indice) => {
      col[valor.estilo] = {
        id: valor.estilo,
        title: valor.resposta,
        estilo: valor.estilo,
        taskIds: []
      };

      initialData.columnOrder[0] = "participantes";
      initialData.columnOrder[indice + 1] = valor.estilo;
    });
    initialData.columns = columnJson;
    initialData.tasks = json;
    initialData.columns.participantes.taskIds = ordem;
    this.setState({ initialData: initialData });

  }

  removeOverflow = () => {
    const appBody = document.querySelector(".app-body");

    if (appBody) {
      this.appRef = appBody;

      appBody.classList.remove("app-body");
    }
  }

  componentWillUnmount() {
    if (this.appRef) {
      this.appRef.classList.add("app-body");
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });
    this.removeOverflow();

    const response = await regularApi.get("api/instruments/6352931d2ff35a38d534b27a");
    const dadosDb = response.data;

    const savedAnswers = await api.get(
      `/respondente/${localStorage.getItem('user')}/${localStorage.getItem(
        'assincrono',
      )}/${localStorage.getItem('instrumento')}/${localStorage.getItem(
        'projeto',
      )}`,
    );

    const storageName = `estilosEscolhas-${localStorage.getItem('assincrono')}`;

    this.props.getStorageName([storageName]);

    if (sessionStorage.getItem(storageName)) this.props.setHasChanged(true);

    const respostas = sessionStorage.getItem(storageName) ?
      JSON.parse(sessionStorage.getItem(storageName)) : savedAnswers.data ? savedAnswers.data.respostas : [];

    const users = JSON.parse(sessionStorage.getItem("estilosLiderancaUsers"));

    const groupUsers = users.filter(u => u.groupId === localStorage.getItem("assincrono"));

    this.monta(groupUsers, dadosDb.data[0].alternativas[0])

    this.setState({ dados: [dadosDb.data[0]], enunciado: dadosDb.data[0].enunciado && dadosDb.data[0].enunciado.replaceAll('${{participant_name}}', localStorage.getItem('userName')), respostas, loading: false, participants: groupUsers });
  }
  handleChange = (e, index, estilo, indice) => {
    const { respostas } = this.state;
    const respostasClone = [...respostas]
    respostasClone[indice] = { estilo: estilo };

    this.props.setHasChanged(true);
    sessionStorage.setItem(`estilosEscolhas-${localStorage.getItem('assincrono')}`, JSON.stringify(respostasClone));

    this.setState({ respostas: respostasClone, hasChanged: true });
  };

  sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  changeQuestion = (initialData) => {
    initialData.columns.participantes.taskIds = this.state.participants.map(p => p.id);
      Object.keys(initialData.columns).map(key => {
        if (!key.toLowerCase().includes('participante')) {
          initialData.columns[key].taskIds = [];

          const newQuestion = this.state.dados[0].alternativas[this.state.perguntaAtual + 1].resposta.find(r => r.estilo && r.estilo.toLowerCase() === key.toLowerCase());

          initialData.columns[key].title = newQuestion.resposta;
        }
      });

      window.scrollTo(0, 0);

      this.setState({ initialData: initialData, perguntaAtual: this.state.perguntaAtual + 1 });
  }

  saveAnswer = (initialData) => {
    if (initialData.columns.participantes.taskIds.length) return;

    const columnsClone = {...initialData.columns, questionIndex: this.state.perguntaAtual};

    const responsesClone = [...this.state.responses];

    if (responsesClone[this.state.perguntaAtual]) {
      responsesClone[this.state.perguntaAtual] = { ...columnsClone }
    } else {
      responsesClone.push(columnsClone);
    }

    this.setState({ responses: responsesClone });

    return responsesClone;
  }

  handleNextQuestion = async(initialData) => {
    if (this.state.perguntaAtual < this.state.dados[0].alternativas.length - 1) {
      if (this.state.initialData.columns.participantes.taskIds.length) return notifyError("Responda todos os participantes");

      this.saveAnswer(initialData);

      if (this.state.responses[this.state.perguntaAtual + 1]) {
        this.setState({ initialData: { ...this.state.initialData, columns: {...this.state.responses[this.state.perguntaAtual + 1]} } });
      } else {
        this.monta(this.state.participants, this.state.dados[0].alternativas[this.state.perguntaAtual + 1])
      }

      this.props.setHasChanged(true);

      window.scrollTo(0, 0);
      this.setState({ perguntaAtual: this.state.perguntaAtual + 1 });
    }
  }

  handlePrevQuestion = () => {
    const initialDataClone = { ...this.state.initialData };

    initialDataClone.columns = { ...initialDataClone.columns, ...this.state.responses[this.state.perguntaAtual - 1] }

    initialDataClone.columns.participantes.taskIds = [];

    window.scrollTo(0, 0);

    this.setState({ initialData: initialDataClone, perguntaAtual: this.state.perguntaAtual - 1 });
  }

  enviar = async () => {
    try {
      if (this.state.perguntaAtual < this.state.dados[0].alternativas.length - 1) return notifyError("Responda todas as questões");
      else if (this.state.perguntaAtual === this.state.dados[0].alternativas.length - 1 && this.state.initialData.columns.participantes.taskIds.length) return notifyError("Responda todos os participantes");

      const responses = this.saveAnswer(this.state.initialData);

      await api.put(`/respondente/${localStorage.getItem("user")}/${localStorage.getItem("assincrono")}/${localStorage.getItem("instrumento")}/responder-estilos-lideranca`, {
        responses,
        users: this.state.participants
      });

      this.props.setHasChanged(false);

      this.props.voltar();
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ hasChanged: false });
    }
  };

  salvar = async () => {
    try {
      const { respostas } = this.state;

      await api.put(
        `/respondente/${localStorage.getItem("user")}/${localStorage.getItem(
          "assincrono"
        )}/${localStorage.getItem("instrumento")}`,
        {
          resposta: {
            respostas
          },
          projetoID: localStorage.getItem("projeto"),
        }
      );

      this.props.setHasChanged(false);
      notifySuccess(`Atividade salva com sucesso.
      Caso queira, você poderá modificar a atividade até o prazo final`);
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ hasChanged: false });
    }
  };

  voltar = () => {
    this.props.history.goBack();
  };

  montaPaginas = (total, atual) => {
    let lista = [];
    for (var i = 0; i < total; i++) {
      if (i + 1 === atual) {
        lista.push(
          <Qst key={i} active>
            {i + 1}
          </Qst>
        );
      } else if (i + 1 > atual) {
        lista.push(
          <Qst className="next" key={i}>
            {i + 1}
          </Qst>
        );
      } else {
        lista.push(<Qst key={i}>{i + 1}</Qst>);
      }
    }

    return <Col>{lista}</Col>;
  };

  onDragEnd = result => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = this.state.initialData.columns[source.droppableId];
    const finish = this.state.initialData.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds
      };

      const newState = {
        ...this.state.initialData,
        columns: {
          ...this.state.initialData.columns,
          [newColumn.id]: newColumn
        }
      };

      this.setState(newState);
      return;
    }

    // Moving from one list to another
    const startTaskIds = Array.from(start.taskIds);

    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);

    const newFinish = {
      ...finish,
      taskIds: finishTaskIds
    };

    const newState = {
      ...this.state.initialData,
      columns: {
        ...this.state.initialData.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    };

    this.verificaResultado(newState);
  };

  verificaResultado = async (initialData, proxima = false) => {
    let respostas = [];

    let estilos = {
      autoritario: [],
      diretivo: [],
      modelador: [],
      participativo: [],
      afetivo: [],
      desenvolvedor: [],
      laissezFaire: []

    }

    var obj = initialData.columns;
    respostas.push(obj);

    for (var i = 0; i < respostas.length; i++) {
      if (respostas[i]["Autoritário"]) {
        estilos.autoritario[this.state.perguntaAtual] = respostas[i][
          "Autoritário"
        ].taskIds;
      }
      if (respostas[i]["Diretivo"]) {
        estilos.diretivo[this.state.perguntaAtual] = respostas[i][
          "Diretivo"
        ].taskIds;
      }
      if (respostas[i]["Modelador"]) {
        estilos.modelador[this.state.perguntaAtual] = respostas[i][
          "Modelador"
        ].taskIds;
      }
      if (respostas[i]["Participativo"]) {
        estilos.participativo[this.state.perguntaAtual] = respostas[i][
          "Participativo"
        ].taskIds;
      }
      if (respostas[i]["Afetivo"]) {
        estilos.afetivo[this.state.perguntaAtual] = respostas[i][
          "Afetivo"
        ].taskIds;
      }
      if (respostas[i]["Desenvolvedor"]) {
        estilos.desenvolvedor[this.state.perguntaAtual] = respostas[i][
          "Desenvolvedor"
        ].taskIds;
      }
      if (respostas[i]["LaissezFaire"]) {
        estilos.laissezFaire[this.state.perguntaAtual] = respostas[i][
          "LaissezFaire"
        ].taskIds;
      }
    }

    this.setState({ initialData });
  };

  colapsa = () => {
    this.setState(previousState => ({
      barra: !previousState.barra
    }));
  };


  render() {
    const { respostas } = this.state;

    if (this.state.loading) return <></>;

    return (
      <>
        <Prompt
          when={this.props.handleBack()}
          message={Messages.Assincronos.msgConfirmacao}
        />
        <InstrumentContainer>
          <NewSubHeader
            titulo={"Estilos de Liderança"}
            instrucao={this.state.enunciado || ''}
            idInstrumento={assincronoInstrumentosIDS.EstilosDeLideranca}
          />
          <Row style={{marginTop: 20}}>
            {
              this.montaPaginas(this.state.dados[0].alternativas.length, this.state.perguntaAtual + 1)
            }
          </Row>

          <Row>
            <StyledCol sm="1">
              <h2>{this.state.perguntaAtual + 1}.</h2>
            </StyledCol>
            <Col>
              <p>
                {
                  this.state.dados[0].alternativas[this.state.perguntaAtual]
                    .pergunta.replaceAll('${{participant_name}}', localStorage.getItem('userName'))
                }
              </p>
            </Col>
          </Row>

          <Container>
            <DragDropContext onDragEnd={this.onDragEnd}>
                <StyledRow>
                  <Col style={{ marginTop: 10 }}>
                    {this.state.initialData.columnOrder.map(columnId => {
                      const column = this.state.initialData.columns[columnId];
                      const tasks = column.taskIds.map(
                        taskId => this.state.initialData.tasks[taskId]
                      );
                      return (
                        <Col sm="12" key={column.id}>
                          <Box>
                            {column.title !== "Participantes" ? (
                              <Col>
                                <Column column={column} tasks={tasks} />
                              </Col>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Col>
                      );
                    })}
                  </Col>
                  <Col style={{ marginTop: 10 }} sm={this.state.barra ? "2" : "4"}>
                    <Boxed className="posicao" style={{ padding: 5 }}>
                      <span onClick={this.colapsa}>
                        <b style={{ fontSize: 15, cursor: "pointer" }}>
                          {" "}
                          &#9776; Participantes
                        </b>
                      </span>

                      {this.state.initialData.columnOrder.map(columnId => {
                        const column = this.state.initialData.columns[columnId];
                        const tasks = column.taskIds.map(
                          taskId => this.state.initialData.tasks[taskId]
                        );
                        return (
                          <div key={column.id}>
                            {column.title === "Participantes" ? (
                              <Col>
                                <Column
                                  barra={this.state.barra}
                                  column={column}
                                  tasks={tasks}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </Boxed>
                  </Col>
                </StyledRow>

            </DragDropContext>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
              <Button style={{ marginRight: 10, opacity: this.state.perguntaAtual === 0 ? "50%" : "100%" }} onClick={ this.handlePrevQuestion } disabled={this.state.perguntaAtual === 0} color="secondary">Anterior</Button>
              <Button style={{ opacity: this.state.perguntaAtual === this.state.dados[0].alternativas.length - 1 ? "50%" : "100%" }} onClick={ async() => { await this.handleNextQuestion({ ...this.state.initialData }) } } disabled={this.state.perguntaAtual === this.state.dados[0].alternativas.length - 1} color="secondary">Próximo</Button>
            </div>
          </Container>
          <ActivityActions
            back={() => this.voltar()}
            send={this.enviar}
            hideBack={this.state.perguntaAtual !== this.state.dados[0].alternativas.length - 1 && this.state.perguntaAtual !== 0}
            hideSend={this.state.perguntaAtual !== this.state.dados[0].alternativas.length - 1}
          ></ActivityActions>
        </InstrumentContainer>
      </>
    );
  }
}

export default withSubscription(TiposDeLiderancaAssincrono);
