export function scrollToUnfinishedQuestion(graphicRefs, alternativas, respostas, startIndex=0, isEstilos=false) {
  let responseToMatch = respostas;
  if (isEstilos) {
    let isAnswered = []
    for (const r of respostas) {
      if (r && r.respostas && r.respostas.length === 4) isAnswered.push(r);
      else isAnswered.push(null);
    }
    responseToMatch = isAnswered
  }

  if (responseToMatch.filter((r) => !!r === true).length < alternativas.length) {
    let unfinishedQuestion = [];

    for (let i = 0 ; i < alternativas.length ; i++) {
      const index = i + startIndex;
      if (!respostas[index] || (isEstilos && respostas[index].respostas.length < 4)) unfinishedQuestion.push(i);
    }

    if (!unfinishedQuestion.length) return;

    graphicRefs.map((r, i) => {
      if (i !== unfinishedQuestion && r && r.current) {
        r.current.style.border = '';
        r.current.style.padding = 0;
        if (isEstilos) {
          r.current.style.marginBottom = 0;
        }
      }
    });

    unfinishedQuestion.map(q => {
      graphicRefs[q].current.style.border = '1px solid #f00';
      graphicRefs[q].current.style.padding = '6px';
      if (isEstilos) {
        graphicRefs[q].current.style.marginBottom = '10px';
      }
    });

    graphicRefs[unfinishedQuestion[0]].current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    //window.scrollTo(0, this.graphicRefs[unfinishedQuestion].current.offsetTop - 140);
  }
}