/**
 * Normaliza o resultado ponderado da prova usando normalização por máximo
 * @author LucasSzavara
 *
 * @param {double} max O maior resultado da atividade
 * @param {double} playerResult O valor para ser normalizado
 * @param {number} topValue O maior valor possível, por padrão 100
 * @returns {double} O valor normalizado
 */
export default function normalizarPorMaximo(max, playerResult, topValue = 100) {
  if (max === 0) {
    return 0;
  }
  return (topValue * playerResult) / max;
}
